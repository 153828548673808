const MRI = () => {
  return (
    <svg
      fill="none"
      height="40px"
      width="40px"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 450 440"
      xmlSpace="preserve"
      stroke="#515dba"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="5"></g>
      <g
        strokeWidth="5"
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier" strokeWidth="10">
        {" "}
        <g>
          {" "}
          <g>
            {" "}
            <path d="M289.533,14.427C289.533,6.464,283.084,0,275.122,0h-52.35c-7.963,0-14.424,6.464-14.424,14.427v57.694h81.185V14.427 L289.533,14.427z"></path>{" "}
            <path d="M99.211,14.427C99.211,6.464,92.747,0,84.785,0H32.444c-7.963,0-14.424,6.464-14.424,14.427v57.694h81.185V14.427H99.211z "></path>{" "}
            <path d="M208.354,141.341c0,39.008-17.065,80.993-54.56,80.993c-37.509,0-54.583-41.985-54.583-80.993v-56.4H18.026v91.366 c0,72.376,60.898,131.247,135.769,131.247c74.855,0,135.738-58.871,135.738-131.247V84.941h-81.179V141.341z"></path>{" "}
          </g>{" "}
        </g>{" "}
      </g>
    </svg>
  );
};
export default MRI;
