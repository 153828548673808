const CT = () => {
  return (
    <svg
      height="40px"
      width="40px"
      viewBox="9 12 60 60"
      id="emoji"
      xmlns="http://www.w3.org/2000/svg"
      fill="#515dba"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#515dba"
        strokeWidth="1.2280000000000002"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <g id="color">
          {" "}
          <path
            fill="none"
            d="M11.7973,32.1459H24.7654a1.6942,1.6942,0,0,1,1.2068.502,14.3889,14.3889,0,0,1,2.5537,3.0972l.0151.0527a1.6913,1.6913,0,0,1-1.6555,2.1059l-15.0669-.008a1.7012,1.7012,0,0,1-1.7-1.6852l-.0222-2.3473A1.7012,1.7012,0,0,1,11.7973,32.1459Z"
          ></path>{" "}
        </g>{" "}
        <g id="line">
          {" "}
          <path
            fill="none"
            stroke="#515dba"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.504"
            d="M16.9855,42.7615A20.43,20.43,0,0,0,56.586,35.2777a20.4308,20.4308,0,0,0-39.6845-7.2572s8.7984.2756,8.8872.1679c3.721-4.5128,6.0234-5.59,10.3188-5.59a12.6819,12.6819,0,0,1,0,25.3593,12.3643,12.3643,0,0,1-10.1984-5.412"
          ></path>{" "}
          <line
            x1="17.3324"
            x2="24.5731"
            y1="42.412"
            y2="42.4557"
            fill="none"
            stroke="#515dba"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.304"
          ></line>{" "}
          <path
            stroke="#515dba"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.304"
            d="M10.186,40.904l26.07.0783a3.232,3.232,0,0,1-2.0356,1.4092c-1.2526.1566-24.0687.036-24.0687.036Z"
          ></path>{" "}
          <path
            fill="none"
            stroke="#515dba"
            strokeMiterlimit="10"
            strokeWidth="2.304"
            d="M11.7973,32.1459H24.7654a1.6942,1.6942,0,0,1,1.2068.502,14.3889,14.3889,0,0,1,2.5537,3.0972l.0151.0527a1.6913,1.6913,0,0,1-1.6555,2.1059l-15.0669-.008a1.7012,1.7012,0,0,1-1.7-1.6852l-.0222-2.3473A1.7012,1.7012,0,0,1,11.7973,32.1459Z"
          ></path>{" "}
          <ellipse cx="32.7995" cy="34.8192" rx="3.2175" ry="3.1989"></ellipse>{" "}
        </g>{" "}
      </g>
    </svg>
  );
};
export default CT;
