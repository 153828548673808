import { useState } from "react";
import { useSignup } from "../hooks/useSignup";
import { Link } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { user } = useAuthContext();
  // const [username, setUsername] = useState("");
  // const [firstname, setFirstname] = useState("");
  // const [middlename, setMiddlename] = useState("");
  // const [lastname, setLastname] = useState("");
  const { signup, error, isLoading } = useSignup();

  const handleSubmit = async (e) => {
    e.preventDefault();

    await signup(email, password);

    console.log(email, password);
  };

  return (
    <form className="signup login" onSubmit={handleSubmit}>
      {!user && (
        <>
          <h3>Зарегистрироваться</h3>
          <label>Электронная почта:</label>
          <input
            className="inputLoginSignup"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />

          <label>Пароль:</label>
          <input
            className="inputLoginSignup"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />

          <button disabled={isLoading}>Зарегистрироваться</button>
          <p style={{ fontSize: "13px" }}>Уже есть аккаунт? Логинимся</p>
          <Link to="/login">
            <button>Войти</button>
          </Link>
        </>
      )}
      {user && (
        <>
          <h2>Спасибо за регистрацию!</h2>
          <Link to="/login">
            <button>Начать заканчивать пораньше</button>
          </Link>
        </>
      )}
      {error && (
        <div
          className="error"
          style={{
            color: "red",
          }}
        >
          {error}
        </div>
      )}
    </form>
  );
};

export default Signup;
