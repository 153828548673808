import { Link } from "react-router-dom";

const RybaRow = ({
  protocol,
  rybaList,
  setRybaContext,
  setOpenContext,
  setTop,
  setLeft,
  rybaOBJECT,
  dateNormalizer,
  navigate,
  duplicateRyba,
  setAreYouSureOpen,
}) => {
  const array = rybaList.filter((a) => a.protocol === protocol);
  if (array.length === 0) {
    return (
      <div className="rybaRow">
        <div className="rybaRowProtocol">{protocol}</div>
        <div className="rybaRowTitle">-</div>

        <div className="rybaRowCreatedAt">-</div>
        <div className="rybaRowCreatedAt">-</div>
        <span
          className="rybaRowButtonEdit"
          onClick={() => {
            navigate(`/sharedRybas`, "_blank");
          }}
        >
          Импортировать
        </span>
        {/* <span
          className="rybaRowButtonEdit"
          onClick={() => {
            duplicateRyba(ryba);
          }}
        >
          Дублировать
        </span>

        <span
          className="rybaRowButtonDelete"
          onClick={() => {
            rybaOBJECT.current = ryba;
            setAreYouSureOpen(true);
          }}
        >
          Удалить
        </span> */}
      </div>
    );
  }

  if (array.length > 0) {
    return array.map((ryba) => (
      <div className="rybaRow">
        <div className="rybaRowProtocol">{ryba.protocol}</div>
        <div className="rybaRowTitle">
          <Link
            to={`/primary/${ryba._id}`}
            onContextMenu={(e) => {
              e.preventDefault();
              setRybaContext(ryba);
              rybaOBJECT.current = ryba;
              setOpenContext(true);
              setLeft(e.pageX);
              setTop(e.pageY);
            }}
          >
            <li ryba={ryba._id}>
              {ryba.title}
              {ryba.isChild && " 🆕"}
            </li>
          </Link>
        </div>

        <div className="rybaRowCreatedAt">{dateNormalizer(ryba.createdAt)}</div>
        <div className="rybaRowCreatedAt">{dateNormalizer(ryba.updatedAt)}</div>
        <span
          className="rybaRowButtonEdit"
          onClick={() => {
            navigate(`/editRyba2/${ryba._id}`);
          }}
        >
          Редактировать
        </span>
        <span
          className="rybaRowButtonEdit"
          onClick={() => {
            duplicateRyba(ryba);
          }}
        >
          Дублировать
        </span>

        <span
          className="rybaRowButtonDelete"
          onClick={() => {
            rybaOBJECT.current = ryba;
            setAreYouSureOpen(true);
          }}
        >
          Удалить
        </span>
      </div>
    ));
  }
};
export default RybaRow;
