const ZoomIn = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={24}
      height={24}
      viewBox="0 0 511.999 511.999"
    >
      <path
        d="m484.272 453.297-152.001-152c25.045-30.438 40.102-69.387 40.102-111.789 0-97.162-79.046-176.208-176.208-176.208S19.957 92.346 19.957 189.508s79.046 176.206 176.208 176.206c35.586 0 68.734-10.614 96.467-28.828L446.656 490.91a26.517 26.517 0 0 0 18.808 7.79 26.517 26.517 0 0 0 18.808-7.79c10.385-10.385 10.385-27.226 0-37.613z"
        style={{
          fill: "#4A5FA0",
        }}
      />
      <path
        d="M73.151 189.506c0-67.83 55.183-123.013 123.013-123.013s123.013 55.183 123.013 123.013-55.183 123.013-123.013 123.013S73.151 257.337 73.151 189.506z"
        style={{
          fill: "#efefef",
        }}
      />
      <path d="M493.675 443.895 349.931 300.149c23.122-32.11 35.74-70.953 35.74-110.643C385.671 85.012 300.659 0 196.165 0S6.658 85.012 6.658 189.506 91.67 379.013 196.164 379.013c33.348 0 65.794-8.715 94.493-25.293l146.594 146.594c7.535 7.535 17.554 11.684 28.212 11.684s20.677-4.149 28.212-11.686c15.554-15.552 15.554-40.863 0-56.417zm-18.807 37.612a13.209 13.209 0 0 1-9.404 3.895 13.209 13.209 0 0 1-9.404-3.895L302.035 327.483a13.289 13.289 0 0 0-9.408-3.895 13.26 13.26 0 0 0-7.296 2.184c-26.543 17.431-57.376 26.644-89.168 26.644-89.829 0-162.909-73.08-162.909-162.909s73.08-162.909 162.909-162.909 162.909 73.08 162.909 162.909c0 37.586-13.166 74.285-37.073 103.34-4.35 5.286-3.975 13.011.864 17.854l152.003 152.001c5.186 5.185 5.186 13.622.002 18.805z" />
      <path d="M196.164 53.195c-75.163 0-136.312 61.149-136.312 136.312s61.15 136.312 136.312 136.312 136.312-61.149 136.312-136.312S271.327 53.195 196.164 53.195zm0 246.026c-60.496 0-109.714-49.218-109.714-109.714S135.669 79.792 196.164 79.792s109.714 49.218 109.714 109.714-49.216 109.715-109.714 109.715z" />
      <path d="M260.891 176.208h-51.437v-50.181c0-7.344-5.955-13.299-13.299-13.299-7.344 0-13.299 5.955-13.299 13.299v50.181h-50.963c-7.344 0-13.299 5.955-13.299 13.299 0 7.344 5.955 13.299 13.299 13.299h50.963v52.219c0 7.344 5.955 13.299 13.299 13.299 7.344 0 13.299-5.955 13.299-13.299v-52.219h51.437c7.344 0 13.299-5.955 13.299-13.299-.001-7.344-5.955-13.299-13.299-13.299z" />
    </svg>
  );
};
export default ZoomIn;
