import { useEffect, useState, useRef } from "react";
import InputPrompt from "./editing/InputPrompt";
import { useRecoilState } from "recoil";
import { textState } from "../state/atoms/textareaText";
import { promptStateAtom } from "../state/atoms/promptState";
import { formStateAtom } from "../state/atoms/formState";
import { fullRYBAatom } from "../state/atoms/fullRYBAatom";
import { zaklStateAtom } from "../state/atoms/zaklState";
import { useParams } from "react-router-dom";
import { rybaIDatom } from "../state/atoms/rybaID";
import Notification from "./editing/NotificationWindow";
import "./Ryba4.css";
import "./editing/NotificationWindow";
import { useAuthContext } from "../hooks/useAuthContext";
import CopyAll from "./svg/CopyAll";
import ResetToNorm from "./svg/ResetToNorm";
import EditRyba from "./svg/EditRyba";
import { Tooltip } from "react-tooltip";
import { Link } from "react-router-dom";
import React from "react";
import ConstructorRouter from "./smallComponents/constructorRouter";
import CopyProtocol from "./svg/CopyProtocol";
import CopyImpression from "./svg/CopyImpression";
import TextareaAutosize from "react-textarea-autosize";
import Buttons from "../components/smallComponents/buttons";
import ZoomIn from "./svg/ZoomIn";
import ZoomOut from "./svg/ZoomOut";
import SaveButton from "./svg/SaveButton";
import TXT from "./svg/TXT";
import { saveAs } from "file-saver";
import DOC from "./svg/DOC";
import { Document, Packer, Paragraph, TextRun, AlignmentType } from "docx";
import OK from "./svg/OK";
import Textarea from "./rybaComponents/textarea";
import { settingsAtom } from "../state/atoms/settings";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import SadFish from "./images/SadFish.png";
import Shape from "./shapes/shapes";
import Shape2 from "./shapes/shapes2";
import Brain from "./shapes/Nuclei.png";

const Ryba4 = () => {
  const [rybaObject, setRybaObject] = useRecoilState(fullRYBAatom);
  const [text, setText] = useRecoilState(textState); // recoil state of the textarea
  const [rybaIdRecoil, setRybaIdRecoil] = useRecoilState(rybaIDatom); // recoil state of the textarea
  const [promptText, setPromptText] = useRecoilState(promptStateAtom);
  const [formState, setFormState] = useRecoilState(formStateAtom);
  const [zaklState, setZaklState] = useRecoilState(zaklStateAtom);
  const [settings, setSettings] = useRecoilState(settingsAtom);
  const { id } = useParams();
  const [notificationWindowOpen, setNotificationWindowOpen] = useState(false);
  const [inputPromptOpen, setInputPromptOpen] = useState(false);
  const [rated, setRated] = useState(true);
  const { user } = useAuthContext();
  ////////// Ryba4 state ////////
  const [organFocus, setOrganFocus] = useState(2000);
  const [rybaLoaded, setRybaLoaded] = useState(false);
  const [reset, setReset] = useState(false);
  const [constructorOpen, setConstructorOpen] = useState(false);
  const [printData, setPrintData] = useState({});
  const [dinamika, setDinamika] = useState(false);
  const [notes, setNotes] = useState();
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(-360);
  const [pressed, setPressed] = useState(false);
  const [nameReportOpen, setNameReportOpen] = useState(false);
  const [reportTitle, setReportTitle] = useState(null);
  const [reportTitle2, setReportTitle2] = useState(null);
  const [saved, setSaved] = useState(false);
  const [beenModified, setBeenModified] = useState(false);
  const [error, setError] = useState("");
  const [zoom, setZoom] = useState();

  // const [buffer, setBuffer, undo, redo] = useUndoRedo([], 100);
  // const [buffer2, setBuffer2, undo2, redo2] = useUndoRedo(undefined, 100);

  useEffect(() => {
    const getFullRyba = async (req, res) => {
      setRybaIdRecoil(id);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/pond/full/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();
      // setting up ryba obj
      let r = { ...json };
      // console.log("rybaObject: ", r);
      const localStorageTXT = JSON.parse(localStorage.getItem(id));
      const z = JSON.parse(localStorage.getItem("zoom"));
      setZoom(z);
      if (!z) {
        setZoom(1);
      }
      const notes = JSON.parse(localStorage.getItem("notes" + id));
      // console.log("длина рыбы совпадает?", r.organs, localStorageTXT);
      if (response.ok) {
        // console.log("rybaObject: ", r);
        setRybaObject(r);

        setRybaLoaded(true);
        // set text state
        if (notes) {
          setNotes(notes);
        }
        if (!localStorageTXT) {
          // set raw text
          let t = [];
          let c = [];
          r.organs.map((o, organIndex) => {
            t.push([]);
            c.push([]);
            o.segments.map((s, segmentIndex) => {
              t[organIndex].push(s.norm);
              c[organIndex].push([]);
              s.buttons.map((b) => {
                c[organIndex][segmentIndex].push(false);
              });
            });
          });
          setText(t);
          setReset(c);

          let h = [];

          r.organs.map((o, organIndex) => {
            h.push([]);
            o.segments.map((s, segmentIndex) => {
              h[organIndex].push([]);
              if (s.buttons) {
                s.buttons.map((b, buttonIndex) => {
                  h[organIndex][segmentIndex].push("");
                });
              }
            });
          });
        }

        if (localStorageTXT) {
          if (localStorageTXT.length !== r.organs.length) {
            setBeenModified(true);
          }
          let t = [];
          r.organs.map((o, organIndex) => {
            t.push([]);

            o.segments.map((s, segmentIndex) => {
              if (localStorageTXT[organIndex] !== undefined || null) {
                localStorageTXT[organIndex]
                  ? t[organIndex].push(
                      localStorageTXT[organIndex][segmentIndex]
                    )
                  : t[organIndex].push(s.norm);
                if (o.segments.length !== localStorageTXT[organIndex].length) {
                  setBeenModified(true);
                }
              }
            });
          });
          setText(t);
          // setText(localStorageTXT);
          let c = [];
          r.organs.map((o, organIndex) => {
            c.push([]);
            o.segments.map((s, segmentIndex) => {
              c[organIndex].push([]);
              s.buttons.map((b) => {
                c[organIndex][segmentIndex].push(false);
              });
            });
          });
          setReset(c);
        }
      }
      if (!response.ok) {
        setError(json);
      }
    };
    const getSettings = async function () {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/settings/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const stngs = await response.json();
      console.log("settings object: ", stngs);
      if (response.ok) {
        setSettings(stngs);
        let p = {};
        p.hospital = stngs.rybaHeaders[0];
        setPrintData(p);
      }
    };
    if (user) {
      getFullRyba();
      getSettings();
    }
  }, [user]);

  // счётчики сегментов и текстовых полей рыбы
  let keyCounter = 0;
  let zaklRef = useRef("");
  let notificationText = useRef();
  let segmentNumber = useRef();
  let organNumber = useRef();
  let whichClick = useRef();
  let buttonINDEX = useRef("");
  let segmentINDEX = useRef("");
  let organINDEX = useRef("");
  let constructorREF = useRef({});

  // notification function
  const notification = function (message) {
    notificationText.current = message;

    setNotificationWindowOpen(true);
    setTimeout(() => {
      setNotificationWindowOpen(false);
    }, 1100);
  };
  ///////// DATE AND TIME FUNCTIONS ///////////
  const createDate = () => {
    const currentdate = new Date();
    const day = `${
      currentdate.getDate() > 9
        ? currentdate.getDate()
        : "0" + currentdate.getDate()
    }`;
    const month = `${
      currentdate.getMonth() + 1 > 9
        ? currentdate.getMonth() + 1
        : "0" + currentdate.getMonth() + 1
    }`;
    const year = `${currentdate.getFullYear()}`;
    return `${day}.${month}.${year}`;
  };
  const createTime = () => {
    const currentdate = new Date();
    const minutes = `${
      currentdate.getMinutes() > 9
        ? currentdate.getMinutes()
        : "0" + currentdate.getMinutes()
    }`;
    const hours = `${
      currentdate.getHours() > 9
        ? currentdate.getHours()
        : "0" + currentdate.getHours()
    }`;
    return `${hours}:${minutes}`;
  };

  // handle input prompt textarea change
  const handleInputPromptTextareaChange = (e) => {
    let f = [...promptText];
    f[1] = e.target.value;
    setPromptText(f);
  };

  // set input prompt value
  const setPromptInputValue = (organIndex, segmentIndex, buttonIndex) => {
    let q = [...promptText];
    const i = q[0] + q[1] + q[2];
    if (i.includes("...")) {
      setInputPromptOpen(true);
      let g = [];
      let y = i;
      g[0] = y.slice(0, y.indexOf("..."));
      g[1] = "";
      g[2] = y.slice(y.indexOf("...") + 3);

      return setPromptText(g);
    }

    let t = [...text];
    ///////
    t[organINDEX.current][segmentINDEX.current] += rybaObject.organs[
      organINDEX.current
    ].segments[segmentINDEX.current].buttons[buttonINDEX.current].newline
      ? "\n" + i
      : i;
    t[organINDEX.current][segmentINDEX.current] =
      t[organINDEX.current][segmentINDEX.current].trim();
    setText(t);

    setInputPromptOpen(false);
  };

  // BUTTON copy text from ALL textareas
  const clickHandlerCopyToClipboardALL = function (e) {
    autosaveReport();
    const x = [...text];
    const orgns = [...rybaObject.organs];
    let rybaString = "";
    orgns.map((organ, organIndex) => {
      let organTextString = "";
      organ.segments.map((segment, segmentIndex) => {
        let segmentText = x[organIndex][segmentIndex];
        if (segmentText) {
          segmentText = segmentText.trim();
        }
        if (segmentText.slice(0, 1) === "-") {
          segmentText = "\n" + segmentText;
        }
        if (segmentText.slice(-1) !== "." && segmentText !== "") {
          segmentText += ". ";
        }
        if (segmentText.slice(-1) === ".") {
          segmentText += " ";
        }
        organTextString += segmentText;
      });

      let organTitleString = organ.title;
      organTitleString = organTitleString.trim();
      if (organTitleString.slice(-1) !== ":") {
        organTitleString += ": ";
      }
      if (organTitleString.slice(-1) === ":") {
        organTitleString += " ";
      }

      if (organIndex === 0) {
        rybaString += organTextString + "\n";
      }
      if (
        organIndex > 0 &&
        organIndex < rybaObject.organs.length - 1 &&
        organTextString.trim() !== ""
      ) {
        rybaString += organTitleString + organTextString + "\n";
      }
      if (organIndex === rybaObject.organs.length - 1) {
        rybaString += "\n" + organTitleString + organTextString;
      }
      // rybaString += organTitleString + organTextString + "\n";
    });

    navigator.clipboard.writeText(rybaString);
    notification("Текст протокола успешно скопирован🔥");
  };

  const clickHandlerCopyToClipboardProtocol = function (e) {
    autosaveReport();
    const x = [...text];
    const orgns = [...rybaObject.organs];
    orgns.splice(-1, 1);
    let rybaString = "";

    orgns.map((organ, organIndex) => {
      let organTextString = "";
      organ.segments.map((segment, segmentIndex) => {
        let segmentText = x[organIndex][segmentIndex];
        if (segmentText) {
          segmentText = segmentText.trim();
        }
        if (segmentText.slice(0, 1) === "-") {
          segmentText = "\n" + segmentText;
        }
        if (segmentText.slice(-1) !== "." && segmentText !== "") {
          segmentText += ". ";
        }
        if (segmentText.slice(-1) === ".") {
          segmentText += " ";
        }
        organTextString += segmentText;
      });

      let organTitleString = organ.title;
      organTitleString = organTitleString.trim();
      if (organTitleString.slice(-1) !== ":") {
        organTitleString += ": ";
      }
      if (organTitleString.slice(-1) === ":") {
        organTitleString += " ";
      }

      if (organIndex === 0) {
        rybaString += organTextString + "\n";
      }
      if (
        organIndex > 0 &&
        organIndex < rybaObject.organs.length - 1 &&
        organTextString.trim() !== ""
      ) {
        rybaString += organTitleString + organTextString + "\n";
      }
      if (organIndex === rybaObject.organs.length - 1) {
        rybaString += "\n" + organTitleString + organTextString;
      }
    });

    navigator.clipboard.writeText(rybaString.trim());
    notification("Текст протокола успешно скопирован🔥");
  };

  const clickHandlerCopyToClipboardImpression = function (e) {
    autosaveReport();
    const x = [...text];
    const orgns = [...rybaObject.organs];

    let rybaString = "";

    orgns.map((organ, organIndex) => {
      let organTextString = "";
      organ.segments.map((segment, segmentIndex) => {
        let segmentText = x[organIndex][segmentIndex];
        if (segmentText) {
          segmentText = segmentText.trim();
        }

        if (segmentText.slice(-1) !== "." && segmentText !== "") {
          segmentText += ". ";
        }
        if (segmentText.slice(-1) === ".") {
          segmentText += " ";
        }
        organTextString += segmentText;
      });

      let organTitleString = organ.title;
      organTitleString = organTitleString.trim();
      if (organTitleString.slice(-1) !== ":") {
        organTitleString += ": ";
      }
      if (organTitleString.slice(-1) === ":") {
        organTitleString += " ";
      }

      if (organIndex === 0) {
        return;
      }
      if (organIndex > 0 && organIndex < rybaObject.organs.length - 1) {
        return;
      }
      if (organIndex === rybaObject.organs.length - 1) {
        rybaString = organTextString;
      }
    });

    navigator.clipboard.writeText(rybaString.trim());
    notification("Текст заключения успешно скопирован🔥");
  };

  //////////////////// RATE IMPORTED RYBA ////////////////////
  const rateSharedRyba = async (req, res, parentRybaIdAttribute, grade) => {
    /// get parent ryba
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/pond/${parentRybaIdAttribute}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const rate = Number(grade);
    const parentRyba = await response.json();
    let ratings = [...parentRyba.ratings, rate];
    const averageRating =
      ratings.reduce((a, b) => a * 1 + b * 1) / ratings.length;

    /// patch parent ryba
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/pond/${parentRybaIdAttribute}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          averageRating,
          ratings,
          ratingsLength: ratings.length,
        }),
      }
    );
    // reset this ryba isChild (so you can't rate it again)
    await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/pond/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        isChild: false,
      }),
    });

    setRated(false);
  };

  ///////
  const rateButtons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  ////////////////////////

  const buttonClick = (e, organIndex, segmentIndex, buttonIndex, zakl) => {
    let t = [...text];
    if (
      t[organIndex][segmentIndex] ===
      rybaObject.organs[organIndex].segments[segmentIndex].norm
    ) {
      t[organIndex][segmentIndex] = "";
    }
    if (
      e.target.checked === true &&
      rybaObject.organs[organIndex].segments[segmentIndex].buttons[buttonIndex]
        .type === "constructor"
    ) {
      return;
    }

    const rybaLength = rybaObject.organs.length - 1;
    const btnObj =
      rybaObject.organs[organIndex].segments[segmentIndex].buttons[buttonIndex];
    if (t[rybaLength][0] === rybaObject.organs[rybaLength].segments[0].norm) {
      t[rybaLength][0] = "";
    }

    if (!t[rybaLength][0].includes(zakl) && zakl !== undefined && zakl !== "") {
      t[rybaLength][0] = t[rybaLength][0].trim() + " " + zakl.trim();
    }

    checkForDots(e.target.value);
    if (e.target.value.includes("...")) return;
    t[organIndex][segmentIndex] = t[organIndex][segmentIndex].trim();
    t[organIndex][segmentIndex] += btnObj.newline
      ? "\n" + e.target.value
      : e.target.value;
    t[organIndex][segmentIndex] = t[organIndex][segmentIndex].trim();

    setText(t);
    // let b = [...undoBuffer];

    // b.splice(0, 0, t);
    // setUndoBuffer(b);
    // undoCounter.current = 0;
    // console.log(b);
  };

  // BUTTON adding text to the textarea
  const checkForDots = function (bTextContent) {
    let y = bTextContent;

    if (y.includes("...")) {
      setInputPromptOpen(true);
      let g = [];
      g[0] = y.slice(0, y.indexOf("..."));
      g[1] = "";
      g[2] = y.slice(y.indexOf("...") + 3);

      return setPromptText(g);
    }
  };

  const rL = function (rO) {
    return rO.organs.length - 1;
  };

  // dinamika constructor function
  const dinamikaConstructorFunction = function (opisanie, zakl) {
    navigator.clipboard.writeText(opisanie);
    notification("Текст для описания скопирован");
    let t = `${text}`;
    if (!t.includes(zakl) && zakl) {
      t += zakl;
    }
  };

  const rateColor = [
    "red",
    "red",
    "orange",
    "orange",
    "yellow",
    "yellow",
    "lightgreen",
    "lightgreen",
    "green",
    "green",
  ];

  const saveReport = () => {
    ////////////////////////////////
    const reports = JSON.parse(localStorage.getItem(`savedReports${user.id}`));
    let contentForExport = "";
    const x = [...text];
    const orgns = [...rybaObject.organs];
    orgns.map((organ, organIndex) => {
      let organTextString = "";
      organ.segments.map((segment, segmentIndex) => {
        let segmentText = x[organIndex][segmentIndex];
        if (segmentText) {
          segmentText = segmentText.trim();
        }
        if (segmentText.slice(0, 1) === "-") {
          segmentText = "\n" + segmentText;
        }
        if (segmentText.slice(-1) !== "." && segmentText !== "") {
          segmentText += ". ";
        }
        if (segmentText.slice(-1) === ".") {
          segmentText += " ";
        }
        organTextString += segmentText;
      });

      let organTitleString = organ.title;
      organTitleString = organTitleString.trim();
      if (organTitleString.slice(-1) !== ":") {
        organTitleString += ": ";
      }
      if (organTitleString.slice(-1) === ":") {
        organTitleString += " ";
      }

      if (organIndex === 0) {
        contentForExport += organTextString + "\n";
      }
      if (
        organIndex > 0 &&
        organIndex < rybaObject.organs.length - 1 &&
        organTextString.trim() !== ""
      ) {
        contentForExport += organTitleString + organTextString + "\n";
      }
      if (organIndex === rybaObject.organs.length - 1) {
        contentForExport += "\n" + organTitleString + organTextString;
      }
      // rybaString += organTitleString + organTextString + "\n";
    });
    let reportObject = {
      id: id,
      title: reportTitle,
      date: createDate(),
      time: createTime(),
      contentForExport,
      content: text,
      protocol: rybaObject.protocol,
      modality: rybaObject.modality,
      timeFull: Date.now(),
      dinamika: false,
    };
    if (!reports) {
      window.localStorage.setItem(
        `savedReports${user.id}`,
        JSON.stringify([reportObject])
      );
    }

    if (reports) {
      reports.unshift(reportObject);
      window.localStorage.setItem(
        `savedReports${user.id}`,
        JSON.stringify(reports)
      );
    }
    notification("Описание успешно сохранено");
    setReportTitle("");

    setSaved(true);
  };

  const autosaveReport = async () => {
    ////////////////////////////////
    if (settings.autosaveOnCopy === false) return;
    const reports = JSON.parse(
      localStorage.getItem(`autosavedReports${user.id}`)
    );
    let contentForExport = "";
    const x = [...text];
    const orgns = [...rybaObject.organs];
    orgns.map((organ, organIndex) => {
      let organTextString = "";
      organ.segments.map((segment, segmentIndex) => {
        let segmentText = x[organIndex][segmentIndex];
        if (segmentText) {
          segmentText = segmentText.trim();
        }
        if (segmentText.slice(0, 1) === "-") {
          segmentText = "\n" + segmentText;
        }
        if (segmentText.slice(-1) !== "." && segmentText !== "") {
          segmentText += ". ";
        }
        if (segmentText.slice(-1) === ".") {
          segmentText += " ";
        }
        organTextString += segmentText;
      });

      let organTitleString = organ.title;
      organTitleString = organTitleString.trim();
      if (organTitleString.slice(-1) !== ":") {
        organTitleString += ": ";
      }
      if (organTitleString.slice(-1) === ":") {
        organTitleString += " ";
      }

      if (organIndex === 0) {
        contentForExport += organTextString + "\n";
      }
      if (
        organIndex > 0 &&
        organIndex < rybaObject.organs.length - 1 &&
        organTextString.trim() !== ""
      ) {
        contentForExport += organTitleString + organTextString + "\n";
      }
      if (organIndex === rybaObject.organs.length - 1) {
        contentForExport += "\n" + organTitleString + organTextString;
      }
      // rybaString += organTitleString + organTextString + "\n";
    });

    let reportObject = {
      id: id,
      title: "Автосохранение",
      date: createDate(),
      time: createTime(),
      content: text,
      contentForExport,
      zakl: text[rybaObject.organs.length - 1].join(" "),
      // zakl: x[rybaObject.organs.length - 1].join(" ").slice(0, 150) + "...",
      protocol: rybaObject.protocol,
      modality: rybaObject.modality,
      timeFull: Date.now(),
      dinamika: false,
    };
    if (!reports) {
      return window.localStorage.setItem(
        `autosavedReports${user.id}`,
        JSON.stringify([reportObject])
      );
    }
    if (
      reports.filter((a) => {
        return (
          a.id === id && JSON.stringify(a.content) === JSON.stringify(text)
        );
      }).length !== 0
    ) {
      return;
    }

    if (reports && !reports.includes(reportObject)) {
      reports.unshift(reportObject);
      window.localStorage.setItem(
        `autosavedReports${user.id}`,
        JSON.stringify(reports)
      );
    }

    notification("Автосохранение завершено");
    setReportTitle("");
    /// patch parent ryba
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/pond/numberTimesUsed/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
  };

  const exportTXT = () => {
    ////////// CREATE REPORT ///////
    const x = [...text];
    const orgns = [...rybaObject.organs];
    let rybaString = "";
    rybaString += "Модальность: " + rybaObject.modality + "\n";
    rybaString += "Исследование: " + rybaObject.protocol + "\n";

    rybaString += "Дата: " + createDate() + "\n\n";

    orgns.map((organ, organIndex) => {
      let organTextString = "";
      organ.segments.map((segment, segmentIndex) => {
        let segmentText = x[organIndex][segmentIndex];
        if (segmentText) {
          segmentText = segmentText.trim();
        }

        if (segmentText.slice(-1) !== "." && segmentText !== "") {
          segmentText += ". ";
        }
        if (segmentText.slice(-1) === ".") {
          segmentText += " ";
        }
        organTextString += segmentText;
      });

      let organTitleString = organ.title;
      organTitleString = organTitleString.trim();
      if (organTitleString.slice(-1) !== ":") {
        organTitleString += ": ";
      }
      if (organTitleString.slice(-1) === ":") {
        organTitleString += " ";
      }

      if (organIndex === 0) {
        rybaString += organTextString + "\n";
      }
      if (
        organIndex > 0 &&
        organIndex < rybaObject.organs.length - 1 &&
        organTextString.trim() !== ""
      ) {
        rybaString += organTitleString + organTextString + "\n";
      }
      if (organIndex === rybaObject.organs.length - 1) {
        rybaString += "\n" + organTitleString + organTextString;
      }
      // rybaString += organTitleString + organTextString + "\n";
    });

    ////////////////////////////////
    // const fileData = JSON.stringify(rybaString);
    const file = new Blob([rybaString], { type: "text/plain;charset=utf-8" });
    saveAs(file, `${rybaObject.protocol} ${createDate()}.txt`);
  };

  const exportWORD = async () => {
    ////////// CREATE REPORT ///////
    const x = [...text];
    const orgns = [...rybaObject.organs];
    let rybaString = "";

    const currentdate = new Date();

    orgns.map((organ, organIndex) => {
      let organTextString = "";
      organ.segments.map((segment, segmentIndex) => {
        let segmentText = x[organIndex][segmentIndex];
        if (segmentText) {
          segmentText = segmentText.trim();
        }

        if (segmentText.slice(-1) !== "." && segmentText !== "") {
          segmentText += ". ";
        }
        if (segmentText.slice(-1) === ".") {
          segmentText += " ";
        }
        organTextString += segmentText;
      });

      let organTitleString = organ.title;
      organTitleString = organTitleString.trim();
      if (organTitleString.slice(-1) !== ":") {
        organTitleString += ": ";
      }
      if (organTitleString.slice(-1) === ":") {
        organTitleString += " ";
      }

      if (organIndex === 0) {
        rybaString += organTextString + "\n";
      }
      if (
        organIndex > 0 &&
        organIndex < rybaObject.organs.length - 1 &&
        organTextString.trim() !== ""
      ) {
        rybaString += organTitleString + organTextString + "\n";
      }
      if (organIndex === rybaObject.organs.length - 1) {
        rybaString += "\n" + organTitleString + organTextString;
      }
      // rybaString += organTitleString + organTextString + "\n";
    });
    /////// CREATE DOCX //////

    const doc = new Document({
      styles: {
        default: {
          document: {
            run: {
              size: "12pt",
              font: "Arial",
            },
          },
        },
      },
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              children: settings.rybaHeaders[0]
                .split("\n")
                .map((line) => new TextRun({ break: 1, text: line })),
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun({
                  text: "ФИО: ",
                }),
              ],
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Дата рождения: ",
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Пол: ",
                }),
              ],
            }),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun({
                  text: rybaObject.protocol,
                  bold: true,
                  size: "16pt",
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({}),
            new Paragraph({}),

            new Paragraph({}),
            new Paragraph({
              children: rybaString
                .split("\n")
                .map(
                  (line) => new TextRun({ break: 1, text: line, size: "11pt" })
                ),
            }),

            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Врач-рентгенолог",
                  alignment: AlignmentType.LEFT,
                }),
                new TextRun({}),
              ],
            }),
            new Paragraph({
              text: `${settings.lastname} ${settings.firstname.slice(
                0,
                1
              )}. ${settings.middlename.slice(0, 1)}.`,
              alignment: AlignmentType.RIGHT,
            }),
            new Paragraph({
              text: `Дата: ${createDate()}`,
            }),
          ],
        },
      ],
    });
    //////////////////////////
    Packer.toBlob(doc).then((blob) => {
      // saveAs from FileSaver will download the file
      saveAs(blob, `${rybaObject.protocol} ${createDate()}.docx`);
    });

    ////////////////////////////////
    // const file = new Blob([doc], { type: "text/plain;charset=utf-8" });
    // saveAs(file, `${rybaObject.protocol} ${date}.docx`);
  };

  const updateLocalStorage = useDebouncedCallback((text) => {
    window.localStorage.setItem(`${id}`, JSON.stringify(text));
  }, 1000);

  useEffect(() => {
    if (saved) {
      setSaved(false);
    }
    updateLocalStorage(text);
  }, [text]);

  // universal patch request
  const patchUser = async function () {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/settings`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({ ...settings }),
      }
    );
    const json = await response.json();

    if (response.ok) {
      console.log("patched settings: ", json);
    }
  };

  // BUTTON go to default ALL
  const goToDefaultAll = (e) => {
    setSaved(false);
    const rybaLength = rybaObject.organs.length - 1;
    let r = { ...rybaObject };
    let t = [];
    let c = [];
    let pupu = [];
    let prevReset = [...reset];
    r.organs.map((o, organIndex) => {
      t.push([]);
      c.push([]);
      pupu.push([]);

      o.segments.map((s, index) => {
        t[organIndex].push(s.norm);
        pupu[organIndex].push(false);
        c[organIndex][index] = !prevReset[organIndex][index];
      });
    });

    setText(t);
    setReset(c);

    let h = [];
    r.organs.map((o, organIndex) => {
      h.push([]);
      o.segments.map((s, segmentIndex) => {
        h[organIndex].push([]);
        s.buttons.map((b, buttonIndex) => {
          h[organIndex][segmentIndex].push(false);
        });
      });
    });
    t[rybaLength][0] = r.organs[rybaLength].segments[0].norm;
    setReset(h);
  };

  const updateSettings = useDebouncedCallback((text) => {
    patchUser();
  }, 3000);

  const doSmth = function () {
    console.log("svg кликнуто");
  };

  if (error) {
    return (
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          width: "100vw",
          textAlign: "center",
          marginTop: "200px",
        }}
      >
        <span
          style={{
            fontSize: "28px",
            color: "pink",
          }}
        >
          {error}
        </span>

        <div>
          <img
            src={SadFish}
            alt="Sad Fish"
            style={{
              width: "450px",
              margin: "30px",
              borderRadius: "10px",
              boxShadow: "0px 0px 15px 1px darkgrey",
            }}
          />
        </div>
      </div>
    );
  }
  if (!error) {
    return (
      rybaLoaded &&
      settings && (
        <div className="RYBA4rybaContainer">
          {nameReportOpen && (
            <div className="popupContainer">
              <div className="popupContent">
                <p>Название сохраняемого документа</p>
                <TextareaAutosize
                  autoFocus
                  className="inputPromptTextarea"
                  value={reportTitle}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      saveReport();
                      setNameReportOpen(false);
                    }
                    if (e.key === "Escape") {
                      setNameReportOpen(false);
                      setReportTitle("");
                    }
                  }}
                  onChange={(e) => {
                    setReportTitle(e.target.value);
                    setReportTitle2(e.target.value);
                  }}
                ></TextareaAutosize>
                {/* <p>Дополнительная информация (что угодно; необязательно)</p>
            <TextareaAutosize
              autoFocus
              className="inputPromptTextarea"
              value={info}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  saveReport();
                  setNameReportOpen(false);
                }
                if (e.key === "Escape") {
                  setNameReportOpen(false);
                  setReportTitle("");
                }
              }}
              onChange={(e) => {
                setInfo(e.target.value);
              }}
            ></TextareaAutosize> */}
                <button
                  className="saveChanges"
                  onClick={() => {
                    saveReport();
                    setNameReportOpen(false);
                    setReportTitle("");
                  }}
                >
                  Сохранить (Enter)
                </button>
                <button
                  className="closeTextEditWindow"
                  onClick={() => {
                    setNameReportOpen(false);
                    setReportTitle("");
                  }}
                >
                  Отмена (Esc)
                </button>
              </div>
            </div>
          )}
          {constructorOpen && (
            <ConstructorRouter
              {...{
                constructorREF,
                setConstructorOpen,
                formState,
                setFormState,
                text,
                setText,
                zaklState,
                setZaklState,
                rybaObject,
                dinamika,
                dinamikaConstructorFunction,
              }}
            />
          )}

          <div
            className="RYBA4ryba"
            key={(keyCounter += 1)}
            style={{
              zoom: zoom,
            }}
          >
            {/* <div>
          <TextareaAutosize
            value={buffer2}
            onChange={(e) => {
              setBuffer2(e.target.value);
            }}
          ></TextareaAutosize>

          <button
            onClick={() => {
              undo2();
              console.log(buffer);
            }}
          >
            Undo
          </button>
          <button
            onClick={() => {
              redo2();
            }}
          >
            redo
          </button>
        </div> */}
            <div className="anchorForNotes">
              {settings && settings.showNotes && (
                <div
                  className="notes"
                  style={{
                    top: `${top}px`,
                    left: `${left}px`,
                    cursor: `${pressed ? "grab" : "pointer"}`,
                  }}
                >
                  <div
                    className="notesTitle"
                    onMouseDown={(e) => {
                      setPressed(true);
                    }}
                    onMouseEnter={() => {
                      setPressed(false);
                    }}
                    onMouseUp={(e) => {
                      setPressed(false);
                    }}
                    onMouseMove={(e) => {
                      if (pressed) {
                        setLeft((l) => l + e.movementX);
                        setTop((t) => t + e.movementY);
                      }
                    }}
                  >
                    Заметки
                  </div>
                  <div className="notesText">
                    <TextareaAutosize
                      value={notes}
                      onChange={(e) => {
                        setNotes(e.target.value);

                        window.localStorage.setItem(
                          `notes${id}`,
                          JSON.stringify(e.target.value)
                        );
                      }}
                    ></TextareaAutosize>
                  </div>
                </div>
              )}
            </div>

            {!rybaLoaded && <div class="loader"></div>}
            {rybaLoaded && settings && (
              <>
                {inputPromptOpen && (
                  <InputPrompt
                    setInputPromptOpen={setInputPromptOpen}
                    promptText={promptText}
                    handleInputPromptTextareaChange={
                      handleInputPromptTextareaChange
                    }
                    setPromptInputValue={setPromptInputValue}
                  />
                )}
                {notificationWindowOpen && (
                  <Notification notificationText={notificationText.current} />
                )}
                {rybaObject.organs.length > 2 && (
                  <div className="RYBA4topButtons" key={(keyCounter += 1)}>
                    <div className="RYBA4topButtonsBorder">
                      <div
                        className="RYBA4topButton copyAll"
                        key={(keyCounter += 1)}
                        onClick={clickHandlerCopyToClipboardALL}
                        data-tooltip-id="skopirovatVse"
                        data-tooltip-delay-show="300"
                      >
                        <CopyAll height="96" width="96" />
                        <Tooltip
                          id="skopirovatVse"
                          content="Скопировать протокол и заключение"
                          place="bottom"
                          style={{ background: "black" }}
                        />
                      </div>
                      <div className="RYBA4topButtonsSmallAndTxtFormat">
                        <span
                          style={{
                            fontSize: "12px",
                            color: "grey",
                            marginLeft: "70px",
                          }}
                        >
                          Работа с шаблоном
                        </span>
                        <div className="RYBA4ropButtonsSmall">
                          <div
                            className="RYBA4topButton"
                            key={(keyCounter += 1)}
                            onClick={clickHandlerCopyToClipboardProtocol}
                            data-tooltip-id="CopyProtocol"
                            data-tooltip-delay-show="300"
                          >
                            <CopyProtocol />
                            <Tooltip
                              id="CopyProtocol"
                              content="Скопировать только протокол"
                              place="bottom"
                              style={{ background: "black" }}
                            />
                          </div>
                          <div
                            className="RYBA4topButton"
                            key={(keyCounter += 1)}
                            onClick={clickHandlerCopyToClipboardImpression}
                            data-tooltip-id="CopyImp"
                            data-tooltip-delay-show="300"
                          >
                            <CopyImpression />
                            <Tooltip
                              id="CopyImp"
                              content="Скопировать только заключение"
                              place="bottom"
                              style={{ background: "black" }}
                            />
                          </div>
                          <div
                            className="RYBA4topButton"
                            key={(keyCounter += 1)}
                            onClick={goToDefaultAll}
                            data-tooltip-id="goToNorm"
                            data-tooltip-delay-show="300"
                          >
                            <ResetToNorm height="48" width="48" />
                            <Tooltip
                              id="goToNorm"
                              content="Сбросить всё до нормы"
                              place="bottom"
                              style={{ background: "black" }}
                            />
                          </div>

                          {/* <div
                        className="RYBA4topButton"
                        key={(keyCounter += 1)}
                        onClick={clickHandlerDeleteTextALL}
                        data-tooltip-id="clearAll"
                        data-tooltip-delay-show="300"
                      >
                        <DeleteAllText width="48" height="48" />
                        <Tooltip
                          id="clearAll"
                          content="Очистить все поля"
                          place="bottom"
                          style={{ background: "black" }}
                        />
                      </div> */}
                          <Link
                            to={`/editRyba2/${rybaObject._id}`}
                            style={{
                              border: "none",
                              padding: "0px",
                              margin: "0px",
                            }}
                            // target="_blank"
                          >
                            <div
                              className="RYBA4topButton"
                              data-tooltip-id="editRYba"
                              data-tooltip-delay-show="300"
                            >
                              <EditRyba />
                              <Tooltip
                                id="editRYba"
                                content="Редактировать шаблон"
                                place="bottom"
                                style={{ background: "black" }}
                              />
                            </div>
                          </Link>
                          <div
                            className="RYBA4topButton saveButtonDiv"
                            data-tooltip-id="Save"
                            data-tooltip-delay-show="300"
                            onClick={() => {
                              setNameReportOpen(true);
                            }}
                          >
                            <SaveButton />
                            <Tooltip
                              id="Save"
                              content="Сохранить заключение"
                              place="bottom"
                              style={{ background: "black" }}
                            />
                            {saved && (
                              <div className="saveButtonDivOK">
                                <OK />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="dinamikaAndPrimary">
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            {/* <div
                          className="RYBA4topButton"
                          onClick={(e) => {
                            undo();
                          }}
                          data-tooltip-id="undo"
                          data-tooltip-delay-show="300"
                        >
                          <DOundo />

                          <Tooltip
                            id="undo"
                            content="Отменить действие"
                            place="bottom"
                            style={{ background: "black" }}
                          />
                        </div>
                        <div
                          className="RYBA4topButton"
                          onClick={(e) => {
                            redo();
                          }}
                          data-tooltip-id="redo"
                          data-tooltip-delay-show="300"
                        >
                          <DOredo />
                          <Tooltip
                            id="redo"
                            content="Повторить действие"
                            place="bottom"
                            style={{ background: "black" }}
                          />
                        </div> */}

                            <div
                              className="RYBA4topButton"
                              onClick={(e) => {
                                window.localStorage.setItem(
                                  `zoom`,
                                  JSON.stringify(zoom + 0.05)
                                );
                                setZoom((z) => z + 0.05);
                              }}
                              data-tooltip-id="ZoomIn"
                              data-tooltip-delay-show="300"
                            >
                              <ZoomIn />
                              <Tooltip
                                id="ZoomIn"
                                content="Увеличить мастштаб (приблизить)"
                                place="bottom"
                                style={{ background: "black" }}
                              />
                            </div>
                            <div
                              className="RYBA4topButton"
                              onClick={(e) => {
                                window.localStorage.setItem(
                                  `zoom`,
                                  JSON.stringify(zoom - 0.05)
                                );
                                setZoom((z) => z - 0.05);
                              }}
                              data-tooltip-id="ZoomOut"
                              data-tooltip-delay-show="300"
                            >
                              <ZoomOut />
                              <Tooltip
                                id="ZoomOut"
                                content="Уменьшить масштаб (отдалить)"
                                place="bottom"
                                style={{ background: "black" }}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <button className="dinamika dinActive">
                              Первичное
                            </button>{" "}
                            <Link
                              to={`/followup/${rybaObject._id}`}
                              style={{
                                border: "none",
                                padding: "0px",
                                margin: "0px",
                              }}
                              // target="_blank"
                            >
                              <button className="dinamika dinInactive">
                                Динамика
                              </button>
                            </Link>
                            <div
                              className="RYBA4topButton"
                              data-tooltip-id="DOC"
                              data-tooltip-delay-show="300"
                              onClick={() => {
                                exportWORD();
                              }}
                            >
                              <DOC />
                              <Tooltip
                                id="DOC"
                                content="Экспорт в WORD"
                                place="bottom"
                                style={{ background: "black" }}
                              />
                            </div>
                            <div
                              className="RYBA4topButton"
                              data-tooltip-id="TXT"
                              data-tooltip-delay-show="300"
                              onClick={() => {
                                exportTXT();
                              }}
                            >
                              <TXT />
                              <Tooltip
                                id="TXT"
                                content="Экспорт в TXT"
                                place="bottom"
                                style={{ background: "black" }}
                              />
                            </div>
                            {/* <div
                          className="RYBA4topButton"
                          data-tooltip-id="PDF"
                          data-tooltip-delay-show="300"
                          onClick={() => {
                            // print();
                            // window.open("/exportPDF", "_blank");
                            savePDF();
                          }}
                        >
                          <PDF />
                          <Tooltip
                            id="PDF"
                            content="Экспорт в PDF"
                            place="bottom"
                            style={{ background: "black" }}
                          />
                        </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="RYBA4topButtonsBorder2">
                        {/* <p
                      style={{
                        textAlign: "center",
                        color: "grey",
                        fontSize: "0.8rem",
                        paddingTop: "10px",
                        margin: "0px",
                        marginTop: "5px",
                        marginBottom: "9px",
                      }}
                    >
                      Модальность: {rybaObject && rybaObject.modality}
                    </p> */}
                        <p
                          style={{
                            textAlign: "center",
                            color: "grey",
                            fontSize: "0.8rem",
                            paddingTop: "10px",
                            margin: "14px",
                            marginBottom: "13px",
                          }}
                        >
                          {/* Протокол:  */}
                          {rybaObject && rybaObject.protocol}
                        </p>
                        <h2 className="RYBA4title" key={(keyCounter += 1)}>
                          {rybaObject.title}
                        </h2>
                      </div>
                      {saved && (
                        <div className="copiedText">
                          <p
                            style={{
                              padding: "0px",
                              margin: "0px",
                              marginBottom: "5px",
                              fontWeight: "500",
                            }}
                          >
                            Описание сохранено под названием "{reportTitle2}"
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {/* {settings && (
              <div>
                Учреждение:
                <select
                  value={printData.hospital}
                  onChange={(e) => {
                    let p = { ...printData };
                    p.hospital = e.target.value;
                    console.log(p);
                    setPrintData(p);
                  }}
                  style={{
                    width: "300px",
                    margin: "10px",
                  }}
                >
                  {settings.rybaHeaders.map((h) => (
                    <option value={h}>{h}</option>
                  ))}
                </select>
              </div>
            )} */}
                {rybaObject.isChild && rated && (
                  <div className="RYBA4rateRybaDiv">
                    <p>
                      Вы импортировали этот шаблон. Пожалуйста, оцените его от 1
                      до 10, это поможет другим коллегам в выборе!
                    </p>
                    {rateButtons.map((n, index) => (
                      <button
                        parentryba={rybaObject.parentRybaSharedID}
                        style={{
                          background: rateColor[index],
                        }}
                        value={n}
                        onClick={(e) => {
                          rateSharedRyba(
                            undefined,
                            undefined,
                            e.target.attributes[0].value,
                            e.target.value
                          );
                        }}
                        className="RYBA4rateRyba"
                      >
                        {n}
                      </button>
                    ))}
                  </div>
                )}
                {beenModified && (
                  <div className="RYBA4rateRybaDiv">
                    <p>
                      Структура шаблона и локальной резервной копии не совпадает
                      (скорее всего, Вы отредактировали шаблон на другом
                      устройстве, добавили или удалили орган или раздел),
                      текстовые блоки наверняка съехали; можно сбросить до
                      нормы, и всё восстановится
                    </p>

                    <button
                      onClick={(e) => {
                        setBeenModified(false);
                      }}
                    >
                      Ясно
                    </button>
                  </div>
                )}
                {/* <div className="svgAndImgContainer">
                  <img
                    src={Brain}
                    alt="Brain"
                    style={{
                      width: "320px",
                      height: "320px",
                    }}
                  ></img>
                  {<Shape2 {...{ height: 320, width: 320, doSmth }} />}
                </div> */}

                {rybaObject.organs.length === 3 && (
                  <>
                    <p style={{ textAlign: "center", paddingTop: "20px" }}>
                      Это пустой шаблон. Нажмите кнопку ниже, чтобы начать
                      добавлять в неё содержимое.
                    </p>

                    <Link
                      to={`/editRyba2/${rybaObject._id}`}
                      style={{
                        border: "none",
                        padding: "0px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <div
                        className="RYBA4topButton"
                        data-tooltip-id="editRYba2"
                        data-tooltip-delay-show="300"
                        style={{
                          border: "none",
                          padding: "10px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <EditRyba />
                        <Tooltip
                          id="editRYba2"
                          content="Редактировать шаблон"
                          place="bottom"
                          style={{ background: "black", zIndex: "10" }}
                        />
                      </div>
                    </Link>
                  </>
                )}

                <div className="RYBA4segmentsContainer">
                  {rybaObject.organs.map((organ, organIndex) => (
                    <div
                      className={`organ ${organIndex === 0 && "topLeft"} ${
                        organIndex === 1 && "bottomLeft marginBottom"
                      } ${organIndex === 2 && "topLeft"} ${
                        organIndex === rL(rybaObject) - 1 &&
                        "bottomLeft marginBottom"
                      } ${
                        organIndex === rL(rybaObject) && "bottomLeft topLeft"
                      }`}
                    >
                      <div
                        className={`organTitle ${
                          organIndex === 0 && "topLeft"
                        } ${organIndex === 1 && "bottomLeft"} ${
                          organIndex === 2 && "topLeft"
                        } ${
                          organIndex === rL(rybaObject) - 1 && "bottomLeft"
                        } ${
                          organIndex === rL(rybaObject) && "bottomLeft topLeft"
                        }`}
                        style={{
                          color: organFocus === organIndex && "white",
                          backgroundColor:
                            organFocus === organIndex && "rgb(16, 16, 16)",
                        }}
                        onClick={() => {
                          setOrganFocus(organIndex);
                        }}
                      >
                        {organ.title}
                      </div>

                      <div className="organSegments">
                        {organ.segments &&
                          organ.segments.map((segment, segmentIndex) => (
                            <div
                              className="sgmnt"
                              key={(keyCounter += 1)}
                              index={segmentIndex}
                            >
                              <Textarea
                                {...{
                                  organFocus,
                                  setOrganFocus,
                                  organIndex,
                                  segmentIndex,
                                }}
                              />

                              <div
                                className="segmentButtons"
                                style={{
                                  zIndex:
                                    organFocus === organIndex &&
                                    segmentIndex === 0 &&
                                    "5",
                                  opacity:
                                    organFocus === organIndex &&
                                    segmentIndex === 0 &&
                                    "1",
                                  padding: organ.segments.length === 1 && "0px",
                                }}
                              >
                                <p className="buttonsOrganTitle">
                                  {organ.title}
                                </p>
                                {organ.segments &&
                                  organ.segments.map(
                                    (segment, segmentIndex) => (
                                      <div className="segmentOne">
                                        <div
                                          className="fieldsetLegend"
                                          style={{
                                            color:
                                              text[organIndex][segmentIndex] ===
                                              rybaObject.organs[organIndex]
                                                .segments[segmentIndex].norm
                                                ? "darkgrey"
                                                : settings.changedSegmentColor,
                                          }}
                                        >
                                          {segment.title}
                                        </div>
                                        {segment &&
                                          segment.buttons.length === 0 && (
                                            <p
                                              style={{
                                                color: "grey",
                                                fontSize: "15px",
                                                paddingLeft: "3px",
                                              }}
                                            >
                                              Кнопок нет
                                            </p>
                                          )}
                                        {segment &&
                                          segment.buttons.map(
                                            (button, buttonIndex) => (
                                              <div>
                                                <Buttons
                                                  {...{
                                                    button,
                                                    buttonIndex,
                                                    buttonINDEX,
                                                    organINDEX,
                                                    segmentINDEX,
                                                    zaklRef,
                                                    setConstructorOpen,
                                                    constructorREF,
                                                    organIndex,
                                                    segmentIndex,
                                                    rybaObject,
                                                    setRybaObject,
                                                    id,
                                                    buttonClick,
                                                    reset,
                                                    setReset,
                                                  }}
                                                />
                                              </div>
                                            )
                                          )}
                                        {text[organIndex][segmentIndex] !==
                                          rybaObject.organs[organIndex]
                                            .segments[segmentIndex].norm && (
                                          <button
                                            className="goToNorm"
                                            value="Сбросить до нормы"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              let x = [...text];
                                              x[organIndex][segmentIndex] =
                                                segment.norm;
                                              setText(x);
                                            }}
                                          >
                                            Сбросить до нормы
                                          </button>
                                        )}
                                      </div>
                                    )
                                  )}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      )
    );
  }
};

export default Ryba4;
