import { useEffect, useState, useRef } from "react";
import { useRecoilState } from "recoil";
import { textState } from "../state/atoms/textareaText";
import ReactDOM from "react-dom";
import "./RybaPDF.css";

const RybaPDF = () => {
  const [print, setPrint] = useState(null); // recoil state of the textarea

  useEffect(() => {
    const printStorage = JSON.parse(localStorage.getItem("print"));
    console.log(printStorage);
    setPrint(printStorage);

    setTimeout(() => {
      window.print();
    }, 500);
  }, []);
  const today = new Date();

  return (
    <div>
      {print && (
        <div className="pageA4">
          <div className="printHeader">{print.hospital}</div>
          <h1>{print.title}</h1>
          <p>{print.text}</p>

          <div className="doctorName">
            <span>Врач-рентгенолог</span>

            <span>{print.doctor}</span>
          </div>
          <div className="date">
            Дата: {today.getDate()}.{today.getMonth() + 1}.{today.getFullYear()}
          </div>
        </div>
      )}
    </div>
  );
};

export default RybaPDF;
