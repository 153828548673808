import { useState } from "react";
import prostate from "../images/Prostate.png";

const ProstateCalc = ({ notification }) => {
  const [notallsizes, setNotallsizes] = useState(false);
  const [result, setResult] = useState("0");

  const [size1, setSize1] = useState();
  const [size2, setSize2] = useState();
  const [size3, setSize3] = useState();
  const [calculated, setCalculated] = useState(false);
  const [resultText, setResultText] = useState();

  // let size1;
  // let size2;
  // let size3;
  const calculate = () => {
    if (!size1 || !size2 || !size3) {
      return setNotallsizes(true);
    }
    if (size1 && size2 && size3) {
      setNotallsizes(false);
    }
    let x = (size1 * size2 * size3 * 3.14) / 6000;
    x = Math.round(x);
    if (x > 30) {
      const res = `Предстательная железа увеличена в размерах (измерения ${size1}x${size2}x${size3} мм, расчётный объём ${x} см3, норма <30 см3). `;
      navigator.clipboard.writeText(res);
      setResultText(res);
    }
    if (x <= 30) {
      const res = `Предстательная железа не увеличена (измерения ${size1}x${size2}x${size3} мм, расчётный объём ${x} см3, норма <30 см3). `;
      navigator.clipboard.writeText(res);
      setResultText(res);
    }
    setResult(x);

    notification(
      "Формулировка для заключения автоматически скопирована в буфер обмена",
      3000
    );
    setCalculated((c) => (c = !c));
    setSize1(undefined);
    setSize2(undefined);
    setSize3(undefined);
  };

  const enter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      calculate();
    }
  };

  return (
    <div className="calculator">
      <p className="name">Объём простаты</p>
      <div className="legend">
        <p>
          {
            "Подойдёт для любой сферы или эллипсоида (например, для округлой гематомы). "
          }
        </p>
      </div>

      <div className="inputVertical" key={calculated}>
        <input
          type="number"
          placeholder="Размер 1 в мм"
          value={size1}
          onChange={(e) => setSize1(e.target.value)}
          onKeyDown={enter}
        />
        <input
          type="number"
          placeholder="Размер 2 в мм"
          value={size2}
          onChange={(e) => setSize2(e.target.value)}
          onKeyDown={enter}
        />
        <input
          type="number"
          placeholder="Размер 3 в мм"
          value={size3}
          onChange={(e) => setSize3(e.target.value)}
          onKeyDown={enter}
        />
      </div>
      <div className="results">{result} см3</div>
      <button
        className="calculate"
        onClick={() => {
          calculate();
        }}
      >
        Рассчитать (Enter)
      </button>
      <div className="legend">
        {result !== "0" && result <= 30 && (
          <>
            <p>{resultText}</p>
          </>
        )}
        {result !== "0" && result > 30 && <p>{resultText}</p>}
        {notallsizes && <p style={{ color: "red" }}> ВВЕДИТЕ ВСЕ РАЗМЕРЫ</p>}
      </div>
    </div>
  );
};

export default ProstateCalc;
