const AreYouSure = ({ setAreYouSureOpen, deleteRybaRequest }) => {
  const enter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      setAreYouSureOpen(false);
    }
  };

  return (
    <div className="popupContainer">
      <div className="constructorWindow">
        <p className="name">
          Вы уверены, что хотите удалить шаблон? Отменить это действие{" "}
          <span
            style={{
              color: "red",
            }}
          >
            невозможно
          </span>
        </p>
        <button
          onClick={() => {
            setAreYouSureOpen(false);
            deleteRybaRequest();
          }}
        >
          Да, удалить шаблон
        </button>
        <button
          onClick={() => {
            setAreYouSureOpen(false);
          }}
        >
          Закрыть окно
        </button>
      </div>
    </div>
  );
};
export default AreYouSure;
