import "./editing.css";

const Notification = ({ notificationText }) => {
  return (
    <div className="notificationContainer">
      <div className="notificationWindow">
        <p>{notificationText}</p>
      </div>
    </div>
  );
};
export default Notification;
