const ResetToNorm = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      fill="#4A5FA0"
      stroke="#4A5FA0"
      strokeWidth={0.01}
      viewBox="-13.97 -13.97 493.59 493.59"
      {...props}
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={29.282}
        d="M230.459 232.825c-32.695 0-59.194-26.5-59.194-59.194s26.5-59.194 59.194-59.194 59.193 26.5 59.193 59.194c0 32.688-26.499 59.194-59.193 59.194zm-92.079 85.503v26.309h184.164v-26.309c0-36.326-29.443-65.77-65.77-65.77h-52.618c-36.332-.005-65.776 29.444-65.776 65.77zM434.316 124.27C394.407 49.457 315.976 1.85 230.459 1.85c-90.508 0-173.128 53.285-210.494 135.755-3.876 8.547-.082 18.618 8.473 22.501 8.541 3.856 18.619.088 22.501-8.473C82.804 81.301 153.265 35.856 230.459 35.856c72.202 0 138.502 39.773 172.855 102.469l-30.308 13.729 82.661 59.242 9.982-101.211-31.333 14.185zm-4.699 188.789c-8.398-4.209-18.611-.775-22.808 7.615-33.599 67.307-101.177 109.127-176.357 109.127-70.204 0-133.75-36.482-169.218-96.117l29.777-14.859-84.816-56.114L0 364.229l30.756-15.342c41.324 71.23 116.518 114.914 199.696 114.914 88.148 0 167.382-49.027 206.774-127.941 4.203-8.407.79-18.612-7.609-22.801z"
      />
      <path d="M230.459 232.825c-32.695 0-59.194-26.5-59.194-59.194s26.5-59.194 59.194-59.194 59.193 26.5 59.193 59.194c0 32.688-26.499 59.194-59.193 59.194zm-92.079 85.503v26.309h184.164v-26.309c0-36.326-29.443-65.77-65.77-65.77h-52.618c-36.332-.005-65.776 29.444-65.776 65.77zM434.316 124.27C394.407 49.457 315.976 1.85 230.459 1.85c-90.508 0-173.128 53.285-210.494 135.755-3.876 8.547-.082 18.618 8.473 22.501 8.541 3.856 18.619.088 22.501-8.473C82.804 81.301 153.265 35.856 230.459 35.856c72.202 0 138.502 39.773 172.855 102.469l-30.308 13.729 82.661 59.242 9.982-101.211-31.333 14.185zm-4.699 188.789c-8.398-4.209-18.611-.775-22.808 7.615-33.599 67.307-101.177 109.127-176.357 109.127-70.204 0-133.75-36.482-169.218-96.117l29.777-14.859-84.816-56.114L0 364.229l30.756-15.342c41.324 71.23 116.518 114.914 199.696 114.914 88.148 0 167.382-49.027 206.774-127.941 4.203-8.407.79-18.612-7.609-22.801z" />
    </svg>
  );
};
export default ResetToNorm;
