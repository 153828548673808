import "./SharedRybas.css";
import { protocolsMRI } from "../data/protocolsMRI";
import { protocolsCT } from "../data/protocolsCT";
import { protocolsUS } from "../data/protocolsUS";
import { protocolsMAMMOGRAPHY } from "../data/protocolsMAMMOGRAPHY";
import { protocolsXRAY } from "../data/protocolsXRAY";
import { protocolsPET } from "../data/protocolsPET";
import { useEffect, useState, useRef } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import Notification from "../components/editing/NotificationWindow";

export function SharedRybas() {
  const [rybaList, setRybaList] = useState(null);
  const [segmentsList, setSegmentsList] = useState(null);
  const [buttonsList, setButtonsList] = useState(null);
  const [ryba, setRyba] = useState(null);
  const [modalityState, setModalityState] = useState(null);
  const [sortClicked, setSortClicked] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [notificationWindowOpen, setNotificationWindowOpen] = useState(false);
  const { user } = useAuthContext();
  const [settings, setSettings] = useState(null);
  const [showButtons, setShowButtons] = useState(false);
  const [loaded, setLoaded] = useState(false);

  // ref variables
  let rybaMODALITY = useRef("");
  let rybaID = useRef("");
  let rybaTITLE = useRef("");

  let createdRybaID = useRef("");
  let createdSegmentID = useRef("");
  let buttonsListRef = useRef(undefined);
  let searchByTitle = useRef("");
  let searchByText = useRef("");
  //////////// QUERY COMPONENTS ////////////////

  // FILTERS //
  let titleQuery = useRef("");
  let modalityQuery = useRef("");
  let protocolQuery = useRef("");

  // SORTING //
  let sortRef = useRef("");
  let pageLimitRef = useRef("&limit=20");

  // LIMITING //
  let pageLimit = useRef(20);

  // search filter refs
  let searchByModality = useRef("all");

  // notification
  let notificationText = useRef("");
  const notification = function (message) {
    notificationText.current = message;

    setNotificationWindowOpen(true);
    setTimeout(() => {
      setNotificationWindowOpen(false);
    }, 1100);
  };

  useEffect(() => {
    if (user) {
      megaRequest();
    }
  }, [user]);

  let keyCounter = -1;

  //////////// preview window open function
  const previewOpenFunction = function (e) {
    rybaTITLE.current = e.target.textContent;
    rybaMODALITY.current = e.target.attributes[1].value;

    const x = e.target.attributes[0].value;
    rybaID.current = x;

    setPreviewOpen(true);

    const getFullRyba = async (req, res) => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/pond/full/${x}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();

      if (response.ok) {
        setRyba(json);
      }
    };
    getFullRyba();
    //сделать чтобы предпросмотр давал только сегменты и норму, а полный предпросмотр
    // открывал новое окно с чужой рыбой чтобы полноценно можно было попробовать
  };

  ////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////// QUERY FUNCTION /////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  const searchQuery = async function (req, res, URL) {
    const response = await fetch(URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    const json = await response.json();

    if (response.ok) {
      // console.log(res.);
      console.log("Ryba list", json);
      setRybaList(json);
      setLoaded(true);
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////// MEGA QUERY TO RULE THEM ALL //////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const megaRequest = () => {
    setLoaded(false);
    setRybaList(undefined);
    const query = `${process.env.REACT_APP_BACKEND_URL}/shared/filters/byText/?shared=true${titleQuery.current}${modalityQuery.current}${protocolQuery.current}${sortRef.current}${pageLimitRef.current}`;
    console.log(query);
    searchQuery(undefined, undefined, query);
    console.log(query);
  };

  //////////////////////////////// NEW IMPORT RYBA ////////////////////////////////////////////////

  const importRyba = async (req, res) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/pond/import/${rybaID.current}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const json = await response.json();

    if (response.ok) {
      console.log(json);
    }
    notification("Рыбка успешно импортирована!");
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////

  // make array of N length
  const makeArray = function (numberOfRybas, limit) {
    const x = Math.ceil(numberOfRybas / limit);
    let arr = [];
    let count = 0;
    for (let i = 0; i < x; i++) {
      arr.push((count += 1));
    }
    console.log(arr);
    return arr;
  };
  let p = -1;
  let pageCount = 0;

  return (
    <>
      {notificationWindowOpen && (
        <Notification notificationText={notificationText.current} />
      )}
      {previewOpen && ryba && (
        <>
          <div className="popupContentShared" key={(p += 1)}>
            <div
              className="centerDiv"
              style={{ width: "100%", textAlign: "center" }}
            >
              <button onClick={importRyba}>Сохранить шаблон себе</button>
              <button
                onClick={() => {
                  setShowButtons((s) => (s = !s));
                }}
              >
                {showButtons ? "Скрыть кнопки" : "Показать кнопки"}
              </button>
              <button
                onClick={() => {
                  setRyba(null);
                  setPreviewOpen(false);
                }}
              >
                Закрыть окно
              </button>
            </div>

            <div
              className="centerDiv"
              style={{
                width: "100%",
                textAlign: "center",
                marginBottom: "17px",
                fontSize: "13px",
              }}
            >
              <h2>{ryba.title}</h2>
              {/* <p key={(p += 1)}>
                Сегментов:{" "}
                <span style={{ fontWeight: "600" }}>
                  {ryba.segments.length}
                </span>
              </p>
              <p key={(p += 1)}>
                Кнопок:{" "}
                <span style={{ fontWeight: "600" }}>{ryba.buttons.length}</span>
              </p> */}
              <p key={(p += 1)}>
                Последнее обновление:{" "}
                <span style={{ fontWeight: "600" }}>
                  {ryba.updatedAt.slice(0, 10).split("-").reverse().join(".")}
                </span>
              </p>
            </div>

            {ryba &&
              ryba.organs.map((organ, organIndex) => (
                <div className="segmentContainerShared" key={(p += 1)}>
                  <p
                    style={{
                      marginTop:
                        organIndex === ryba.organs.length - 1 && "20px",
                    }}
                    key={(p += 1)}
                  >
                    <span className="segmentTitleSpan">{organ.title}: </span>
                    {ryba.organs[organIndex].segments.map((s, segmentIndex) => (
                      <span>{s.norm}</span>
                    ))}
                  </p>

                  <div className="buttonsContainer">
                    {showButtons &&
                      ryba.organs[organIndex].segments.map(
                        (segment, segmentIndex) => (
                          <>
                            <fieldset>
                              <legend>{segment.title}</legend>
                              {segment.buttons.map((b) => (
                                <>
                                  <div className="buttonContainer">
                                    <p key={(p += 1)}>
                                      {b.title}
                                      {b.type === "constructor" &&
                                        " (конструктор)"}
                                    </p>
                                  </div>

                                  <span>{b.content}</span>
                                  {b.type === "radio" && (
                                    <>
                                      {b.radios.map(
                                        (r) =>
                                          r.title !== "-" && (
                                            <>
                                              <span>
                                                {r.title}: {r.content}
                                              </span>
                                            </>
                                          )
                                      )}
                                    </>
                                  )}
                                  {b.type === "select" && (
                                    <>
                                      {b.options.map(
                                        (r) =>
                                          r.title !== "-" && (
                                            <>
                                              <span>
                                                {r.title}: {r.content}
                                              </span>
                                            </>
                                          )
                                      )}
                                    </>
                                  )}
                                </>
                              ))}
                            </fieldset>
                          </>
                        )
                      )}
                  </div>
                </div>
              ))}
          </div>
          <div className="popupContainerShared" key={(p += 1)}></div>
        </>
      )}
      <div className="sharedRybasContainer">
        <h1>Публичные шаблоны</h1>
        <p>Рыбки, которыми поделились добрые коллеги</p>
        <div className="searchBar">
          <input
            type="text"
            className="searchText"
            placeholder="Поиск по названию"
            onChange={(e) => {
              titleQuery.current = `&title=${e.target.value}`;
              if (e.target.value === "") {
                titleQuery.current = "";
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                megaRequest();
              }
            }}
          />
          <button onClick={megaRequest}>Искать</button>
        </div>
        <div className="modalitiesDiv">
          <form
            className="modalityForm"
            onChange={(e) => {
              pageLimitRef.current = "&limit=20";
              protocolQuery.current = "";
              modalityQuery.current = `&modality=${e.target.value}`;
              if (e.target.value === "Все") {
                modalityQuery.current = "";
                setModalityState(null);
              }
              setModalityState(e.target.value);
              megaRequest();
            }}
          >
            <label>
              <input type="radio" name="modality" value={"Все"}></input>Все
            </label>
            <label>
              <input type="radio" name="modality" value={"КТ"}></input>КТ
            </label>
            <label>
              <input type="radio" name="modality" value={"МРТ"}></input>МРТ
            </label>
            <label>
              <input type="radio" name="modality" value={"Классика"}></input>
              Классика
            </label>
            <label>
              <input type="radio" name="modality" value={"УЗИ"}></input>УЗИ
            </label>
            <label>
              <input type="radio" name="modality" value={"Маммография"}></input>
              Маммография
            </label>
            <label>
              <input type="radio" name="modality" value={"ПЭТ"}></input>ПЭТ
            </label>
          </form>
        </div>
        {modalityState === "КТ" && (
          <select
            onChange={(e) => {
              protocolQuery.current = `&protocol=${e.target.value}`;
              if (e.target.value === "") {
                protocolQuery.current = "";
              }
              megaRequest();
            }}
            name="selectProtocol"
            id="2"
          >
            <option value="">Фильтр по протоколу</option>
            {protocolsCT.map((p) => (
              <option value={p}>{p}</option>
            ))}
          </select>
        )}
        {modalityState === "МРТ" && (
          <select
            onChange={(e) => {
              protocolQuery.current = `&protocol=${e.target.value}`;
              if (e.target.value === "") {
                protocolQuery.current = "";
              }
              megaRequest();
            }}
            name="selectProtocol"
            id="3"
          >
            <option value="">Фильтр по протоколу</option>
            {protocolsMRI.map((p) => (
              <option value={p}>{p}</option>
            ))}
          </select>
        )}
        {modalityState === "Классика" && (
          <select
            onChange={(e) => {
              protocolQuery.current = `&protocol=${e.target.value}`;
              if (e.target.value === "") {
                protocolQuery.current = "";
              }
              megaRequest();
            }}
            name="selectProtocol"
            id="4"
          >
            <option value="">Фильтр по протоколу</option>
            {protocolsXRAY.map((p) => (
              <option value={p}>{p}</option>
            ))}
          </select>
        )}
        {modalityState === "УЗИ" && (
          <select
            onChange={(e) => {
              protocolQuery.current = `&protocol=${e.target.value}`;
              if (e.target.value === "") {
                protocolQuery.current = "";
              }
              megaRequest();
            }}
            name="selectProtocol"
            id="5"
          >
            <option value="">Фильтр по протоколу</option>
            {protocolsUS.map((p) => (
              <option value={p}>{p}</option>
            ))}
          </select>
        )}
        {modalityState === "Маммография" && (
          <select
            onChange={(e) => {
              protocolQuery.current = `&protocol=${e.target.value}`;
              if (e.target.value === "") {
                protocolQuery.current = "";
              }
              megaRequest();
            }}
            name="selectProtocol"
            id="6"
          >
            <option value="">Фильтр по протоколу</option>
            {protocolsMAMMOGRAPHY.map((p) => (
              <option value={p}>{p}</option>
            ))}
          </select>
        )}
        {modalityState === "ПЭТ" && (
          <select
            onChange={(e) => {
              protocolQuery.current = `&protocol=${e.target.value}`;
              if (e.target.value === "") {
                protocolQuery.current = "";
              }
              megaRequest();
            }}
            name="selectProtocol"
            id="2"
          >
            <option value="">Фильтр по протоколу</option>
            {protocolsPET.map((p) => (
              <option value={p}>{p}</option>
            ))}
          </select>
        )}
        <div className="filters">
          <p className="filtersP">Сортировать:</p>
          <select
            className="modalitySelect"
            name="sort"
            onChange={(e) => {
              e.target.value === ""
                ? (sortRef.current = "")
                : (sortRef.current = `&sort=${e.target.value}`);

              megaRequest();
            }}
          >
            <option value="">По названию</option>
            <option value="title">В алфавитном</option>
            <option value="-title">Наоборот</option>
          </select>
          <select
            className="modalitySelect"
            name="sort"
            onChange={(e) => {
              e.target.value === ""
                ? (sortRef.current = "")
                : (sortRef.current = `&sort=${e.target.value}`);

              megaRequest();
            }}
          >
            <option value="">По дате создания</option>
            <option value="-createdAt">Сначала новые</option>
            <option value="createdAt">Сначала старые</option>
          </select>

          <select
            className="modalitySelect"
            onChange={(e) => {
              e.target.value === ""
                ? (sortRef.current = "")
                : (sortRef.current = `&sort=${e.target.value}`);

              megaRequest();
            }}
          >
            <option value="">По популярности</option>
            <option value="-numberTimesShared">Сначала популярные</option>
            <option value="numberTimesShared">Сначала неизвестные</option>
          </select>
          <select
            className="modalitySelect"
            onChange={(e) => {
              e.target.value === ""
                ? (sortRef.current = "")
                : (sortRef.current = `&sort=${e.target.value}`);

              megaRequest();
            }}
          >
            <option value="">По рейтингу</option>
            <option value="-averageRating">Сначала крутые</option>
            <option value="averageRating">Сначала ординаторские</option>
          </select>
          <select
            className="modalitySelect"
            onChange={(e) => {
              e.target.value === ""
                ? (sortRef.current = "")
                : (sortRef.current = `&sort=${e.target.value}`);

              megaRequest();
            }}
          >
            <option value="">По количеству оценок</option>
            <option value="-ratingsLength">Больше оценок</option>
            <option value="ratingsLength">Меньше оценок</option>
          </select>
        </div>

        <div className="sharedRybasList">
          <div
            className="sharedRyba"
            key={(keyCounter += 1)}
            style={{
              marginTop: "20px",
            }}
          >
            <p
              key={(keyCounter += 1)}
              className="sharedRybaP"
              style={{ color: "white", textAlign: "center", fontSize: "18px" }}
            >
              Название шаблона
            </p>

            <div className="sharedInfoContainer">
              <div className="infoProtocol" style={{ fontSize: "17px" }}>
                Протокол
              </div>
              <div className="infoModaliity" style={{ fontSize: "17px" }}>
                Метод
              </div>
              <div className="infoNumberTimesShared">Популярность</div>
              <div className="infoAuthor" style={{ fontSize: "17px" }}>
                Автор
              </div>
              <div className="infoCreatedAt">Дата создания</div>
              <div className="infoRating" style={{}}>
                Рейтинг
              </div>
              <div className="infoRatingAmount" style={{}}>
                Количество оценок
              </div>
            </div>
          </div>
          {!loaded && (
            <div
              className="loaderSpinner"
              style={{
                marginTop: "50px",
                marginBottom: "50px",
                scale: "1.5",
              }}
            ></div>
          )}
          {rybaList &&
            rybaList.map((ryba) => (
              <div className="sharedRyba" key={(keyCounter += 1)}>
                <p
                  ryba={ryba._id}
                  modality={ryba.modality}
                  onClick={previewOpenFunction}
                  key={(keyCounter += 1)}
                  className="sharedRybaP"
                >
                  {ryba.title}
                </p>
                <div className="sharedInfoContainer">
                  <div className="infoProtocol">{ryba.protocol}</div>
                  <div className="infoModaliity">{ryba.modality}</div>
                  <div className="infoNumberTimesShared">
                    {ryba.numberTimesShared}
                  </div>
                  <div className="infoAuthor">{ryba.authorNickname}</div>
                  <div className="infoCreatedAt">
                    {ryba.createdAt.slice(0, 10)}
                  </div>
                  <div className="infoRating" style={{}}>
                    {ryba.averageRating &&
                      String(ryba.averageRating).slice(0, 3)}
                  </div>
                  <div className="infoRatingAmount" style={{}}>
                    {ryba.ratingsLength}
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="pageSelectDiv">
          <button
            className="pageSelectButton"
            onClick={(e) => {
              pageLimit.current = pageLimit.current * 1 + 20;
              pageLimitRef.current = `&limit=${pageLimit.current}`;
              console.log(pageLimit.current);
              megaRequest();
            }}
          >
            Загрузить ещё результатов
          </button>
        </div>
      </div>
    </>
  );
}
