const Duplicate = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path d="M3 10a3 3 0 0 1 3-3h8a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-8ZM10 14v-3m0 3v3m0-3h3m-3 0H7" />
        <path d="M7 3h11a3 3 0 0 1 3 3v11" />
      </g>
    </svg>
  );
};
export default Duplicate;
