const ZoomOut = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={24}
      height={24}
      viewBox="0 0 512 512"
    >
      <path
        d="m484.272 453.297-152.001-152c25.045-30.438 40.102-69.387 40.102-111.789 0-97.162-79.046-176.208-176.208-176.208S19.957 92.346 19.957 189.508s79.047 176.206 176.208 176.206c35.586 0 68.734-10.614 96.467-28.828L446.656 490.91a26.517 26.517 0 0 0 18.808 7.79 26.517 26.517 0 0 0 18.808-7.79c10.385-10.385 10.385-27.226 0-37.613z"
        style={{
          fill: "#4A5FA0",
        }}
      />
      <path
        d="M73.152 189.506c0-67.83 55.183-123.013 123.013-123.013s123.013 55.183 123.013 123.013-55.183 123.013-123.013 123.013S73.152 257.337 73.152 189.506z"
        style={{
          fill: "#efefef",
        }}
      />
      <path d="M493.676 443.893 349.933 300.149c23.122-32.11 35.74-70.953 35.74-110.643C385.673 85.012 300.66 0 196.166 0S6.659 85.012 6.659 189.506s85.012 189.506 189.507 189.506c33.349 0 65.795-8.715 94.493-25.293l146.594 146.594c7.536 7.535 17.554 11.686 28.212 11.686 10.658 0 20.677-4.151 28.212-11.686 15.553-15.554 15.553-40.864-.001-56.42zm-18.807 37.614a13.21 13.21 0 0 1-9.404 3.895 13.21 13.21 0 0 1-9.404-3.895L302.036 327.483a13.289 13.289 0 0 0-9.408-3.895 13.26 13.26 0 0 0-7.296 2.184c-26.543 17.431-57.375 26.644-89.168 26.644-89.829 0-162.909-73.08-162.909-162.909s73.08-162.909 162.909-162.909 162.909 73.08 162.909 162.909c0 37.586-13.166 74.285-37.073 103.34a13.302 13.302 0 0 0 .864 17.854l152.001 152c5.187 5.185 5.187 13.621.004 18.806z" />
      <path d="M196.165 53.195c-75.163 0-136.312 61.149-136.312 136.312s61.15 136.312 136.312 136.312c75.163 0 136.312-61.149 136.312-136.312S271.328 53.195 196.165 53.195zm0 246.026c-60.497 0-109.714-49.217-109.714-109.714S135.668 79.792 196.165 79.792s109.714 49.217 109.714 109.714-49.217 109.715-109.714 109.715z" />
      <path d="M260.891 176.208H131.894c-7.345 0-13.299 5.954-13.299 13.299s5.954 13.299 13.299 13.299h128.997c7.344 0 13.299-5.954 13.299-13.299s-5.954-13.299-13.299-13.299z" />
    </svg>
  );
};
export default ZoomOut;
