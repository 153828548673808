import { useEffect, useState, useRef } from "react";
import { rybaTitleAtom } from "../state/atoms/rybaTitle";
import { useRecoilState } from "recoil";
import RybaCreate from "./editing/RybaCreate";
import { useAuthContext } from "../hooks/useAuthContext";

const RybaListXRAY = () => {
  const [rybaList, setRybaList] = useState(null);
  const [openRybaCreateWindow, setOpenRybaCreateWindow] = useState(false);
  const [modalityState, setModalityState] = useState(null);
  const [rybaTitleEdit, setRybaTitleEdit] = useRecoilState(rybaTitleAtom);
  const { user } = useAuthContext();

  // ref variables
  let modality = useRef("");
  let protocol = useRef("");

  useEffect(() => {
    const fetchRybaList = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/pond/`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();

      if (response.ok) {
        setRybaList(json);
      }
    };
    if (user) {
      fetchRybaList();
    }
  }, [user]);

  let keyCounter = 0;
  // handle ryba title input change
  const titleEditHandler = (e) => {
    setRybaTitleEdit(e.target.value);
    console.log(rybaTitleEdit);
  };

  // handle select option change
  const onSelectOptionChangeHandler = (e) => {
    modality.current = `${e.target.value}`;
    setModalityState(e.target.value);
    console.log(modality.current);
  };

  //// create New Ryba

  const createNewRybaRequest = function (e) {
    if (modality.current === "") {
      alert("выберите модальность");
      return;
    }
    if (protocol.current === "") {
      alert("выберите протокол");
      return;
    }

    if (rybaTitleEdit === "") {
      alert("Введите название новой рыбки");
      return;
    }
    const createRyba = async (req, res) => {
      console.log(rybaTitleEdit);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/pond/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({
            title: `${rybaTitleEdit}`,
            modality: `${modality.current}`,
            protocol: `${protocol.current}`,
          }),
        }
      );
      const json = await response.json();
      if (response.ok) {
        console.log(json);
        window.location.reload();
      }
    };
    createRyba();
  };

  // open create ryba window
  const openRybaCreateWindowOnclick = () => {
    setOpenRybaCreateWindow(true);
  };
  // close create rybe window
  const closeRybaCreateWindowOnclick = () => {
    setOpenRybaCreateWindow(false);
  };

  return (
    <div className="rybaListContainer" key={(keyCounter += 1)}>
      <div className="rybaListSubcontainer1">
        <div className="rybaList" key={(keyCounter += 1)}>
          <p>КТ</p>

          {rybaList &&
            rybaList
              .filter((a) => a.modality === "КТ")
              .map((ryba) => (
                <a
                  href={`${process.env.REACT_APP_BACKEND_URL}/${ryba._id}`}
                  key={(keyCounter += 1)}
                >
                  <li ryba={ryba._id} key={(keyCounter += 1)}>
                    {ryba.title}
                  </li>
                </a>
              ))}
        </div>
        <div className="rybaList" key={(keyCounter += 1)}>
          <p>МРТ</p>

          {rybaList &&
            rybaList
              .filter((a) => a.modality === "МРТ")
              .map((ryba) => (
                <a
                  href={`${process.env.REACT_APP_BACKEND_URL}/${ryba._id}`}
                  key={(keyCounter += 1)}
                >
                  <li ryba={ryba._id} key={(keyCounter += 1)}>
                    {ryba.title}
                  </li>
                </a>
              ))}
        </div>
        <div className="rybaList" key={(keyCounter += 1)}>
          <p>КЛАССИКА</p>

          {rybaList &&
            rybaList
              .filter((a) => a.modality === "Классика")
              .map((ryba) => (
                <a
                  href={`${process.env.REACT_APP_BACKEND_URL}/${ryba._id}`}
                  key={(keyCounter += 1)}
                >
                  <li ryba={ryba._id} key={(keyCounter += 1)}>
                    {ryba.title}
                  </li>
                </a>
              ))}
        </div>
      </div>
      <div className="rybaListSubcontainer1">
        <div className="rybaList" key={(keyCounter += 1)}>
          <p>УЗИ</p>

          {rybaList &&
            rybaList
              .filter((a) => a.modality === "УЗИ")
              .map((ryba) => (
                <a
                  href={`${process.env.REACT_APP_BACKEND_URL}/${ryba._id}`}
                  key={(keyCounter += 1)}
                >
                  <li ryba={ryba._id} key={(keyCounter += 1)}>
                    {ryba.title}
                  </li>
                </a>
              ))}
        </div>
        <div className="rybaList" key={(keyCounter += 1)}>
          <p>МАММОГРАФИЯ</p>

          {rybaList &&
            rybaList
              .filter((a) => a.modality === "Маммография")
              .map((ryba) => (
                <a
                  href={`${process.env.REACT_APP_BACKEND_URL}/${ryba._id}`}
                  key={(keyCounter += 1)}
                >
                  <li ryba={ryba._id} key={(keyCounter += 1)}>
                    {ryba.title}
                  </li>
                </a>
              ))}
        </div>
        <div className="rybaList" key={(keyCounter += 1)}>
          <p>ПЭТ</p>

          {rybaList &&
            rybaList
              .filter((a) => a.modality === "ПЭТ")
              .map((ryba) => (
                <a
                  href={`${process.env.REACT_APP_BACKEND_URL}/${ryba._id}`}
                  key={(keyCounter += 1)}
                >
                  <li ryba={ryba._id} key={(keyCounter += 1)}>
                    {ryba.title}
                  </li>
                </a>
              ))}
        </div>
      </div>
      <button className="createRyba" onClick={openRybaCreateWindowOnclick}>
        Создать новую рыбку
      </button>

      {openRybaCreateWindow && (
        <RybaCreate
          {...{
            openRybaCreateWindowOnclick,
            closeRybaCreateWindowOnclick,
            createNewRybaRequest,
            titleEditHandler,
            rybaTitleEdit,
            onSelectOptionChangeHandler,
            modalityState,
            protocol,
          }}
        />
      )}
    </div>
  );
};

export default RybaListXRAY;
