import { useState, useRef } from "react";
const RadioLymphZaklCodes = ({
  activeGroup,
  setActiveGroup,
  opisatel,
  setString,
  zakluchatel,
  setZakl,
  IASLC,
  codes,
}) => {
  const [checked, setChecked] = useState([]);

  return (
    <>
      <label className={`radioLabel ${checked === "1" && "radioLabelChecked"}`}>
        <input
          type="checkbox"
          onClick={(e) => {
            if (e.target.checked) {
              codes.current = true;
              let a = [...activeGroup];
              let s = "";
              let z = "";
              s = opisatel(a);
              z = zakluchatel(a);
              setChecked("1");
              setZakl(z);
              setActiveGroup(a);
              setString(s);
            }
            if (!e.target.checked) {
              codes.current = false;
              let a = [...activeGroup];
              let s = "";
              let z = "";
              s = opisatel(a);
              z = zakluchatel(a);
              setChecked("0");
              setZakl(z);
              setActiveGroup(a);
              setString(s);
            }
          }}
        />
        Использовать коды IASLC
      </label>
    </>
  );
};
export default RadioLymphZaklCodes;
