import { useRecoilState } from "recoil";
import { useState, useEffect } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { settingsAtom } from "../../state/atoms/settings";
import { rybaIDatom } from "../../state/atoms/rybaID";
import { fullRYBAatom } from "../../state/atoms/fullRYBAatom";
import { textState } from "../../state/atoms/textareaText";
import { useDebounce, useDebouncedCallback } from "use-debounce";

const Textarea = ({ organFocus, setOrganFocus, organIndex, segmentIndex }) => {
  const [text, setText] = useRecoilState(textState);
  const [localTextState, setLocalTextState] = useState(
    text[organIndex][segmentIndex]
  );
  const [id] = useRecoilState(rybaIDatom);
  const [rybaObject] = useRecoilState(fullRYBAatom);
  const [settings] = useRecoilState(settingsAtom);
  // count.current[organIndex] += 1;

  useEffect(() => {
    console.log(`rerender textarea ${organIndex + 1}`);
  }, []);
  const rL = function (rO) {
    return rO.organs.length - 1;
  };

  const updateGeneralState = useDebouncedCallback((text) => {
    let x = [...text];
    x[organIndex][segmentIndex] = localTextState;
    setText(x);
    console.log("я обновил общий стейт");
  }, 300);

  useEffect(() => {
    setLocalTextState(text[organIndex][segmentIndex]);
  }, [text]);

  return (
    <div className="segmentText">
      <TextareaAutosize
        autoComplete={false}
        autoCorrect={false}
        autoCapitalize={false}
        spellCheck={false}
        className={`segmentTextTextarea ${
          organIndex === 0 && segmentIndex === 0 && "topRight"
        } ${organIndex === 1 && "bottomRight"} ${
          organIndex === 2 && segmentIndex === 0 && "topRight"
        } ${
          organIndex === rL(rybaObject) - 1 &&
          segmentIndex ===
            rybaObject.organs[rL(rybaObject) - 1].segments.length - 1 &&
          "bottomRight"
        } ${
          organIndex === rL(rybaObject) &&
          segmentIndex === 0 &&
          "topRight bottomRight"
        }`}
        value={localTextState}
        onChange={(e) => {
          setLocalTextState(e.target.value);
          updateGeneralState(text);
        }}
        onFocus={(e) => {
          setOrganFocus(organIndex);
        }}
        placeholder="-"
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            setOrganFocus(null);
          }
        }}
        style={{
          backgroundColor:
            localTextState !==
              rybaObject.organs[organIndex].segments[segmentIndex].norm &&
            settings.changedTextColor,
          // opacity: organIndex === organFocus && "0.90",
        }}
      ></TextareaAutosize>
    </div>
  );
};

export default Textarea;
