const CopyImpression = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={48}
      height={48}
      viewBox="145 145 790 790"
    >
      <path
        d="M139.09-124.22H52.62v-46.31L-17.85-241h-191.71v365.22h156.94V241h262.18V-53.75z"
        style={{
          stroke: "none",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeDashoffset: 0,
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          fill: "#d1dceb",
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform="matrix(1.59 0 0 1.59 539.24 539.13)"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M-35.23 35.23v-70.46l70.46 70.46z"
        style={{
          stroke: "none",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeDashoffset: 0,
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          fill: "#4ec9dc",
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform="matrix(1.59 0 0 1.59 567.57 211.96)"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M-35.23 35.23v-70.46l70.46 70.46z"
        style={{
          stroke: "none",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeDashoffset: 0,
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          fill: "#4ec9dc",
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform="matrix(1.59 0 0 1.59 817.67 398.06)"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="m467.628 190.176-70.469-70.468a10.003 10.003 0 0 0-7.071-2.929h-76.473V80.468c0-2.652-1.054-5.196-2.929-7.071L240.219 2.929A10.007 10.007 0 0 0 233.146 0H41.444c-5.522 0-10 4.477-10 10v365.221c0 5.523 4.478 10 10 10h146.941V492c0 5.523 4.478 10 10 10h262.172c5.522 0 10-4.477 10-10V197.247c0-2.652-1.054-5.196-2.929-7.071zm-31.214-2.929h-36.326v-36.326l18.163 18.163 18.163 18.163zM279.473 70.468h-36.326V34.142l18.163 18.163 18.163 18.163zM87.286 116.779c-5.522 0-10 4.477-10 10s4.478 10 10 10h101.099v29.919H87.286c-5.522 0-10 4.477-10 10s4.478 10 10 10h101.099v30.01H87.286c-5.522 0-10 4.477-10 10s4.478 10 10 10h101.099v30.009H87.286c-5.522 0-10 4.477-10 10s4.478 10 10 10h101.099v78.504H51.444V20h171.703v60.468c0 5.523 4.478 10 10 10h60.469v26.311H87.286zm292.803 20v60.468c0 5.523 4.478 10 10 10h60.469V482H208.385V136.779h171.704z"
        style={{
          stroke: "none",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeDashoffset: 0,
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          fill: "#000",
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform="matrix(1.59 0 0 1.59 140.11 140)"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M244.229 253.468H369.63c5.522 0 10-4.477 10-10s-4.478-10-10-10H244.229c-5.522 0-10 4.477-10 10s4.477 10 10 10z"
        style={{
          stroke: "none",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeDashoffset: 0,
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          fill: "#000",
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform="matrix(1.59 0 0 1.59 140.841 140.843)"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M414.714 283.478H244.229c-5.522 0-10 4.477-10 10s4.478 10 10 10h170.486c5.522 0 10-4.477 10-10s-4.479-10-10.001-10z"
        style={{
          stroke: "none",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeDashoffset: 0,
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          fill: "#000",
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform="matrix(1.59 0 0 1.59 140.853 141.027)"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M414.714 333.487H244.229c-5.522 0-10 4.477-10 10s4.478 10 10 10h170.486c5.522 0 10-4.477 10-10s-4.479-10-10.001-10z"
        style={{
          stroke: "none",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeDashoffset: 0,
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          fill: "#000",
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform="matrix(1.59 0 0 1.59 140.853 141.2)"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M414.714 383.497H244.229c-5.522 0-10 4.477-10 10s4.478 10 10 10h170.486c5.522 0 10-4.477 10-10s-4.479-10-10.001-10z"
        style={{
          stroke: "none",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeDashoffset: 0,
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          fill: "#e3164d",
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform="matrix(1.66 0 0 1.66 124.45 114.54)"
        vectorEffect="non-scaling-stroke"
      />
      <path
        d="M414.714 233.468h-16.67c-5.522 0-10 4.477-10 10s4.478 10 10 10h16.67c5.522 0 10-4.477 10-10s-4.478-10-10-10z"
        style={{
          stroke: "none",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeDashoffset: 0,
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          fill: "#000",
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform="matrix(1.59 0 0 1.59 141.406 140.843)"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};
export default CopyImpression;
