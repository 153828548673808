import { Link } from "react-router-dom";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";
import FishLogo from "./svg/FishLogo";

const Navbar = () => {
  const { logout } = useLogout();
  const { user } = useAuthContext();

  const logoutClick = () => {
    logout();
  };

  return (
    <header>
      <div className="container">
        <div className="rybovProdaete" style={{ display: "flex" }}>
          <div className="logoIMG">
            <FishLogo />
          </div>
          <div className="logoTXT">
            <Link to="/">
              <h1>
                <>
                  {/* <span className="spanTurbo">TurboRyba</span> */}
                  <span className="spanTurbo">Turboryba</span>
                  {/* <span className="spanRyba">ryba</span> */}
                </>
                <span
                  style={{
                    display: "inline-block",
                    width: "100%",
                    fontSize: "14px",
                    fontWeight: "100",
                    position: "relative",
                    top: "-7px",
                    left: "1px",
                  }}
                >
                  Сегодня домой пораньше
                </span>
              </h1>
            </Link>
          </div>
        </div>
        <div className="navbarLinksContainer">
          <div className="navbarTop">
            <Link to="/">
              <p className="navbarLink">Мои шаблоны</p>
            </Link>

            <Link to="/sharedRybas">
              <p className="navbarLink">Импорт шаблонов</p>
            </Link>
            <Link to="/calculators">
              <p className="navbarLink">Калькуляторы</p>
            </Link>
            <Link to="/profilePage">
              <p className="navbarLink">Настройки</p>
            </Link>
            <Link to="/howtouse">
              <p className="navbarLink">Как пользоваться</p>
            </Link>
          </div>
          <div className="navbarBottom">
            {/* {" "}
            <Link to="/rybaListCT2">
              <p className="navbarLink">КТ</p>
            </Link>
            <Link to="/rybaListMRI2">
              <p className="navbarLink">МРТ</p>
            </Link> */}
          </div>
        </div>
        {!user && (
          <div className="logout">
            <p style={{ color: "white", fontSize: "14px" }}>
              Нет аккаунта? Регистрируемся, чтобы заканчивать пораньше
            </p>
            <Link to="/signup">
              <button>Зарегистрироваться</button>
            </Link>
          </div>
        )}
        {user && (
          <div className="logout">
            <p style={{ color: "white", fontSize: "15px" }}>
              Добро пожаловать, {user.email}
            </p>
            <button className="logout" onClick={logoutClick}>
              Выйти
            </button>
          </div>
        )}
        {/* <Link to="/lala">
          <div className="skull">💀</div>
          <span className="profileText">Личный кабинет</span>
        </Link> */}
      </div>
    </header>
  );
};

export default Navbar;
