import "./buttons.css";
import { useRef } from "react";
import CheckboxsVG from "../svg/CheckboxSVG";
import RadioSVG from "../svg/RadioSVG";

import ConstructorSVG from "../svg/ConstructorSVG";
import Dropdown from "../svg/Dropdown";

const ButtonsFollowup = ({
  button,
  buttonIndex,
  zaklRef,
  setConstructorOpen,
  constructorREF,
  organIndex,
  segmentIndex,
  buttonClick,
  buttonINDEX,
  organINDEX,
  segmentINDEX,
  reset,
  setReset,
  notification,
  copied,
  setCopied,
  text,
  setText,
  id,
}) => {
  if (button.type === "checkbox")
    return (
      <>
        <button
          className="simpleButton3"
          value={button.content}
          onClick={(e) => {
            let t = text;
            if (!t.includes(button.zakl) && button.zakl) {
              t += button.zakl;
            }
            console.log(button.zakl);
            navigator.clipboard.writeText(button.content);
            notification("Текст кнопки скопирован");
            setCopied(button.content);
            buttonClick(button);
            setText(t);
          }}
        >
          <span
            style={{
              marginTop: "9px",
            }}
          >
            <CheckboxsVG
              width={12}
              height={12}
              color={
                // reset[organIndex][segmentIndex][buttonIndex] ? "yellow" :
                "grey"
              }
            />{" "}
          </span>
          {button.title}
        </button>
      </>
    );
  if (button.type === "radio")
    return (
      <>
        <fieldset className="buttonBox">
          <legend>
            {/* {" "}
            <RadioSVG
              style={{ verticalAlign: "center" }}
              height={14}
              width={14}
              color={
                // reset[organIndex][segmentIndex][buttonIndex] ? "yellow" :
                "grey"
              }
            />{" "} */}
            {button.title}
          </legend>
          {button.radios.map((r, index) => (
            <button
              value={r.content}
              onClick={(e) => {
                let t = `${text}`;
                if (!t.includes(r.zakl) && r.zakl) {
                  t += r.zakl;
                }
                notification("Текст кнопки скопирован");
                navigator.clipboard.writeText(r.content);
                setCopied(r.content);
                buttonClick(r);
                setText(t);
                window.localStorage.setItem(
                  `${"followup" + id.slice(1, -1)}`,
                  JSON.stringify(t)
                );
              }}
            >
              {r.title}
            </button>
          ))}
        </fieldset>
      </>
    );

  if (button.type === "constructor")
    return (
      <>
        <ConstructorSVG
          width={16}
          height={16}
          color={
            // reset[organIndex][segmentIndex][buttonIndex] ? "yellow" :
            "grey"
          }
        />{" "}
        <button
          className="simpleButton2"
          value={button.title}
          onClick={(e) => {
            organINDEX.current = organIndex;
            segmentINDEX.current = segmentIndex;
            buttonINDEX.current = buttonIndex;
            constructorREF.current.constructor = button.title;
            constructorREF.current.organIndex = organIndex;
            constructorREF.current.segmentIndex = segmentIndex;
            constructorREF.current.buttonIndex = buttonIndex;
            setConstructorOpen(true);
          }}
        >
          {button.title} 🎛️
        </button>
      </>
    );
  if (button.type === "select")
    return (
      <>
        <label>
          <Dropdown
            width={16}
            height={16}
            color={
              // reset[organIndex][segmentIndex][buttonIndex] ? "yellow" :
              "grey"
            }
          />{" "}
          <select
            onChange={(e) => {
              let t = `${text}`;
              if (button.options[e.target.selectedIndex].zakl) {
                t += button.options[e.target.selectedIndex].zakl;
              }
              console.log(button.options[e.target.selectedIndex].zakl);
              setText(t);
              notification("Текст кнопки скопирован");
              navigator.clipboard.writeText(e.target.value);
              setCopied(e.target.value);
              buttonClick(button.options[e.target.selectedIndex]);
              window.localStorage.setItem(
                `${"followup" + id.slice(1, -1)}`,
                JSON.stringify(t)
              );
            }}
          >
            {button.options.map((s) => (
              <option value={s.content}>{s.title} </option>
            ))}
          </select>
          {button.title}
        </label>
      </>
    );
};

export default ButtonsFollowup;
