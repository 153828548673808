import TextareaAutosize from "react-textarea-autosize";
import { useState } from "react";
import Radio from "./Radio";
import LiverVolum from "../../images/liverVolume.png";

const LiverVolume = ({
  constructorREF,
  setConstructorOpen,
  formState,
  setFormState,
  text,
  setText,
  zaklState,
  setZaklState,
  rybaObject,
  dinamika,
  dinamikaConstructorFunction,
}) => {
  let p = 0;

  const organIndex = constructorREF.current.organIndex;
  const segmentIndex = constructorREF.current.segmentIndex;
  const buttonIndex = constructorREF.current.buttonIndex;

  //////////////////////
  const [size1, setSize1] = useState(null);
  const [size2, setSize2] = useState(null);
  const [size3, setSize3] = useState(null);
  const [weight, setWeight] = useState(null);
  const [height, setHeight] = useState(null);
  const [notallsizes, setNotallsizes] = useState(false);

  //////////////

  const textHandler = function () {
    ///////
    let zaklStringFinal = "";
    let xx = `${size1}`;
    let zz = `${size2}`;
    let yy = `${size3}`;
    if (!size1 || !size2 || !size3) {
      return setNotallsizes(true);
    }

    if (xx && zz && yy) {
      setNotallsizes(false);
    }
    let volume = xx * zz * yy * 0.31;
    volume = Math.round(volume);
    let BSA;
    let expectedVolume;
    if (weight && height) {
      const w = weight;
      const h = height;
      BSA = Math.sqrt((w * h) / 3600);
      expectedVolume = Math.round(1267.28 * BSA - 794.41);
    }

    let finalMeasure = "";
    const assessVolume = function (volume, expectedVolume) {
      if (volume > 1700) {
        return `- увеличение относительно абсолютного референсного значения 1700см3 (является усреднённым, требуется оценка в рамках конкретного клинического контекста)${
          expectedVolume
            ? `${
                expectedVolume < volume
                  ? `; объём печени выше значения ожидаемого объёма, рассчитанного на основе роста и веса (${expectedVolume} см3), что является более специфичной методикой выявления гепатомегалии`
                  : `; объём печени не превышает значения ожидаемого объёма, рассчитанного на основе роста и веса (${expectedVolume} см3)`
              }`
            : ""
        }`;
      }
      if (volume <= 1700) {
        return `- не увеличен относительно абсолютного референсного значения 1700см3 (является усреднённым, требуется оценка в рамках конкретного клинического контекста)${
          expectedVolume
            ? `${
                expectedVolume < volume
                  ? `; объём печени выше значения ожидаемого объёма, рассчитанного на основе роста и веса (${expectedVolume} см3), что является более специфичной методикой выявления гепатомегалии`
                  : `; объём печени не превышает значения ожидаемого объёма, рассчитанного на основе роста и веса (${expectedVolume} см3)`
              }`
            : ""
        }`;
      }
    };
    const accessVolumeZakl = function (volume) {
      if (volume > 1700 || expectedVolume < volume) {
        return `Гепатомегалия. `;
      } else {
        return "";
      }
    };

    finalMeasure = `Максимальные размеры печени в трёх плоскостях ${xx}х${yy}x${zz} см, расчётный объём ${volume} мл ${assessVolume(
      volume,
      expectedVolume
    )}.`;

    zaklStringFinal = `${accessVolumeZakl(volume)}`;

    console.log(finalMeasure);

    ///////
    ///////
    if (dinamika) {
      dinamikaConstructorFunction(finalMeasure, zaklStringFinal);
      setConstructorOpen(false);
      return;
    }
    ///////

    let btnObj =
      rybaObject.organs[organIndex].segments[segmentIndex].buttons[buttonIndex];
    let rybaLength = rybaObject.organs.length - 1;

    let t = [...text];
    if (
      t[organIndex][segmentIndex] ===
      rybaObject.organs[organIndex].segments[segmentIndex].norm
    ) {
      t[organIndex][segmentIndex] = "";
    }
    t[organIndex][segmentIndex] += btnObj.newline
      ? "\n" + finalMeasure
      : finalMeasure;

    t[organIndex][segmentIndex] = t[organIndex][segmentIndex].trim();
    if (
      t[rybaLength][0] === rybaObject.organs[rybaLength].segments[0].norm &&
      zaklStringFinal !== ""
    ) {
      t[rybaLength][0] = "";
    }
    if (zaklStringFinal !== "") {
      t[rybaLength][0] += zaklStringFinal;
    }
    setText(t);
    setConstructorOpen(false);
  };
  const enter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      // calculate();
      textHandler();
      setConstructorOpen(false);
    }
  };

  return (
    // <div className="popupContainerConstructor" key={(p += 1)}>
    <div className="constructorWindow" key={(p += 1)}>
      <h2
        key={(p += 1)}
        style={{
          margin: "0px",
        }}
      >
        Расчёт объёма печени
      </h2>

      <img
        src={LiverVolum}
        alt="spleen"
        style={{
          width: "300px",
          borderRadius: "10px",
          margin: "10px",
          boxShadow: "0px 3px 10px grey",
        }}
      />
      <div>
        <div className="inputVertical">
          <fieldset
            style={{
              display: "flex",
              justifyContent: "center",
              borderRadius: "6px",
            }}
          >
            <legend>Расчёт ожидаемого объёма печени (опционален)</legend>

            <label>
              <input
                placeholder="вес в кг"
                type="number"
                name="age"
                onChange={(e) => {
                  setWeight(e.target.value);
                }}
              />
            </label>
            <label>
              <input
                placeholder="рост в см"
                type="number"
                name="age"
                onChange={(e) => {
                  setHeight(e.target.value);
                }}
              />
            </label>
          </fieldset>
        </div>
      </div>

      <div
        className="inputVertical"
        style={{
          display: "flex",
        }}
      >
        <input
          style={{
            marginLeft: "auto",
            marginRight: "auto",
          }}
          autoFocus
          type="number"
          placeholder="Размер 1 СМ"
          value={size1}
          ы
          onChange={(e) => setSize1(e.target.value)}
          onKeyDown={enter}
        />

        <input
          style={{
            marginLeft: "auto",
            marginRight: "auto",
          }}
          type="number"
          placeholder="Размер 2 в СМ"
          value={size2}
          onChange={(e) => setSize2(e.target.value)}
          onKeyDown={enter}
        />

        <input
          style={{
            marginLeft: "auto",
            marginRight: "auto",
          }}
          type="number"
          placeholder="Размер 3 в СМ"
          value={size3}
          onChange={(e) => setSize3(e.target.value)}
          onKeyDown={enter}
        />
      </div>

      <button
        className="calculate"
        onClick={() => {
          // calculate();
          textHandler();
        }}
      >
        Рассчитать
      </button>
      {notallsizes && <p style={{ color: "red" }}> ВВЕДИТЕ ВСЕ РАЗМЕРЫ</p>}

      <button
        className="calculate"
        onClick={() => {
          // calculate();
          setConstructorOpen(false);
        }}
      >
        Закрыть
      </button>
    </div>
    // </div>
  );
};
export default LiverVolume;
