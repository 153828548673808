import TextareaAutosize from "react-textarea-autosize";
import { useState, useRef, useEffect } from "react";
import { useRecoilState } from "recoil";
import Radio from "./Radio";
import Checkbox from "./Checkbox";
import RadioLymph from "./microComponents/RadioLymph";
import RadioLymphPrev from "./microComponents/RadioLymphPrev";
import RadioLymphDinamika from "./microComponents/RadioLymphDinamika";
import RadioLymphZaklCodes from "./microComponents/RadioLymphZaklCodes";
import { lymphNodeAtom } from "../../../state/atoms/lymphNodesState";

const LymphOGK = ({
  constructorREF,
  setConstructorOpen,
  formState,
  setFormState,
  text,
  setText,
  zaklState,
  setZaklState,
  rybaObject,
  dinamika,
  dinamikaConstructorFunction,
}) => {
  let p = 0;

  const [string, setString] = useState("");
  const [zakl, setZakl] = useState("");
  const [reset, setReset] = useState(0);
  let codes = useRef(false);
  let IASLC = codes.current;

  ////// NODULAR STATE //////

  const [activeGroup, setActiveGroup] = useRecoilState(lymphNodeAtom);
  const [groupText, setGroupText] = useState([]);

  const organIndex = constructorREF.current.organIndex;
  const segmentIndex = constructorREF.current.segmentIndex;
  const buttonIndex = constructorREF.current.buttonIndex;

  useEffect(() => {
    const lymphGroupDefaultObject = {
      checked: false,
      title: "",
      code: "",
      amountCurrent: "единичный",
      sizeCurrent: "",
      checkedPrevious: false,
      amountPrevious: "",
      sizePrevious: "",
      IASLC: false,
    };
    let i = [];
    Array.apply(null, Array(30)).map(() => {
      i.push(lymphGroupDefaultObject);
    });
    setActiveGroup(i);
    console.log("active group default", i);
  }, []);

  const handleInput = function () {
    // let opisanie = "";
    // let a = [...activeGroup];
    // a.map((g) => {
    //   let str = "";
    //   if (g.checked) {
    //     str = `${opisanie === "" ? "" : "\n"}- ${g.title} (${g.code}): до ${
    //       g.size || "..."
    //     } мм${
    //       g.checkedPrevious ? `${g.amountPrevious} до ${g.sizePrevious} мм` : ""
    //     }`;
    //     opisanie += str;
    //     return opisanie
    //   }
    // });
    // setString(opisanie);
    // return opisanie
    //////// zakl ///////
    // if (patho.current.join("")) {
    //   let loca = x;
    //   loca = loca.replaceAll("доле", "доли");
    //   zaklString = pz.current + " " + loca.slice(3).trim() + ". ";
    //   setZakl(zaklString);
    // }
    /////////////////////
    // if (pp.join("")) {
    //   patologia = pp
    //     .filter((g) => {
    //       if (g) return g;
    //     })
    //     .join(",");
    // }
  };
  const opisatel = function (a) {
    let str = "";
    let opisanie = "";
    a.map((g) => {
      if (g.checked) {
        str = `${opisanie === "" ? "" : "\n"}- ${g.title}: ${
          g.amountCurrent
        } до ${g.sizeCurrent || "..."} мм${
          g.checkedPrevious
            ? `, ранее ${g.amountPrevious} до ${g.sizePrevious || "..."} мм`
            : ""
        }`;
        opisanie += str;
      }
    });
    opisanie =
      "Лимфаденопатия на уровне органов грудной клетки:" + "\n" + opisanie;

    return opisanie;
  };
  const nameGroup = function (a) {
    let name = a;
    if (a) {
      name = name
        .replaceAll("11R", "междолевые справа")
        .replaceAll("11L", "междолевые слева")
        .replaceAll("1R", "надключичные справа")
        .replaceAll("1L", "надключичные слева")
        .replaceAll("2R", "верхние паратрахеальные справа")
        .replaceAll("2L", "верхние паратрахеальные слева")
        .replaceAll("3A", "преваскулярные")
        .replaceAll("3P", "ретротрахеальные")
        .replaceAll("4R", "нижние паратрахеальные справа")
        .replaceAll("4L", "нижние паратрахеальные слева")
        .replaceAll("5", "субаортальные")
        .replaceAll("6", "парааортальные")
        .replaceAll("7", "субкаринальные")
        .replaceAll("8", "параэзофагеальные")
        .replaceAll("9R", "лёгочной связки справа")
        .replaceAll("9L", "лёгочной связки слева")
        .replaceAll("10R", "корня лёгкого справа")
        .replaceAll("10L", "корня лёгкого слева");
    }
    return name;
  };
  const textHandler = function () {
    let zaklStringFinal = "";
    ///////
    if (dinamika) {
      dinamikaConstructorFunction(string, zakl);
      setConstructorOpen(false);
      return;
    }
    ///////
    ///////

    let btnObj =
      rybaObject.organs[organIndex].segments[segmentIndex].buttons[buttonIndex];
    let rybaLength = rybaObject.organs.length - 1;

    let t = [...text];
    if (
      t[organIndex][segmentIndex] ===
      rybaObject.organs[organIndex].segments[segmentIndex].norm
    ) {
      t[organIndex][segmentIndex] = "";
    }
    t[organIndex][segmentIndex] += btnObj.newline ? "\n" + string : string;

    t[organIndex][segmentIndex] = t[organIndex][segmentIndex].trim() + " ";
    if (t[rybaLength][0] === rybaObject.organs[rybaLength].segments[0].norm) {
      t[rybaLength][0] = "";
    }

    t[rybaLength][0] += zakl;

    setText(t);
    setConstructorOpen(false);
  };

  let nameCount = 0;
  let indexCount = -1;

  const checkClick = (e, index) => {
    console.log(e.target.checked);
    console.log(index);
    if (e.target.checked) {
      let a = [...activeGroup];
      let bobo = { ...a[index] };
      bobo.checked = true;
      bobo.title = nameGroup(e.target.value);
      bobo.code = e.target.value;
      a[index] = bobo;
      console.log(a);
      let z = "";
      z = zakluchatel(a);
      setZakl(z);
      setActiveGroup(a);

      let opisanie = "";

      opisanie = opisatel(a);
      setString(opisanie);
    }
    if (!e.target.checked) {
      let a = [...activeGroup];
      let bobo = { ...a[index] };
      bobo.checked = false;
      bobo.title = "";
      bobo.code = "";
      a[index] = bobo;
      console.log(a);
      let z = "";
      z = zakluchatel(a);
      setZakl(z);
      setActiveGroup(a);
      let opisanie = "";

      opisanie = opisatel(a);

      setString(opisanie);
      //ksjdnclkdjsnc
    }
  };

  const zakluchatel = function (a) {
    let groups = "";
    let zakluchenie = "";
    let count = 0;
    let groupsArray = [];
    let codesArray = [];
    a.map((g) => {
      if (g.checked) {
        groupsArray.push(g.title);
      }
      if (codes.current && g.checked) {
        codesArray.push(g.code);
      }
    });
    if (!codes.current) {
      zakluchenie = `Лимфаденопатия (${
        groupsArray.length < 6
          ? groupsArray.join(", ")
          : "множественных групп, см. описание"
      }). `;
    }
    console.log("codes array: ", codesArray);
    if (codes.current) {
      let popo = `${
        groupsArray.length < 6
          ? groupsArray.join(", ")
          : "множественных групп, см. описание"
      }`;
      zakluchenie = `Лимфаденопатия (${popo}; IASLC: ${codesArray.join(
        ", "
      )}). `;
    }
    return zakluchenie;
  };

  return (
    // <div className="popupContainerConstructor" key={(p += 1)}>
    <div className="constructorWindow lymphadenopathy" key={reset}>
      {/* <h1 key={(p += 1)}>Лимфаденопатия ОГК</h1> */}
      <button
        style={{
          margin: "0px",
        }}
        onClick={() => {
          codes.current = false;
          setString("");
          setZakl("");
          setReset(Math.random());
          const lymphGroupDefaultObject = {
            checked: false,
            title: "",
            code: "",
            amountCurrent: "",
            sizeCurrent: "",
            checkedPrevious: false,
            amountPrevious: "",
            sizePrevious: "",
          };
          let i = [];
          Array.apply(null, Array(30)).map(() => {
            i.push(lymphGroupDefaultObject);
          });
          setActiveGroup(i);
        }}
      >
        Сбросить
      </button>
      <div
        style={{
          display: "flex",
        }}
      >
        <div>
          <form
            onChange={() => {
              handleInput();
            }}
          >
            <fieldset nameCount={(nameCount += 1)}>
              <legend>Справа</legend>
              <Checkbox
                title={"Подмышечные"}
                value={"подмышечные справа"}
                checkClick={checkClick}
                indexCount={(indexCount += 1)}
              />
              <Checkbox
                title={"Ретромаммарные"}
                value={"ретромаммарные справа"}
                checkClick={checkClick}
                indexCount={(indexCount += 1)}
              />
              <hr />
              <Checkbox
                title={"Надключичные 1R*"}
                value={"1R"}
                checkClick={checkClick}
                indexCount={(indexCount += 1)}
              />
              <Checkbox
                title={"Верхние паратрахеальные 2R"}
                value={"2R"}
                checkClick={checkClick}
                indexCount={(indexCount += 1)}
              />
              <Checkbox
                title={"Нижние паратрахеальные 4R"}
                value={"4R"}
                checkClick={checkClick}
                indexCount={(indexCount += 1)}
              />
            </fieldset>
          </form>
        </div>
        <hr />

        <div>
          <fieldset
            nameCount={(nameCount += 1)}
            onChange={() => {
              handleInput();
            }}
          >
            <legend>Слева</legend>
            <Checkbox
              title={"Подмышечные"}
              value={"подмышечные слева"}
              checkClick={checkClick}
              indexCount={(indexCount += 1)}
            />
            <Checkbox
              title={"Ретромаммарные"}
              value={"ретромаммарные слева"}
              checkClick={checkClick}
              indexCount={(indexCount += 1)}
            />
            <hr />
            <Checkbox
              title={"Надключичные 1L"}
              value={"1L"}
              checkClick={checkClick}
              indexCount={(indexCount += 1)}
            />
            <Checkbox
              title={"Верхние паратрахеальные 2L"}
              value={"2L"}
              checkClick={checkClick}
              indexCount={(indexCount += 1)}
            />
            <Checkbox
              title={"Нижние паратрахеальные 4L"}
              value={"4L"}
              checkClick={checkClick}
              indexCount={(indexCount += 1)}
            />
          </fieldset>
        </div>
      </div>
      <div>
        <span
          style={{
            fontSize: "15px",
            color: "grey",
          }}
        >
          * группы лимфоузлов по{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://radiopaedia.org/cases/thoracic-lymph-node-stations-annotated-ct"
          >
            IASLC (анатомия)
          </a>
          , подробнее{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.google.com/search?q=IASLC+%D0%BA%D0%B0%D1%80%D1%82%D0%B0+%D0%BB%D0%B8%D0%BC%D1%84%D0%BE%D1%83%D0%B7%D0%BB%D0%BE%D0%B2&sca_esv=48f637e9bc078c4b&biw=1652&bih=1233&sxsrf=ADLYWIKCZY4JtlNE0U8teo239mconY5hWA%3A1730719115069&ei=i60oZ-fzA8rNwPAP4v_Y4Qs&ved=0ahUKEwin5byQx8KJAxXKJhAIHeI_NrwQ4dUDCA8&uact=5&oq=IASLC+%D0%BA%D0%B0%D1%80%D1%82%D0%B0+%D0%BB%D0%B8%D0%BC%D1%84%D0%BE%D1%83%D0%B7%D0%BB%D0%BE%D0%B2&gs_lp=Egxnd3Mtd2l6LXNlcnAiJUlBU0xDINC60LDRgNGC0LAg0LvQuNC80YTQvtGD0LfQu9C-0LIyBBAjGCcyCBAAGIAEGKIEMggQABiABBiiBDIIEAAYgAQYogQyCBAAGIAEGKIESKAhUKYGWPUgcAV4AZABA5gB8AGgAc0XqgEFMC45Lje4AQPIAQD4AQGYAhCgAucQwgIKEAAYsAMY1gQYR8ICChAjGIAEGCcYigXCAgoQABiABBgUGIcCwgIFEAAYgATCAggQABiiBBiJBZgDAIgGAZAGCJIHBTUuNi41oAfXYA&sclient=gws-wiz-serp"
          >
            здесь
          </a>
          {/* или 
          <a
            target="_blank"
            rel="noreferrer"
            href="https://radiopaedia.org/cases/thoracic-lymph-node-stations-annotated-ct"
          >
            здесь (RU)
          </a> */}
        </span>
      </div>
      <div>
        <form>
          <fieldset
            nameCount={(nameCount += 1)}
            onChange={() => {
              handleInput();
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Checkbox
                title={"Преваскулярные 3A"}
                value={"3A"}
                checkClick={checkClick}
                indexCount={(indexCount += 1)}
              />
              <Checkbox
                title={"Ретротрахеальные 3P"}
                value={"3P"}
                checkClick={checkClick}
                indexCount={(indexCount += 1)}
              />
              <Checkbox
                title={"Субаортальные 5"}
                value={"5"}
                checkClick={checkClick}
                indexCount={(indexCount += 1)}
              />
              <Checkbox
                title={"Парааортальные 6"}
                value={"6"}
                checkClick={checkClick}
                indexCount={(indexCount += 1)}
              />
              <Checkbox
                title={"Субкаринальные 7"}
                value={"7"}
                checkClick={checkClick}
                indexCount={(indexCount += 1)}
              />
              <Checkbox
                title={"Параэзофагеальные 8"}
                value={"8"}
                checkClick={checkClick}
                indexCount={(indexCount += 1)}
              />
            </div>
          </fieldset>
        </form>
      </div>

      <div
        style={{
          display: "flex",
        }}
      >
        <div>
          <form>
            <fieldset
              nameCount={(nameCount += 1)}
              onChange={() => {
                handleInput();
              }}
            >
              <legend>Справа</legend>
              <Checkbox
                title={"Лёгочной связки 9R"}
                value={"9R"}
                checkClick={checkClick}
                indexCount={(indexCount += 1)}
              />
              <Checkbox
                title={"Корня лёгкого 10R"}
                value={"10R"}
                checkClick={checkClick}
                indexCount={(indexCount += 1)}
              />
              <Checkbox
                title={"Междолевые 11R"}
                value={"11R"}
                checkClick={checkClick}
                indexCount={(indexCount += 1)}
              />
            </fieldset>
          </form>
        </div>
        <hr />

        <div>
          <fieldset
            nameCount={(nameCount += 1)}
            onChange={() => {
              handleInput();
            }}
          >
            <legend>Слева</legend>
            <Checkbox
              title={"Лёгочной связки 9L"}
              value={"9L"}
              checkClick={checkClick}
              indexCount={(indexCount += 1)}
            />
            <Checkbox
              title={"Корня лёгкого 10L"}
              value={"10L"}
              checkClick={checkClick}
              indexCount={(indexCount += 1)}
            />
            <Checkbox
              title={"Междолевые 11L"}
              value={"11L"}
              checkClick={checkClick}
              indexCount={(indexCount += 1)}
            />
          </fieldset>
        </div>
      </div>

      <div>
        {activeGroup.map(
          (a, index) =>
            a.checked && (
              <div className="groupLymph">
                <div className="nameGroupLymph">
                  {a.title.toUpperCase()}

                  <RadioLymphDinamika
                    indexCount={index}
                    activeGroup={activeGroup}
                    setActiveGroup={setActiveGroup}
                    string={string}
                    zakluchatel={zakluchatel}
                    setString={setString}
                    opisatel={opisatel}
                    setZakl={setZakl}
                  />
                </div>
                <div>
                  <RadioLymph
                    indexCount={index}
                    activeGroup={activeGroup}
                    string={string}
                    zakluchatel={zakluchatel}
                    setString={setString}
                    setActiveGroup={setActiveGroup}
                    setZakl={setZakl}
                    opisatel={opisatel}
                  />

                  <input
                    type="text"
                    // value={activeGroup[index].sizeCurrent}
                    onChange={(e) => {
                      let a = [...activeGroup];
                      let g = a[index];
                      g.sizeCurrent = e.target.value;
                      a[index] = g;
                      let s = "";
                      s = opisatel(a);
                      let z = "";
                      z = zakluchatel(a);
                      setZakl(z);
                      setActiveGroup(a);
                      setString(s);
                    }}
                    placeholder="сейчас мм"
                  />
                </div>
                <div>
                  {a.checkedPrevious && (
                    <>
                      <RadioLymphPrev
                        indexCount={index}
                        activeGroup={activeGroup}
                        string={string}
                        zakluchatel={zakluchatel}
                        setString={setString}
                        setActiveGroup={setActiveGroup}
                        setZakl={setZakl}
                      />
                      <input
                        type="text"
                        placeholder="ранее мм"
                        onChange={(e) => {
                          let a = [...activeGroup];
                          let g = a[index];
                          g.sizePrevious = e.target.value;
                          a[index] = g;
                          let s = "";
                          s = opisatel(a);
                          let z = "";
                          z = zakluchatel(a);
                          setZakl(z);
                          setActiveGroup(a);
                          setString(s);
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            )
        )}
      </div>
      <div>
        <div>В описание</div>
        <TextareaAutosize
          style={{
            width: "580px",
          }}
          value={string}
          onChange={(e) => {
            setString(e.target.value);
          }}
        ></TextareaAutosize>
        <div>
          В заключение{"   "}
          <RadioLymphZaklCodes
            activeGroup={activeGroup}
            string={string}
            zakluchatel={zakluchatel}
            setString={setString}
            setActiveGroup={setActiveGroup}
            setZakl={setZakl}
            IASLC={IASLC}
            opisatel={opisatel}
            codes={codes}
          />
          <TextareaAutosize
            value={zakl}
            onChange={(e) => {
              setZakl(e.target.value);
            }}
          ></TextareaAutosize>
        </div>
        <button
          onClick={(e) => {
            textHandler();
            setConstructorOpen(false);
          }}
        >
          Готово
        </button>
        <button
          onClick={(e) => {
            setConstructorOpen(false);
          }}
        >
          Закрыть
        </button>
      </div>
    </div>
  );
};
export default LymphOGK;
