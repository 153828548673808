import TextareaAutosize from "react-textarea-autosize";
import { useState } from "react";
import Radio from "./Radio";

const RenalCysts = ({
  constructorREF,
  setConstructorOpen,
  formState,
  setFormState,
  text,
  setText,
  zaklState,
  setZaklState,
  rybaObject,
  dinamika,
  dinamikaConstructorFunction,
}) => {
  let p = 0;
  const [choice, setChoice] = useState([]);
  const [string, setString] = useState("");
  const [zaklString, setZaklString] = useState([]);
  const organIndex = constructorREF.current.organIndex;
  const segmentIndex = constructorREF.current.segmentIndex;
  const buttonIndex = constructorREF.current.buttonIndex;

  const handleInput = function (e, index, preText, postText) {
    if (preText === undefined) {
      preText = "";
    }
    if (postText === undefined) {
      postText = "";
    }
    let x = [...choice];
    if (index === 0) {
      x.splice(index + 1, 100);
    }

    x[index] = preText + e.target.value + postText;
    setChoice(x);
    console.log(x);
    let g = x.join("");
    setString(g);
  };

  const createZakl = function (string) {
    let x = [];
    x[0] = "Кистa ";

    if (string.includes("единичные")) {
      x[0] = "Кисты ";
    }
    if (string.includes("множественные")) {
      x[0] = "Кисты ";
    }
    if (string.includes("правой") && string.includes("левой")) {
      x[1] = "обеих почек ";
    }
    if (string.includes("правой") && !string.includes("левой")) {
      x[1] = "правой почки ";
    }
    if (!string.includes("правой") && string.includes("левой")) {
      x[1] = "левой почки ";
    }
    string.includes("Bosniak II")
      ? (x[2] = "Bosniak II. ")
      : (x[2] = "Bosniak I. ");
    if (x[1] === "обеих почек ") {
      x[0] = "Кисты ";
    }
    return x.join("");
  };

  const textHandler = function () {
    let zaklStringFinal = "";

    zaklStringFinal = createZakl(string);

    ///////
    if (dinamika) {
      dinamikaConstructorFunction(string, zaklStringFinal);
      setConstructorOpen(false);
      return;
    }
    ///////
    ///////

    let btnObj =
      rybaObject.organs[organIndex].segments[segmentIndex].buttons[buttonIndex];
    let rybaLength = rybaObject.organs.length - 1;

    let t = [...text];
    if (
      t[organIndex][segmentIndex] ===
      rybaObject.organs[organIndex].segments[segmentIndex].norm
    ) {
      t[organIndex][segmentIndex] = "";
    }
    t[organIndex][segmentIndex] += btnObj.newline ? "\n" + string : string;

    t[organIndex][segmentIndex] = t[organIndex][segmentIndex].trim();
    if (t[rybaLength][0] === rybaObject.organs[rybaLength].segments[0].norm) {
      t[rybaLength][0] = "";
    }
    if (!t[rybaLength][0].includes("osniak")) {
      t[rybaLength][0] += zaklStringFinal;
    }
    setText(t);
    setConstructorOpen(false);
  };

  let nameCount = 0;

  return (
    // <div className="popupContainerConstructor" key={(p += 1)}>
    <div className="constructorWindow" key={(p += 1)}>
      <h1 key={(p += 1)}>Конструктор почечных кисты вип</h1>
      <fieldset
        nameCount={(nameCount += 1)}
        onChange={(e) => {
          handleInput(e, 0);
        }}
      >
        <legend>Сторона</legend>
        <Radio {...{ title: "Слева", value: "В левой почке ", nameCount }} />
        <Radio {...{ title: "Справа", value: "В правой почке ", nameCount }} />
        <Radio
          {...{
            title: "Билатерально",
            value: "Отмечаются кисты обеих почек: ",
            nameCount,
          }}
        />
      </fieldset>
      {choice[0] && choice[0] === "В левой почке " && (
        <div
          style={{
            display: "inline-block",
          }}
        >
          <fieldset
            nameCount={(nameCount += 1)}
            onChange={(e) => {
              handleInput(e, 4);
            }}
          >
            <legend>Слева</legend>
            <Radio
              {...{
                title: "Одна",
                value: "единичная киста ",
                nameCount,
              }}
            />
            <Radio
              {...{
                title: "Единичные",
                value: "единичные кисты ",
                nameCount,
              }}
            />
            <Radio
              {...{
                title: "Множественные",
                value: "множественные кисты ",
                nameCount,
              }}
            />
          </fieldset>
          <fieldset
            nameCount={(nameCount += 1)}
            onChange={(e) => {
              handleInput(e, 5);
            }}
          >
            <legend>Категория</legend>
            <Radio
              {...{
                title: "Bosniak I",
                value: "со структурой в рамках категории Bosniak I ",
                nameCount,
              }}
            />
            <Radio
              {...{
                title: "Bosniak II",
                value: "со структурой в рамках категории Bosniak II ",
                nameCount,
              }}
            />
          </fieldset>
          <fieldset
            nameCount={(nameCount += 1)}
            onChange={(e) => {
              handleInput(e, 6, "размерами до ", " мм. ");
            }}
          >
            <legend>Размеры</legend>
            <input type="text" />
          </fieldset>
        </div>
      )}
      {choice[0] && choice[0] === "В правой почке " && (
        <div
          style={{
            display: "inline-block",
          }}
        >
          <fieldset
            nameCount={(nameCount += 1)}
            onChange={(e) => {
              handleInput(e, 1);
            }}
          >
            <legend>Справа</legend>
            <Radio
              {...{
                title: "Одна",
                value: "единичная киста ",
                nameCount,
              }}
            />
            <Radio
              {...{
                title: "Единичные",
                value: "единичные кисты ",
                nameCount,
              }}
            />
            <Radio
              {...{
                title: "Множественные",
                value: "множественные кисты ",
                nameCount,
              }}
            />
          </fieldset>
          <fieldset
            nameCount={(nameCount += 1)}
            onChange={(e) => {
              handleInput(e, 2);
            }}
          >
            <legend>Категория</legend>
            <Radio
              {...{
                title: "Bosniak I",
                value: "со структурой в рамках категории Bosniak I ",
                nameCount,
              }}
            />
            <Radio
              {...{
                title: "Bosniak II",
                value: "со структурой в рамках категории Bosniak II ",
                nameCount,
              }}
            />
          </fieldset>
          <fieldset
            nameCount={(nameCount += 1)}
            onChange={(e) => {
              handleInput(e, 3, "размерами до ", " мм. ");
            }}
          >
            <legend>Размеры</legend>
            <input type="text" />
          </fieldset>
        </div>
      )}
      {choice[0] && choice[0] === "Отмечаются кисты обеих почек: " && (
        <div>
          <div
            style={{
              display: "inline-block",
            }}
          >
            <fieldset
              nameCount={(nameCount += 1)}
              onChange={(e) => {
                handleInput(e, 1);
              }}
            >
              <legend>Справа</legend>
              <Radio
                {...{
                  title: "Одна",
                  value: "в правой почке единичная киста ",
                  nameCount,
                }}
              />
              <Radio
                {...{
                  title: "Единичные",
                  value: "в правой почке единичные кисты ",
                  nameCount,
                }}
              />
              <Radio
                {...{
                  title: "Множественные",
                  value: "в правой почке множественные кисты ",
                  nameCount,
                }}
              />
            </fieldset>
            <fieldset
              nameCount={(nameCount += 1)}
              onChange={(e) => {
                handleInput(e, 2);
              }}
            >
              <legend>Категория</legend>
              <Radio
                {...{
                  title: "Bosniak I",
                  value: "со структурой в рамках категории Bosniak I ",
                  nameCount,
                }}
              />
              <Radio
                {...{
                  title: "Bosniak II",
                  value: "со структурой в рамках категории Bosniak II ",
                  nameCount,
                }}
              />
            </fieldset>
            <fieldset
              nameCount={(nameCount += 1)}
              onChange={(e) => {
                handleInput(e, 3, "размерами до ", " мм, ");
              }}
            >
              <legend>Размеры</legend>
              <input type="text" />
            </fieldset>
          </div>
          <div
            style={{
              display: "inline-block",
            }}
          >
            <fieldset
              nameCount={(nameCount += 1)}
              onChange={(e) => {
                handleInput(e, 4);
              }}
            >
              <legend>Слева</legend>
              <Radio
                {...{
                  title: "Одна",
                  value: "в левой почке единичная киста ",
                  nameCount,
                }}
              />
              <Radio
                {...{
                  title: "Единичные",
                  value: "в левой почке единичные кисты ",
                  nameCount,
                }}
              />
              <Radio
                {...{
                  title: "Множественные",
                  value: "в левой почке множественные кисты ",
                  nameCount,
                }}
              />
            </fieldset>
            <fieldset
              nameCount={(nameCount += 1)}
              onChange={(e) => {
                handleInput(e, 5);
              }}
            >
              <legend>Категория</legend>
              <Radio
                {...{
                  title: "Bosniak I",
                  value: "со структурой в рамках категории Bosniak I ",
                  nameCount,
                }}
              />
              <Radio
                {...{
                  title: "Bosniak II",
                  value: "со структурой в рамках категории Bosniak II ",
                  nameCount,
                }}
              />
            </fieldset>
            <fieldset
              nameCount={(nameCount += 1)}
              onChange={(e) => {
                handleInput(e, 6, "размерами до ", " мм.");
              }}
            >
              <legend>Размеры</legend>
              <input type="text" />
            </fieldset>
          </div>
        </div>
      )}

      <TextareaAutosize
        value={string}
        onChange={(e) => {
          setString(e.target.value);
        }}
      ></TextareaAutosize>
      <button
        onClick={(e) => {
          textHandler();
          setConstructorOpen(false);
        }}
      >
        Готово
      </button>
      <button
        onClick={(e) => {
          setConstructorOpen(false);
        }}
      >
        Закрыть
      </button>
    </div>
    // </div>
  );
};
export default RenalCysts;
