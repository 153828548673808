import { Link } from "react-router-dom";

const ModalityRybaList = ({
  array,
  loaded,
  rybaList,
  setRybaContext,
  rybaOBJECT,
  setOpenContext,
  setLeft,
  setTop,
  listTitle,
}) => {
  return (
    <div className="modalityrybaList">
      <p>{listTitle}</p>
      <hr className="rybaListHr" />
      {!loaded && <div className="loaderSpinner"></div>}
      {rybaList &&
        rybaList.filter((a) => array.includes(a.protocol)).length === 0 && (
          <span className="rybovNet">Шаблонов нет</span>
        )}
      {rybaList &&
        rybaList
          .filter((a) => array.includes(a.protocol))
          .map((ryba) => (
            <Link
              to={`/primary/${ryba._id}`}
              onContextMenu={(e) => {
                e.preventDefault();
                setRybaContext(ryba);
                rybaOBJECT.current = ryba;
                setOpenContext(true);
                setLeft(e.pageX);
                setTop(e.pageY);
              }}
            >
              <li ryba={ryba._id}>
                {ryba.title}
                {ryba.isChild && " 🆕"}
              </li>
            </Link>
          ))}
    </div>
  );
};
export default ModalityRybaList;
