import { useState } from "react";
import { useLogin } from "../hooks/useLogin";
import "./Login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordForgot, setPasswordForgot] = useState(false);
  const [message, setMessage] = useState(undefined);

  const { login, error, isLoading } = useLogin();

  const handleSubmit = async (e) => {
    e.preventDefault();

    await login(email, password);
  };

  const forgotPasswordRequest = async function (email) {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/user/forgotPassword`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      }
    );
    const json = await response.json();
    if (response.ok) {
      console.log(json);

      const sdsc =
        "Ура, Вы нашлись среди пользователей!\n" +
        json.message +
        ". \n\n. Если не нашли письмо, не забудьте проверить папку СПАМ. ";
      setMessage(sdsc);
    }
    if (!response.ok) {
      setMessage(
        "Что-то пошло не так:( \nИли пользователя с такой почтой нет, или ввели почту неправильно"
      );
    }
  };

  return (
    <>
      {!passwordForgot && (
        <>
          {" "}
          <form className="login" onSubmit={handleSubmit}>
            {" "}
            <h3>Вход в аккаунт</h3>
            <label>Электронная почта:</label>
            <input
              className="inputLoginSignup"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder=""
            />
            <label>Пароль:</label>
            <input
              className="inputLoginSignup"
              type="password"
              placeholder=""
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <button disabled={isLoading} className="loginButton">
              Войти
            </button>
            <p
              className="forgotPasswordButton"
              onClick={() => {
                setPasswordForgot(true);
              }}
            >
              Я забыл пароль {":("}
            </p>{" "}
            {error && (
              <div
                className="error"
                style={{
                  color: "red",
                }}
              >
                {error}
              </div>
            )}
          </form>
        </>
      )}
      {passwordForgot && (
        <>
          <div className="login">
            <h3>Смена пароля</h3>
            <span>
              Введите свою электронную почту, с которой регистрировались:
            </span>
            <input
              className="inputLoginSignup"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder=""
            />
            <button
              className="loginButton"
              onClick={(e) => {
                e.preventDefault();
                forgotPasswordRequest(email);
              }}
            >
              Отправить
            </button>
            {message && (
              <div
                className="error"
                style={{
                  color: "pink",
                }}
              >
                {message}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Login;
