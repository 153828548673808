import { useState } from "react";
import "./Login.css";
import { useParams, useNavigate } from "react-router-dom";
import { useLogin } from "../hooks/useLogin";

const NewPassword = () => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [message, setMessage] = useState(undefined);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();
  const { login } = useLogin();
  const resetPasswordRequest = async function () {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/user/resetPassword/${token}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password,
        }),
      }
    );
    const json = await response.json();
    if (response.ok) {
      console.log(json);
      await login(json.email, password);
      navigate("/");
    }
    if (!response.ok) {
      setMessage("Код сброса пароля просрочился, отправьте ещё один");
    }
  };

  return (
    <div className="login">
      <h3>Смена пароля</h3>
      <span>Введите новый пароль:</span>
      <input
        className="inputLoginSignup"
        type="text"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        placeholder=""
      />
      <span>Ещё раз то же самое:</span>
      <input
        className="inputLoginSignup"
        type="text"
        onChange={(e) => setPasswordConfirm(e.target.value)}
        value={passwordConfirm}
        placeholder=""
      />
      <button
        className="loginButton"
        onClick={() => {
          if (password !== passwordConfirm) {
            setMessage("Пароль и подтверждение не сходятся");
            return;
          }
          if (password === "") {
            setMessage("Введите хоть какой-то пароль");
            return;
          }
          if (password === passwordConfirm) {
            resetPasswordRequest();
          }
        }}
      >
        Отправить
      </button>
      {message && (
        <div
          className="error"
          style={{
            color: "pink",
          }}
        >
          {message}
        </div>
      )}
    </div>
  );
};

export default NewPassword;
