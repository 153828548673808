const Dropdown = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 32 32"
    >
      <title>{"dropdown-list"}</title>
      <path d="M26 6V2H2v10h9v18h19V6ZM4 10V4h20v2H11v4Zm24 18H13V8h15Z" />
      <path d="M15 11h2v2h-2zM19 11h7v2h-7zM15 17h2v2h-2zM19 17h7v2h-7zM15 23h2v2h-2zM19 23h7v2h-7z" />
      <path
        d="M0 0h32v32H0z"
        data-name="&lt;Transparent Rectangle&gt;"
        style={{
          fill: "none",
        }}
      />
    </svg>
  );
};
export default Dropdown;
