import { useState } from "react";

const ICAcalc = ({ notification }) => {
  const [notallsizes, setNotallsizes] = useState(false);

  const [size1, setSize1] = useState();
  const [size2, setSize2] = useState();
  const [size3, setSize3] = useState();
  const [calculated, setCalculated] = useState(false);
  const [string, setString] = useState(undefined);
  const [ECSTstate, setECST] = useState(undefined);
  const [NASCETstate, setNASCET] = useState(undefined);

  const calculate = () => {
    let zaklStringFinal = "";
    let xx = `${size1}`;
    let zz = `${size2}`;
    let yy = `${size3}`;
    if (!size1 || !size2 || !size3) {
      return setNotallsizes(true);
    }

    if (xx && zz && yy) {
      setNotallsizes(false);
    }
    let ECST = ((yy - xx) / yy) * 100;
    ECST = Math.round(ECST);
    setECST(ECST);
    let NASCET = ((zz - xx) / zz) * 100;
    NASCET = Math.round(NASCET);
    setNASCET(NASCET);

    let finalMeasure = "";

    finalMeasure = `Общий диаметр С1 сегмента ВСА на уровне стеноза составляет ${yy} мм, выше уровня стеноза ${zz} мм, диаметр контрастируемого просвета на уровне стеноза ${xx} мм; расчётный процент стеноза по ECST составляет ${ECST}%, по NASCET ${NASCET}%.`;

    // zaklStringFinal = `Стеноз С1 сегмента ${
    //   side === "R" ? "правой" : "левой"
    // } ВСА (${ECST}% по ESCT, ${NASCET}% по NASCET). `;

    console.log(finalMeasure);

    setString(finalMeasure);
    navigator.clipboard.writeText(finalMeasure);
    notification(
      "Формулировка для заключения автоматически скопирована в буфер обмена",
      3000
    );
    setCalculated((c) => (c = !c));
    setSize1(undefined);
    setSize2(undefined);
    setSize3(undefined);
  };

  const enter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      calculate();
    }
  };

  return (
    <div className="calculator">
      <p className="name">Расчёт стеноза С1 ВСА по ECST и NASCET</p>
      <div className="legend">
        <p>
          {
            // "Если добавить возраст и вес, ещё посчитается ожидаемый объём печени (более точный референс)"
          }
        </p>
      </div>

      <div className="inputVertical" key={calculated}>
        <fieldset
          style={{
            justifyContent: "center",
            borderRadius: "6px",
            border: "none",
            background: "#171717",
            color: "grey",
            margin: "5px",
          }}
          key={calculated}
        >
          {/* <legend>Расчёт ожидаемого объёма печени (опционален)</legend> */}
          Диаметр ПРОСВЕТА на уровне стеноза
          <label>
            <input
              placeholder="А мм"
              type="number"
              name="age"
              onChange={(e) => {
                setSize1(e.target.value);
              }}
            />
          </label>
        </fieldset>
        <fieldset
          style={{
            justifyContent: "center",
            borderRadius: "6px",
            border: "none",
            background: "#171717",
            color: "grey",
            margin: "5px",
          }}
          key={calculated}
        >
          ОБЩИЙ диаметр выше уровня стеноза
          <label>
            <input
              placeholder="В мм"
              type="number"
              name="age"
              onChange={(e) => {
                setSize2(e.target.value);
              }}
            />
          </label>
        </fieldset>
        <fieldset
          style={{
            justifyContent: "center",
            borderRadius: "6px",
            border: "none",
            background: "#171717",
            color: "grey",
            margin: "5px",
          }}
          key={calculated}
        >
          {/* <legend>Расчёт ожидаемого объёма печени (опционален)</legend> */}
          ОБЩИЙ диаметр на уровне стеноза
          <label>
            <input
              placeholder="С мм"
              type="number"
              name="age"
              onChange={(e) => {
                setSize3(e.target.value);
              }}
            />
          </label>
        </fieldset>
      </div>
      <div className="results">NASCET: {NASCETstate} %</div>
      <div className="results">ECST: {ECSTstate} %</div>

      <button
        className="calculate"
        onClick={() => {
          calculate();
        }}
      >
        Рассчитать (Enter)
      </button>

      <div className="legend">
        <p>{string}</p>
        {notallsizes && <p style={{ color: "red" }}> ВВЕДИТЕ ВСЕ РАЗМЕРЫ</p>}
      </div>
    </div>
  );
};

export default ICAcalc;
