import { useRef } from "react";
import "./constructors/constructor.css";
import RenalCysts from "./constructors/RenalCysts";
import Splenomegaly from "./constructors/Splenomegaly";
import ProstateCalculator from "./constructors/ProstateCalculator";
import PleuralEffusion from "./constructors/PleuralEffusion";
import LungInfiltration from "./constructors/LungInfiltration";
import LungOchagi from "./constructors/LungOchagi";
import LungFibrosis from "./constructors/LungFibrosis";
import ICAstenosis from "./constructors/ICAstenosis";
import OvarianVolume from "./constructors/OvarianVolume";
import TesticularVolume from "./constructors/TesticularVolume";
import LiverVolume from "./constructors/LiverVolume";
import AdrenalWashout from "./constructors/AdrenalWashout";
import LymphOGK from "./constructors/LymphOGK";

const ConstructorRouter = ({
  constructorREF,
  setConstructorOpen,
  formState,
  setFormState,
  text,
  setText,
  zaklState,
  setZaklState,
  rybaObject,
  dinamika,
  dinamikaConstructorFunction,
}) => {
  const props = {
    constructorREF,
    setConstructorOpen,
    formState,
    setFormState,
    text,
    setText,
    zaklState,
    setZaklState,
    rybaObject,
    dinamika,
    dinamikaConstructorFunction,
  };

  if (constructorREF.current.constructor === "Кисты почек")
    return (
      <RenalCysts
        {...{
          ...props,
        }}
      />
    );
  if (constructorREF.current.constructor === "Спленомегалия")
    return (
      <Splenomegaly
        {...{
          ...props,
        }}
      />
    );
  if (constructorREF.current.constructor === "Объём простаты")
    return (
      <ProstateCalculator
        {...{
          ...props,
        }}
      />
    );
  if (constructorREF.current.constructor === "Гидроторакс")
    return (
      <PleuralEffusion
        {...{
          ...props,
        }}
      />
    );
  if (constructorREF.current.constructor === "Очаговые изменения лёгких")
    return (
      <LungOchagi
        {...{
          ...props,
        }}
      />
    );
  if (constructorREF.current.constructor === "Инфильтративные изменения лёгких")
    return (
      <LungInfiltration
        {...{
          ...props,
        }}
      />
    );
  if (constructorREF.current.constructor === "Фиброзные изменения лёгких")
    return (
      <LungFibrosis
        {...{
          ...props,
        }}
      />
    );
  if (constructorREF.current.constructor === "Очаговые изменения печени")
    return (
      <LungFibrosis
        {...{
          ...props,
        }}
      />
    );
  if (constructorREF.current.constructor === "Стеноз ВСА по ECST и NASCET")
    return (
      <ICAstenosis
        {...{
          ...props,
        }}
      />
    );
  if (constructorREF.current.constructor === "Объём яичника")
    return (
      <OvarianVolume
        {...{
          ...props,
        }}
      />
    );
  if (constructorREF.current.constructor === "Объём яичка")
    return (
      <TesticularVolume
        {...{
          ...props,
        }}
      />
    );
  if (constructorREF.current.constructor === "Объём печени")
    return (
      <LiverVolume
        {...{
          ...props,
        }}
      />
    );
  if (constructorREF.current.constructor === "Индекс вымывания (надпочечники)")
    return (
      <AdrenalWashout
        {...{
          ...props,
        }}
      />
    );
  if (constructorREF.current.constructor === "Лимфаденопатия ОГК")
    return (
      <LymphOGK
        {...{
          ...props,
        }}
      />
    );
};

export default ConstructorRouter;
