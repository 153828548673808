import { useState } from "react";
import prostate from "../images/Prostate.png";

const AdrenalWASHOUT = ({ notification }) => {
  const [notallsizes, setNotallsizes] = useState(false);
  const [result, setResult] = useState("0");

  const [size1, setSize1] = useState();
  const [size2, setSize2] = useState();
  const [size3, setSize3] = useState();
  const [calculated, setCalculated] = useState(false);
  const [abs, setAbs] = useState(undefined);
  const [rel, setRel] = useState(undefined);
  const [string, setString] = useState(undefined);

  // let size1;
  // let size2;
  // let size3;
  const calculate = () => {
    if (!size1 || !size2 || !size3) {
      return setNotallsizes(true);
    }
    if (size1 && size2 && size3) {
      setNotallsizes(false);
    }
    let nat = size1;
    let ven = size2;
    let del = size3;
    let absoluteWashout = ((ven - del) / (ven - nat)) * 100;
    absoluteWashout = Math.round(absoluteWashout);
    setAbs(absoluteWashout);
    let relativeWashout = ((ven - del) / ven) * 100;
    relativeWashout = Math.round(relativeWashout);
    setRel(relativeWashout);

    let finalMeasure = "";

    finalMeasure = `Плотность объёмного образования нативно ${nat} HU, в венозную фазу контрастирования (60-75 сек) ${ven} HU, в отсроченную фазу контрастирования (15 мин) ${del} HU, относительный индекс вымывания - ${relativeWashout}% ${
      relativeWashout >= 40
        ? "(>40% - с высокой долей вероятности указывает на аденому надпочечника, чувствительность 96%, специфичность 100%)"
        : "(<40% - менее характерно для аденом, образование требует дальнейшего дообследования)"
    }, абсолютный индекс вымывания - ${absoluteWashout}% ${
      absoluteWashout >= 60
        ? "(>60% - с высокой долей вероятности указывает на аденому надпочечника, чувствительность 87%, специфичность 94%)"
        : "(<60% - менее характерно для аденом, образование требует дальнейшего дообследования)"
    }.`;

    setString(finalMeasure);
    navigator.clipboard.writeText(finalMeasure);
    console.log(finalMeasure);

    notification(
      "Формулировка для заключения автоматически скопирована в буфер обмена",
      3000
    );
    setCalculated((c) => (c = !c));
    setSize1(undefined);
    setSize2(undefined);
    setSize3(undefined);
  };

  const enter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      calculate();
    }
  };

  return (
    <div className="calculator">
      <p className="name">
        Расчёт индексов вымывания контрастного вещества образованием
        надпочечников
      </p>
      <div className="legend">
        <p>
          {
            // "Подойдёт для любой сферы или эллипсоида (например, для округлой гематомы). "
          }
        </p>
      </div>
      <div className="inputVertical" key={calculated}>
        <div
          className="inputVertical"
          style={{
            display: "flex",
            marginTop: "20px",
          }}
        >
          Плотность нативно
          <input
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
            autoFocus
            type="number"
            placeholder="HU"
            value={size1}
            ы
            onChange={(e) => setSize1(e.target.value)}
            onKeyDown={enter}
          />
          Плотность в венозную фазу 60-75 сек
          <input
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
            type="number"
            placeholder="HU"
            value={size2}
            onChange={(e) => setSize2(e.target.value)}
            onKeyDown={enter}
          />
          Плотность в отсроченную фазу 15 мин
          <input
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
            type="number"
            placeholder="HU"
            value={size3}
            onChange={(e) => setSize3(e.target.value)}
            onKeyDown={enter}
          />
        </div>
      </div>
      <div
        style={{
          fontWeight: "100",
        }}
      >
        {abs && rel && (
          <>
            <p>Относительный индекс вымывания - {rel}%</p>
            <p>Абсолютный индекс вымывания - {abs}%</p>
          </>
        )}
      </div>
      <button
        className="calculate"
        onClick={() => {
          calculate();
        }}
      >
        Рассчитать (Enter)
      </button>
      {abs > 60 && rel > 40 && (
        <h1
          style={{
            color: "green",
          }}
        >
          {" "}
          Аденома
        </h1>
      )}
      {abs < 60 && rel > 40 && (
        <>
          <h1
            style={{
              color: "orange",
            }}
          >
            {" "}
            Вероятнее, аденома
          </h1>
          <h2>Относительный индекс вымывания надёжнее</h2>
        </>
      )}
      {abs < 60 && rel < 40 && (
        <h1
          style={{
            color: "red",
          }}
        >
          {" "}
          Рекомендуется дообследование
        </h1>
      )}
      <div
        className="legend"
        style={{
          fontWeight: "100",
        }}
      >
        {string}
        {notallsizes && <p style={{ color: "red" }}> ВВЕДИТЕ ВСЕ РАЗМЕРЫ</p>}
      </div>
    </div>
  );
};

export default AdrenalWASHOUT;
