const TXT = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="-4 0 64 64"
    >
      <path
        fill="#F9CA06"
        fillRule="evenodd"
        d="M5.151-.036A5.074 5.074 0 0 0 .077 5.038v53.841a5.073 5.073 0 0 0 5.074 5.074h45.774a5.074 5.074 0 0 0 5.074-5.074V20.274L37.097-.036H5.151z"
        clipRule="evenodd"
      />
      <g fillRule="evenodd" clipRule="evenodd">
        <path
          fill="#F7BC04"
          d="M56.008 20.316v1H43.209s-6.312-1.26-6.129-6.708c0 0 .208 5.708 6.004 5.708h12.924z"
        />
        <path
          fill="#fff"
          d="M37.106-.036v14.561c0 1.656 1.104 5.792 6.104 5.792h12.799L37.106-.036z"
          opacity={0.5}
        />
      </g>
      <path
        fill="#000"
        d="M18.763 43.045h-3.277v10.047a.734.734 0 0 1-.756.738.73.73 0 0 1-.738-.738V43.045h-3.259c-.36 0-.648-.288-.648-.684 0-.36.288-.648.648-.648h8.03c.36 0 .648.288.648.685a.645.645 0 0 1-.648.647zm11.7 10.803a.64.64 0 0 1-.541-.27l-3.727-4.97-3.745 4.97a.639.639 0 0 1-.54.27.71.71 0 0 1-.72-.72c0-.144.036-.306.144-.432l3.889-5.131-3.619-4.826a.721.721 0 0 1-.144-.414c0-.343.288-.721.72-.721.216 0 .432.108.576.288l3.439 4.627 3.439-4.646a.642.642 0 0 1 .541-.27c.378 0 .738.306.738.721a.7.7 0 0 1-.126.414l-3.619 4.808 3.89 5.149c.09.126.126.27.126.415a.739.739 0 0 1-.721.738zm11.195-10.803h-3.277v10.047a.734.734 0 0 1-.756.738.73.73 0 0 1-.738-.738V43.045h-3.259c-.36 0-.648-.288-.648-.684 0-.36.288-.648.648-.648h8.03c.36 0 .648.288.648.685a.644.644 0 0 1-.648.647z"
      />
    </svg>
  );
};
export default TXT;
