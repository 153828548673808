import { useEffect, useState, useRef } from "react";
import { textState } from "../state/atoms/textareaText";
import { useParams } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import Notification from "./editing/NotificationWindow";
import "./ProfilePage.css";
import "./editing/NotificationWindow";
import { useAuthContext } from "../hooks/useAuthContext";

import "./ProfilePage.css";

const ProfilePage = () => {
  const [userObject, setUserObject] = useState(null);
  const [notificationWindowOpen, setNotificationWindowOpen] = useState(false);
  const { user } = useAuthContext();
  ////////// ProfilePage state ////////
  const [organFocus, setOrganFocus] = useState(1);

  const [settings, setSettings] = useState(null);
  const [settingsInitial, setSettingsInitial] = useState(null);

  useEffect(() => {
    const getUser = async (req, res) => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/user/${user.email}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();

      if (response.ok) {
        console.log(json);
        setUserObject(json);
      }

      const response2 = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/settings/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const stngs = await response2.json();

      if (response2.ok) {
        console.log(stngs);
        setSettings(stngs);
        let h = JSON.parse(JSON.stringify(stngs));
        setSettingsInitial(h);
      }
    };

    if (user) {
      getUser();
    }
  }, [user]);

  // счётчики сегментов и текстовых полей рыбы
  let keyCounter = 0;

  let notificationText = useRef();

  // notification function
  const notification = function (message) {
    notificationText.current = message;

    setNotificationWindowOpen(true);
    setTimeout(() => {
      setNotificationWindowOpen(false);
    }, 1100);
  };

  // universal patch request
  const patchUser = async function () {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/settings`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({ ...settings }),
      }
    );
    const json = await response.json();

    if (response.ok) {
      console.log("patched settings: ", json);
    }
  };

  return (
    <div>
      {notificationWindowOpen && (
        <Notification notificationText={notificationText.current} />
      )}
      {userObject && settings && (
        <div
          className="RYBA4rybaSETTINGS"
          style={{
            padding: "20px",
          }}
          key={(keyCounter += 1)}
        >
          <h1>Настройки</h1>
          {JSON.stringify(settings) !== JSON.stringify(settingsInitial) && (
            <button
              onClick={(e) => {
                patchUser();
                notification("Изменения успешно сохранены");
              }}
            >
              Сохранить внесённые изменения
            </button>
          )}
          <div className="userInfo">
            <p>
              Профиль создан:{" "}
              {userObject.createdAt.slice(0, 10).split("-").reverse().join(".")}
            </p>
          </div>
          <div className="userInfoTitle">
            <p>Данные</p>
          </div>
          <div className="userInfoSegment">
            <p>Ник</p>
            <input
              className="textInput"
              type="text"
              value={settings.nickname}
              onChange={(e) => {
                let s = { ...settings };
                s.nickname = e.target.value;
                setSettings(s);
              }}
            ></input>
          </div>

          <div className="userInfoSegment">
            <p>Фамилия:</p>
            <input
              className="textInput"
              type="text"
              value={settings.lastname}
              onChange={(e) => {
                let s = { ...settings };
                s.lastname = e.target.value;
                setSettings(s);
              }}
            ></input>
          </div>
          <div className="userInfoSegment">
            <p>Имя:</p>
            <input
              className="textInput"
              type="text"
              value={settings.firstname}
              onChange={(e) => {
                let s = { ...settings };
                s.firstname = e.target.value;
                setSettings(s);
              }}
            ></input>
          </div>
          <div className="userInfoSegment">
            <p>Отчество:</p>
            <input
              className="textInput"
              type="text"
              value={settings.middlename}
              onChange={(e) => {
                let s = { ...settings };
                s.middlename = e.target.value;
                setSettings(s);
              }}
            ></input>
          </div>
          <div className="userInfoSegment">
            <p>Автосохранение при копировании текста шаблона</p>
            <input
              type="checkbox"
              id="checkboxInput"
              checked={settings.autosaveOnCopy}
              value={settings.autosaveOnCopy}
              onClick={(e) => {
                let s = { ...settings };
                s.autosaveOnCopy = !s.autosaveOnCopy;
                console.log(s);

                setSettings(s);
              }}
            ></input>
          </div>
          <div className="userInfoSegment">
            <p>Шапки заключений (для экспорта в PDF): </p>
            <div className="profileShapka">
              {settings.rybaHeaders.map((o, index) => (
                <div>
                  <TextareaAutosize
                    value={settings.rybaHeaders[index]}
                    placeholder="Введите текст шапки, например, название организации (будет отображаться в шапке протокола)"
                    onChange={(e) => {
                      let s = { ...settings };
                      s.rybaHeaders[index] = e.target.value;
                      setSettings(s);
                    }}
                  ></TextareaAutosize>
                  <button
                    onClick={() => {
                      let s = { ...settings };
                      s.rybaHeaders.splice(index, 1);
                      setSettings(s);
                    }}
                  >
                    Удалить
                  </button>
                </div>
              ))}
              <button
                onClick={() => {
                  let s = { ...settings };
                  s.rybaHeaders.push("");
                  setSettings(s);
                }}
              >
                Добавить шапку
              </button>
            </div>
          </div>
          {/* <div className="userInfoSegment">
            <p>Показывать Ваш ник в поле поиска рыб для импорта</p>

            <input
              checked={settings.profile.showName}
              onChange={(e) => {
                let s = { ...settings };
                s.profile.showName = !settings.profile.showName;
                setSettings(s);
                patchUser(undefined, undefined, settings);
              }}
              type="checkbox"
            ></input>
          </div> */}
          <div className="userInfoTitle">
            <p>Интерфейс</p>
          </div>
          <div className="userInfoSegment">
            <p>Отображать заметки</p>
            <input
              type="checkbox"
              id="checkboxInput"
              checked={settings.showNotes}
              value={settings.showNotes}
              onClick={(e) => {
                let s = { ...settings };
                s.showNotes = !s.showNotes;
                console.log(s);

                setSettings(s);
              }}
            ></input>
          </div>

          <div className="userInfoTitle">
            <p>Цветовая схема</p>
          </div>
          <div className="userInfoSegment">
            <p>Изменённый текст</p>
            <input
              className="colorInput"
              type="color"
              value={settings.changedTextColor}
              onChange={(e) => {
                let s = { ...settings };
                s.changedTextColor = e.target.value;
                setSettings(s);
              }}
            ></input>
          </div>
          <div className="userInfoSegment">
            <p>Изменённый раздел</p>
            <input
              className="colorInput"
              type="color"
              value={settings.changedSegmentColor}
              onChange={(e) => {
                let s = { ...settings };
                s.changedSegmentColor = e.target.value;
                console.log(s);
                setSettings(s);
              }}
            ></input>{" "}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfilePage;
