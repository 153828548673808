import TextareaAutosize from "react-textarea-autosize";
import { useState, useRef } from "react";
import Radio from "./Radio";
import Checkbox from "./Checkbox";

const LungOchagi = ({
  constructorREF,
  setConstructorOpen,
  formState,
  setFormState,
  text,
  setText,
  zaklState,
  setZaklState,
  rybaObject,
  dinamika,
  dinamikaConstructorFunction,
}) => {
  let p = 0;

  let right = useRef([]);
  let left = useRef([]);
  let patho = useRef([]);
  let pz = useRef("");
  let size = useRef("");
  let r = right.current;
  let l = left.current;
  let pp = patho.current;
  const [string, setString] = useState("");
  const [zakl, setZakl] = useState("");

  const [reset, setReset] = useState(0);

  ////// NODULAR STATE
  const [node, setNode] = useState("");

  const organIndex = constructorREF.current.organIndex;
  const segmentIndex = constructorREF.current.segmentIndex;
  const buttonIndex = constructorREF.current.buttonIndex;

  const handleInput = function () {
    let vpravom = "";
    let vlevom = "";
    let razmer = "";
    let patologia = "";
    let zaklString = "";
    let x;
    if (r.join("")) {
      vpravom =
        r
          .filter((g) => {
            if (g) return g;
          })
          .join(",") + " правого лёгкого, ";
    }
    if (l.join("")) {
      vlevom =
        l
          .filter((g) => {
            if (g) return g;
          })
          .join(",") + " левого лёгкого ";
    }
    if (size.current !== "") {
      razmer = "до " + size.current.trim() + " мм";
    }

    x = "В" + vpravom + vlevom + ". ";

    x = x.trim().slice(0, -1) + " ";
    //////// zakl ///////
    if (patho.current.join("")) {
      let loca = x;

      loca = loca
        .replaceAll("доле", "доли")
        .replaceAll("сегментах", "сегментов")
        .replaceAll(
          "множественных сегментов правого лёгкого,  множественных сегментов левого лёгкого",
          "обоих лёгких"
        )
        .replaceAll(
          "ткани правого лёгкого,  ткани левого лёгкого",
          "обоих лёгких"
        )
        .replaceAll("обоих лёгких правого лёгкого", "обоих лёгких")
        .replaceAll("ткани", "");

      zaklString = pz.current + " " + loca.slice(3).trim() + ". ";
      setZakl(zaklString);
    }
    /////////////////////
    if (pp.join("")) {
      patologia = pp
        .filter((g) => {
          if (g) return g;
        })
        .join(",");
    }

    x = x + patologia.trim() + " " + razmer + ". ";
    x = x
      .replaceAll(
        "ткани правого лёгкого,  ткани левого лёгкого",
        "обоих лёгких"
      )
      .replaceAll("обоих лёгких правого лёгкого", "обоих лёгких")
      .replaceAll(
        "в множественных сегментах правого лёгкого,  множественных сегментах левого лёгкого",
        "полисегментарно в обоих лёгких"
      );
    setString(x);
  };

  const textHandler = function () {
    let zaklStringFinal = "";

    ///////
    ///////
    if (dinamika) {
      dinamikaConstructorFunction(string, zakl);
      setConstructorOpen(false);
      return;
    }
    ///////

    let btnObj =
      rybaObject.organs[organIndex].segments[segmentIndex].buttons[buttonIndex];
    let rybaLength = rybaObject.organs.length - 1;

    let t = [...text];
    if (
      t[organIndex][segmentIndex] ===
      rybaObject.organs[organIndex].segments[segmentIndex].norm
    ) {
      t[organIndex][segmentIndex] = "";
    }
    t[organIndex][segmentIndex] += btnObj.newline ? "\n" + string : string;

    t[organIndex][segmentIndex] = t[organIndex][segmentIndex].trim() + " ";
    if (t[rybaLength][0] === rybaObject.organs[rybaLength].segments[0].norm) {
      t[rybaLength][0] = "";
    }

    t[rybaLength][0] += zakl;

    setText(t);
    setConstructorOpen(false);
  };

  let nameCount = 0;
  let indexCount = -1;

  const checkClickR = (e, index) => {
    console.log(e.target.value);
    e.target.checked
      ? (r[index] = " " + e.target.value)
      : (r[index] = undefined);
  };
  const checkClickL = (e, index) => {
    console.log(e.target.value);
    e.target.checked
      ? (l[index] = " " + e.target.value)
      : (l[index] = undefined);
  };
  const checkClickPP = (e, index) => {
    console.log(e.target.value);
    e.target.checked
      ? (pp[index] = " " + e.target.value)
      : (pp[index] = undefined);
  };

  return (
    // <div className="popupContainerConstructor" key={(p += 1)}>
    <div className="constructorWindow lungWhere" key={reset}>
      <h1 key={(p += 1)}>Очаговые изменения</h1>
      <button
        onClick={() => {
          setString("");
          setZakl("");
          setNode("");
          right.current = [];
          left.current = [];
          patho.current = [];
          setReset(Math.random());
        }}
      >
        Сбросить
      </button>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            paddingRight: "100px",
            fontSize: "20px",
          }}
        >
          Справа
        </p>
        <p
          style={{
            paddingLeft: "100px",
            fontSize: "20px",
          }}
        >
          Слева
        </p>
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <div>
          <fieldset
            nameCount={(nameCount += 1)}
            onChange={(e) => {
              handleInput(e, 4);
            }}
          >
            <legend>Доли</legend>

            <Checkbox
              title={"Верхняя"}
              value={"верхней доле"}
              checkClick={checkClickR}
              indexCount={(indexCount += 1)}
            />
            <Checkbox
              title={"Средняя"}
              value={"средней доле"}
              checkClick={checkClickR}
              indexCount={(indexCount += 1)}
            />
            <Checkbox
              title={"Нижняя"}
              value={"нижней доле"}
              checkClick={checkClickR}
              indexCount={(indexCount += 1)}
            />
          </fieldset>
          <fieldset
            nameCount={(nameCount += 1)}
            onChange={(e) => {
              handleInput(e, 4);
            }}
          >
            <legend>Лёгкое</legend>
            <Checkbox
              title={"Правое"}
              value={"ткани"}
              checkClick={checkClickR}
              indexCount={(indexCount += 1)}
            />
          </fieldset>
        </div>
        <div>
          <form
            onChange={(e) => {
              handleInput(e, 4);
            }}
          >
            <fieldset nameCount={(nameCount += 1)}>
              <legend>Сегменты</legend>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  title={"S1"}
                  value={"S1"}
                  checkClick={checkClickR}
                  indexCount={(indexCount += 1)}
                />
                <Checkbox
                  title={"S2"}
                  value={"S2"}
                  checkClick={checkClickR}
                  indexCount={(indexCount += 1)}
                />
                <Checkbox
                  title={"S3"}
                  value={"S3"}
                  checkClick={checkClickR}
                  indexCount={(indexCount += 1)}
                />
              </div>
              <hr />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  title={"S4"}
                  value={"S4"}
                  checkClick={checkClickR}
                  indexCount={(indexCount += 1)}
                />
                <Checkbox
                  title={"S5"}
                  value={"S5"}
                  checkClick={checkClickR}
                  indexCount={(indexCount += 1)}
                />
              </div>
              <hr />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  title={"S6"}
                  value={"S6"}
                  checkClick={checkClickR}
                  indexCount={(indexCount += 1)}
                />
                <Checkbox
                  title={"S7"}
                  value={"S7"}
                  checkClick={checkClickR}
                  indexCount={(indexCount += 1)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  title={"S8"}
                  value={"S8"}
                  checkClick={checkClickR}
                  indexCount={(indexCount += 1)}
                />
                <Checkbox
                  title={"S9"}
                  value={"S9"}
                  checkClick={checkClickR}
                  indexCount={(indexCount += 1)}
                />
                <Checkbox
                  title={"S10"}
                  value={"S10"}
                  checkClick={checkClickR}
                  indexCount={(indexCount += 1)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                {" "}
                <Checkbox
                  title={"Полисегментарно"}
                  value={"множественных сегментах"}
                  checkClick={checkClickR}
                  indexCount={(indexCount += 1)}
                />
              </div>
            </fieldset>
          </form>
        </div>
        <hr />

        <div>
          <fieldset
            nameCount={(nameCount += 1)}
            onChange={(e) => {
              handleInput(e, 4);
            }}
          >
            <legend>Сегменты</legend>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Checkbox
                title={"S1-2"}
                value={"S1-2"}
                checkClick={checkClickL}
                indexCount={(indexCount += 1)}
              />
              <Checkbox
                title={"S3"}
                value={"S3"}
                checkClick={checkClickL}
                indexCount={(indexCount += 1)}
              />
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Checkbox
                title={"S5"}
                value={"S5"}
                checkClick={checkClickL}
                indexCount={(indexCount += 1)}
              />
              <Checkbox
                title={"S4"}
                value={"S4"}
                checkClick={checkClickL}
                indexCount={(indexCount += 1)}
              />
            </div>
            <hr />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Checkbox
                title={"S6"}
                value={"S6"}
                checkClick={checkClickL}
                indexCount={(indexCount += 1)}
              />
            </div>{" "}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Checkbox
                title={"S10"}
                value={"S10"}
                checkClick={checkClickL}
                indexCount={(indexCount += 1)}
              />

              <Checkbox
                title={"S9"}
                value={"S9"}
                checkClick={checkClickL}
                indexCount={(indexCount += 1)}
              />
              <Checkbox
                title={"S8"}
                value={"S8"}
                checkClick={checkClickL}
                indexCount={(indexCount += 1)}
              />
            </div>
            <div
              style={{
                display: "flex",
              }}
            >
              {" "}
              <Checkbox
                title={"Полисегментарно"}
                value={"множественных сегментах"}
                checkClick={checkClickL}
                indexCount={(indexCount += 1)}
              />
            </div>
          </fieldset>
        </div>
        <div>
          <fieldset
            nameCount={(nameCount += 1)}
            onChange={(e) => {
              handleInput(e, 4);
            }}
          >
            <legend>Доли</legend>
            <Checkbox
              title={"Верхняя"}
              value={"верхней доле"}
              checkClick={checkClickL}
              indexCount={(indexCount += 1)}
            />
            <Checkbox
              title={"Язычковая"}
              value={"язычковой доле"}
              checkClick={checkClickL}
              indexCount={(indexCount += 1)}
            />
            <Checkbox
              title={"Нижняя"}
              value={"нижней доле"}
              checkClick={checkClickL}
              indexCount={(indexCount += 1)}
            />
          </fieldset>
          <fieldset
            nameCount={(nameCount += 1)}
            onChange={(e) => {
              handleInput(e, 4);
            }}
          >
            <legend>Лёгкое</legend>
            <Checkbox
              title={"Левое"}
              value={"ткани"}
              checkClick={checkClickL}
              indexCount={(indexCount += 1)}
            />
          </fieldset>
        </div>
      </div>
      <div>
        <form
          onChange={(e) => {
            handleInput(e, 4);
          }}
        >
          <fieldset nameCount={(nameCount += 1)}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Checkbox
                title={"В обоих лёгких"}
                value={"обоих лёгких"}
                checkClick={checkClickR}
                indexCount={(indexCount += 1)}
              />
            </div>
          </fieldset>
        </form>
      </div>
      <div>
        Патология:
        <form
          onChange={(e) => {
            handleInput(e, 4);
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label>
              <input
                type="radio"
                name="patho"
                value={""}
                indexCount={indexCount}
                onClick={(e) => {
                  setNode("Солидные очаги");
                  pz.current = "";
                  size.current = "";
                }}
              />
              Солидные очаги
            </label>
            {node === "Солидные очаги" && (
              <fieldset
                className="fieldsetMini"
                onChange={(e) => {
                  handleInput(e, 4);
                }}
              >
                <legend></legend>
                <label>
                  <input
                    type="radio"
                    name="solid"
                    value={"солидный очаг"}
                    indexCount={indexCount}
                    onClick={(e) => {
                      checkClickPP(e, indexCount);
                      pz.current = "Солидный очаг";
                    }}
                  />
                  Один
                </label>
                <label>
                  <input
                    type="radio"
                    name="solid"
                    value={"единичные солидные очаги"}
                    indexCount={indexCount}
                    onClick={(e) => {
                      checkClickPP(e, indexCount);
                      pz.current = "Cолидные очаги";
                    }}
                  />
                  Единичные
                </label>
                <label>
                  <input
                    type="radio"
                    name="solid"
                    value={"многочисленные солидные очаги"}
                    indexCount={indexCount}
                    onClick={(e) => {
                      checkClickPP(e, indexCount);
                      pz.current = "Cолидные очаги";
                    }}
                  />
                  Множественные
                </label>
                <label>
                  Размер мм
                  <input
                    type="text"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        textHandler();
                        setConstructorOpen(false);
                      }
                    }}
                    indexCount={indexCount}
                    onChange={(e) => {
                      size.current = e.target.value;
                    }}
                  />
                </label>
              </fieldset>
            )}
            <label>
              <input
                type="radio"
                name="patho"
                value={""}
                indexCount={indexCount}
                onClick={(e) => {
                  setNode("Лимфоузлы");
                  pz.current = "";
                  size.current = "";
                }}
              />
              Внутрилёгочные лимфоузлы
            </label>
            {node === "Лимфоузлы" && (
              <fieldset
                className="fieldsetMini"
                onChange={(e) => {
                  handleInput(e, 4);
                }}
              >
                <legend></legend>
                <label>
                  <input
                    type="radio"
                    name="solid"
                    value={
                      "солидный очаг полигональной формы (вероятнее, внутрилёгочный лимфоузел)"
                    }
                    indexCount={indexCount}
                    onClick={(e) => {
                      checkClickPP(e, indexCount);
                      pz.current = "Внутрилёгочный лимфоузел";
                    }}
                  />
                  Один
                </label>
                <label>
                  <input
                    type="radio"
                    name="solid"
                    value={
                      "единичные солидные очаги полигональной формы (вероятнее, внутрилёгочные лимфоузлы)"
                    }
                    indexCount={indexCount}
                    onClick={(e) => {
                      checkClickPP(e, indexCount);
                      pz.current = "Внутрилёгочные лимфоузлы";
                    }}
                  />
                  Единичные
                </label>
                <label>
                  <input
                    type="radio"
                    name="solid"
                    value={
                      "многочисленные солидные очаги полигональной формы (вероятнее, внутрилёгочные лимфоузлы)"
                    }
                    indexCount={indexCount}
                    onClick={(e) => {
                      checkClickPP(e, indexCount);
                      pz.current = "Внутрилёгочные лимфоузлы";
                    }}
                  />
                  Множественные
                </label>
                <label>
                  Размер мм
                  <input
                    type="text"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        textHandler();
                        setConstructorOpen(false);
                      }
                    }}
                    indexCount={indexCount}
                    onChange={(e) => {
                      size.current = e.target.value;
                    }}
                  />
                </label>
              </fieldset>
            )}
            <label>
              <input
                type="radio"
                name="patho"
                value={""}
                indexCount={indexCount}
                onClick={(e) => {
                  setNode("Субсолидные очаги");
                  pz.current = "";
                  size.current = "";
                }}
              />
              Субсолидные очаги
            </label>
            {node === "Субсолидные очаги" && (
              <fieldset
                className="fieldsetMini"
                onChange={(e) => {
                  handleInput(e, 4);
                }}
              >
                <legend></legend>
                <label>
                  <input
                    type="radio"
                    name="solid"
                    value={"субсолидный очаг"}
                    indexCount={indexCount}
                    onClick={(e) => {
                      checkClickPP(e, indexCount);
                      pz.current = "Субсолидный очаг";
                    }}
                  />
                  Один
                </label>
                <label>
                  <input
                    type="radio"
                    name="solid"
                    value={"единичные субсолидные очаги"}
                    indexCount={indexCount}
                    onClick={(e) => {
                      checkClickPP(e, indexCount);
                      pz.current = "Cубсолидные очаги";
                    }}
                  />
                  Единичные
                </label>
                <label>
                  <input
                    type="radio"
                    name="solid"
                    value={"многочисленные субсолидные очаги"}
                    indexCount={indexCount}
                    onClick={(e) => {
                      checkClickPP(e, indexCount);
                      pz.current = "Cубсолидные очаги";
                    }}
                  />
                  Множественные
                </label>
                <label>
                  Размер мм
                  <input
                    type="text"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        textHandler();
                        setConstructorOpen(false);
                      }
                    }}
                    indexCount={indexCount}
                    onChange={(e) => {
                      size.current = e.target.value;
                    }}
                  />
                </label>
              </fieldset>
            )}
            <label>
              <input
                type="radio"
                name="patho"
                value={""}
                indexCount={indexCount}
                onClick={(e) => {
                  setNode("Буллы");
                  pz.current = "";
                  size.current = "";
                }}
              />
              Буллы
            </label>
            {node === "Буллы" && (
              <fieldset
                className="fieldsetMini"
                onChange={(e) => {
                  handleInput(e, 4);
                }}
              >
                <legend></legend>
                <label>
                  <input
                    type="radio"
                    name="solid"
                    value={"единичная булла"}
                    indexCount={indexCount}
                    onClick={(e) => {
                      checkClickPP(e, indexCount);
                      pz.current = "Булла";
                    }}
                  />
                  Одна
                </label>
                <label>
                  <input
                    type="radio"
                    name="solid"
                    value={"единичные буллы"}
                    indexCount={indexCount}
                    onClick={(e) => {
                      checkClickPP(e, indexCount);
                      pz.current = "Буллы";
                    }}
                  />
                  Единичные
                </label>
                <label>
                  <input
                    type="radio"
                    name="solid"
                    value={"многочисленные буллы"}
                    indexCount={indexCount}
                    onClick={(e) => {
                      checkClickPP(e, indexCount);
                      pz.current = "Буллы";
                    }}
                  />
                  Множественные
                </label>
                <label>
                  Размер мм
                  <input
                    type="text"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        textHandler();
                        setConstructorOpen(false);
                      }
                    }}
                    indexCount={indexCount}
                    onChange={(e) => {
                      size.current = e.target.value;
                    }}
                  />
                </label>
              </fieldset>
            )}
            <label>
              <input
                type="radio"
                name="patho"
                value={""}
                indexCount={indexCount}
                onClick={(e) => {
                  setNode("Кисты");
                  pz.current = "";
                  size.current = "";
                }}
              />
              Кисты
            </label>
            {node === "Кисты" && (
              <fieldset
                className="fieldsetMini"
                onChange={(e) => {
                  handleInput(e, 4);
                }}
              >
                <legend></legend>
                <label>
                  <input
                    type="radio"
                    name="solid"
                    value={"единичная киста"}
                    indexCount={indexCount}
                    onClick={(e) => {
                      checkClickPP(e, indexCount);
                      pz.current = "Киста";
                    }}
                  />
                  Одна
                </label>
                <label>
                  <input
                    type="radio"
                    name="solid"
                    value={"единичные кисты"}
                    indexCount={indexCount}
                    onClick={(e) => {
                      checkClickPP(e, indexCount);
                      pz.current = "Кисты";
                    }}
                  />
                  Единичные
                </label>
                <label>
                  <input
                    type="radio"
                    name="solid"
                    value={"многочисленные кисты"}
                    indexCount={indexCount}
                    onClick={(e) => {
                      checkClickPP(e, indexCount);
                      pz.current = "Кисты";
                    }}
                  />
                  Множественные
                </label>
                <label>
                  Размер мм
                  <input
                    type="text"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        textHandler();
                        setConstructorOpen(false);
                      }
                    }}
                    indexCount={indexCount}
                    onChange={(e) => {
                      size.current = e.target.value;
                    }}
                  />
                </label>
              </fieldset>
            )}
          </div>
        </form>
      </div>
      ...или введите вручную
      <TextareaAutosize
        value={string}
        onChange={(e) => {
          setString(e.target.value);
        }}
      ></TextareaAutosize>
      <div>
        В заключение
        <TextareaAutosize
          value={zakl}
          onChange={(e) => {
            setZakl(e.target.value);
          }}
        ></TextareaAutosize>
      </div>
      <button
        onClick={(e) => {
          textHandler();
          setConstructorOpen(false);
        }}
      >
        Готово
      </button>
      <button
        onClick={(e) => {
          setConstructorOpen(false);
        }}
      >
        Закрыть
      </button>
    </div>
    // </div>
  );
};
export default LungOchagi;
