const EditRyba = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 512 512"
      height={48}
      width={48}
    >
      <path
        d="M354.051 8.414H61.167c-18.093 0-32.759 14.667-32.759 32.759v429.653c0 18.093 14.667 32.759 32.759 32.759h292.885c18.093 0 32.759-14.667 32.759-32.759V41.173c0-18.093-14.667-32.759-32.76-32.759z"
        style={{
          fill: "#dce5fa",
        }}
      />
      <path
        d="M386.811 104.541c-10.408 2.671-19.954 8.08-27.75 15.877L156.393 323.085 151.81 468.06l144.976-4.58 90.025-90.024V104.541z"
        style={{
          opacity: 0.1,
          enableBackground: "new",
        }}
      />
      <path
        d="m475.656 198.696-54.479-54.479c-10.582-10.582-27.738-10.582-38.32 0l-30.269 30.268 25.777 67.022 67.022 25.777 30.269-30.269c10.583-10.581 10.583-27.738 0-38.319z"
        style={{
          fill: "#ff999a",
        }}
      />
      <path
        d="m186.581 433.292 3.029-95.828 28.806-28.807 92.8 92.799-28.807 28.807z"
        style={{
          fill: "#ffebbf",
        }}
      />
      <path
        d="m282.409 430.263-92.799-92.799 123.854-123.855 64.903 27.898 27.897 64.901z"
        style={{
          fill: "#ffd782",
        }}
      />
      <path
        d="M313.801 212.786h131.24v55.328h-131.24z"
        style={{
          fill: "#515262",
        }}
        transform="rotate(-134.999 379.423 240.451)"
      />
      <path
        d="m475.656 198.696-39.662-39.662c10.582 10.582 10.582 27.738 0 38.32L242.746 390.6l-54.872 1.734-1.295 40.957 95.828-3.029 193.247-193.247c10.585-10.581 10.585-27.738.002-38.319z"
        style={{
          opacity: 0.1,
          enableBackground: "new",
        }}
      />
      <path d="M427.136 138.267c-6.707-6.707-15.625-10.4-25.11-10.4-2.306 0-4.578.222-6.794.65V41.173C395.232 18.47 376.762 0 354.06 0H134.375c-4.648 0-8.414 3.767-8.414 8.414s3.766 8.414 8.414 8.414H354.06c13.424 0 24.345 10.921 24.345 24.346v95.692l-.027.026c-.497.444-.985.9-1.461 1.375l-95.961 95.958c-3.245 3.245-3.242 8.657 0 11.898 3.19 3.192 8.635 3.265 11.899 0l20.617-20.616 80.9 80.9-111.955 111.956-80.9-80.901 67.543-67.541a8.412 8.412 0 0 0 0-11.898 8.414 8.414 0 0 0-11.899 0l-73.492 73.49c-.036.036-.067.076-.102.112-.061.063-.116.128-.174.192a8.489 8.489 0 0 0-2.167 5.223c-.002.053-.015.103-.017.156l-3.029 95.828a8.412 8.412 0 0 0 8.675 8.676l95.828-3.029c.053-.001.103-.013.156-.017 2-.103 3.97-.948 5.414-2.34.037-.036.077-.067.113-.103l90.038-90.037v124.652c0 13.425-10.921 24.346-24.345 24.346h-60.687a8.413 8.413 0 0 0-8.414 8.414 8.413 8.413 0 0 0 8.414 8.414h60.687c22.702 0 41.172-18.47 41.172-41.173v-141.48l86.382-86.382c13.844-13.844 13.844-36.373 0-50.218l-54.477-54.48zM195.282 424.599l2.131-67.442 57.847 57.847 7.462 7.462-67.44 2.133zm210.99-130.09-80.9-80.9 27.224-27.224 80.901 80.9-27.225 27.224zm63.443-63.443-24.319 24.319-4.597-4.597-76.303-76.303 24.321-24.321a18.758 18.758 0 0 1 13.208-5.471c4.99 0 9.681 1.943 13.209 5.472l54.479 54.479c7.286 7.286 7.286 19.138.002 26.422z" />
      <path d="M268.133 503.586a8.413 8.413 0 0 0-8.414-8.414H61.175c-13.425 0-24.346-10.921-24.346-24.346V41.173c0-13.425 10.921-24.346 24.346-24.346h39.545c4.648 0 8.414-3.767 8.414-8.414S105.368 0 100.721 0H61.175C38.473 0 20.002 18.47 20.002 41.173v429.654C20.002 493.53 38.473 512 61.175 512h198.543a8.414 8.414 0 0 0 8.415-8.414z" />
    </svg>
  );
};
export default EditRyba;
