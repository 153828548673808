import { useEffect, useState, useRef } from "react";
import InputPrompt from "./editing/InputPrompt";
import { useRecoilState } from "recoil";
import { textState } from "../state/atoms/textareaText";
import { promptStateAtom } from "../state/atoms/promptState";
import { formStateAtom } from "../state/atoms/formState";
import { fullRYBAatom2 } from "../state/atoms/fullRYBAatom2";
import { zaklStateAtom } from "../state/atoms/zaklState";
import { undoStateAtom } from "../state/atoms/undoState";
import { useParams } from "react-router-dom";
import Notification from "./editing/NotificationWindow";
import "./Ryba4.css";
import "./editing/NotificationWindow";
import { useAuthContext } from "../hooks/useAuthContext";
import CopyAll from "./svg/CopyAll";
import ResetToNorm from "./svg/ResetToNorm";
import EditRyba from "./svg/EditRyba";
import PDF from "./svg/PDF";
import { Tooltip } from "react-tooltip";
import { Link } from "react-router-dom";
import React from "react";
import ConstructorRouter from "./smallComponents/constructorRouter";
import CopyProtocol from "./svg/CopyProtocol";
import CopyImpression from "./svg/CopyImpression";
import TextareaAutosize from "react-textarea-autosize";
import Buttons from "../components/smallComponents/buttons";
import { textStateFollowup } from "../state/atoms/textareaTextFollowup";
import ButtonsFollowup from "./smallComponents/buttonsFollowup";
import ZoomOut from "./svg/ZoomOut";
import ZoomIn from "./svg/ZoomIn";
import DOC from "./svg/DOC";
import TXT from "./svg/TXT";
import { Document, Packer, Paragraph, TextRun, AlignmentType } from "docx";
import * as docx from "docx";
import { saveAs } from "file-saver";
import SaveButton from "./svg/SaveButton";
import OK from "./svg/OK";
import { useDebounce, useDebouncedCallback } from "use-debounce";

const RybaDinamica = () => {
  const [rybaObject, setRybaObject] = useRecoilState(fullRYBAatom2);
  const [text, setText] = useRecoilState(textStateFollowup); // recoil state of the textarea
  const [promptText, setPromptText] = useRecoilState(promptStateAtom);
  const [formState, setFormState] = useRecoilState(formStateAtom);
  const [zaklState, setZaklState] = useRecoilState(zaklStateAtom);
  const [undoBuffer, setUndoBuffer] = useRecoilState(undoStateAtom);
  const { id } = useParams();
  const [notificationWindowOpen, setNotificationWindowOpen] = useState(false);
  const [inputPromptOpen, setInputPromptOpen] = useState(false);
  const [rated, setRated] = useState(true);
  const { user } = useAuthContext();
  ////////// Ryba4 state ////////
  const [organFocus, setOrganFocus] = useState(2000);
  const [rybaLoaded, setRybaLoaded] = useState(false);
  const [reset, setReset] = useState(false);
  const [constructorOpen, setConstructorOpen] = useState(false);
  const [settings, setSettings] = useState(null);
  const [printData, setPrintData] = useState({});
  const [copied, setCopied] = useState("");
  const [dinamika, setDinamika] = useState(true);
  const [notes, setNotes] = useState();
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(-360);
  const [pressed, setPressed] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [nameReportOpen, setNameReportOpen] = useState(false);
  const [reportTitle, setReportTitle] = useState(null);
  const [reportTitle2, setReportTitle2] = useState(null);
  const [saved, setSaved] = useState(false);
  const [savedText, setSavedText] = useState();

  useEffect(() => {
    const getFullRyba = async (req, res) => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/pond/full/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();
      // setting up ryba obj
      let r = { ...json };
      console.log("rybaObject: ", r);
      const localStorageTXT = JSON.parse(localStorage.getItem("followup" + id));
      const notes = JSON.parse(localStorage.getItem("notes" + id));
      if (notes) {
        setNotes(notes);
      }
      if (response.ok) {
        // console.log(r);
        setRybaObject(r);
        setRybaLoaded(true);
        // set text state

        if (localStorageTXT) {
          setText(localStorageTXT);
        }
        if (!localStorageTXT) {
          setText("");
        }
      }
    };
    const getSettings = async function () {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/settings/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const stngs = await response.json();
      console.log("settings object: ", stngs);
      if (response.ok) {
        setSettings(stngs);
        let p = {};
        p.hospital = stngs.rybaHeaders[0];
        setPrintData(p);
      }
    };
    if (user) {
      getFullRyba();
      getSettings();
    }
  }, [user]);

  // счётчики сегментов и текстовых полей рыбы
  let keyCounter = 0;
  let zaklRef = useRef("");
  let notificationText = useRef();
  let buttonINDEX = useRef("");
  let segmentINDEX = useRef("");
  let organINDEX = useRef("");
  let constructorREF = useRef({});

  // notification function
  const notification = function (message) {
    notificationText.current = message;

    setNotificationWindowOpen(true);
    setTimeout(() => {
      setNotificationWindowOpen(false);
    }, 1100);
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////
  ///////// DATE AND TIME FUNCTIONS ///////////
  const createDate = () => {
    const currentdate = new Date();
    const day = `${
      currentdate.getDate() > 9
        ? currentdate.getDate()
        : "0" + currentdate.getDate()
    }`;
    const month = `${
      currentdate.getMonth() + 1 > 9
        ? currentdate.getMonth() + 1
        : "0" + currentdate.getMonth() + 1
    }`;
    const year = `${currentdate.getFullYear()}`;
    return `${day}.${month}.${year}`;
  };
  const createTime = () => {
    const currentdate = new Date();
    const minutes = `${
      currentdate.getMinutes() > 9
        ? currentdate.getMinutes()
        : "0" + currentdate.getMinutes()
    }`;
    const hours = `${
      currentdate.getHours() > 9
        ? currentdate.getHours()
        : "0" + currentdate.getHours()
    }`;
    return `${hours}:${minutes}`;
  };
  // handle input prompt textarea change
  const handleInputPromptTextareaChange = (e) => {
    let f = [...promptText];
    f[1] = e.target.value;
    setPromptText(f);
  };

  // set input prompt value
  const setPromptInputValue = (button) => {
    let q = [...promptText];
    const i = q[0] + q[1] + q[2];
    if (i.includes("...")) {
      setInputPromptOpen(true);
      let g = [];
      let y = i;
      g[0] = y.slice(0, y.indexOf("..."));
      g[1] = "";
      g[2] = y.slice(y.indexOf("...") + 3);

      return setPromptText(g);
    }
    let t = `${text}`;
    navigator.clipboard.writeText(i);
    // if (!t.includes(button.zakl) && button.zakl) {
    //   t += button.zakl;
    // }

    setText(t);
    setCopied(i);
    window.localStorage.setItem(`${"followup" + id}`, JSON.stringify(t));
    notification("Текст кнопки скопирован");

    setInputPromptOpen(false);
  };

  //////////////////// RATE IMPORTED RYBA ////////////////////
  const rateSharedRyba = async (req, res, parentRybaIdAttribute, grade) => {
    /// get parent ryba
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/pond/${parentRybaIdAttribute}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const rate = Number(grade);
    const parentRyba = await response.json();
    let ratings = [...parentRyba.ratings, rate];
    const averageRating =
      ratings.reduce((a, b) => a * 1 + b * 1) / ratings.length;

    /// patch parent ryba
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/pond/${parentRybaIdAttribute}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          averageRating,
          ratings,
          ratingsLength: ratings.length,
        }),
      }
    );
    // reset this ryba isChild (so you can't rate it again)
    await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/pond/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        isChild: false,
      }),
    });

    setRated(false);
  };

  ///////
  const rateButtons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  ////////////////////////

  const buttonClick = (button) => {
    checkForDots(button.content);
    if (button.content.includes("...")) return;
  };

  /// undo
  // const undo = function () {
  //   undoCounter.current += 1;
  //   console.log(undoCounter.current);
  //   if (undoBuffer.length < undoCounter.current) {
  //     notification("Отменять больше некуда");
  //     undoCounter.current = undoCounter.current - 1;
  //     return;
  //   }
  //   const prevText = undoBuffer[undoCounter.current];
  //   setText(prevText);
  // };

  // BUTTON adding text to the textarea
  const checkForDots = function (bTextContent) {
    let y = bTextContent;

    if (y.includes("...")) {
      setInputPromptOpen(true);
      let g = [];
      g[0] = y.slice(0, y.indexOf("..."));
      g[1] = "";
      g[2] = y.slice(y.indexOf("...") + 3);

      return setPromptText(g);
    }
  };
  //// form ref

  // dinamika constructor function
  const dinamikaConstructorFunction = function (opisanie, zakl) {
    navigator.clipboard.writeText(opisanie);
    notification("Текст для описания скопирован");
    let t = `${text}`;
    if (!t.includes(zakl) && zakl) {
      t += zakl;
    }
    setText(t);
    setCopied(opisanie);
  };

  const rateColor = [
    "red",
    "red",
    "orange",
    "orange",
    "yellow",
    "yellow",
    "lightgreen",
    "lightgreen",
    "green",
    "green",
  ];

  const exportWORD = async () => {
    ////////// CREATE REPORT ///////
    let rybaString = text;
    /////// CREATE DOCX //////

    const doc = new Document({
      styles: {
        default: {
          document: {
            run: {
              size: "12pt",
              font: "Arial",
            },
          },
        },
      },
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              text: settings.rybaHeaders[0],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun({
                  text: "ФИО: ",
                }),
              ],
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: "Дата рождения: ",
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Пол: ",
                }),
              ],
            }),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun({
                  text: rybaObject.protocol,
                  bold: true,
                  size: "16pt",
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({}),
            new Paragraph({}),

            new Paragraph({}),
            new Paragraph({
              children: rybaString
                .split("\n")
                .map((line) => new TextRun({ break: 1, text: line })),
            }),

            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Врач-рентгенолог",
                  alignment: AlignmentType.LEFT,
                }),
                new TextRun({}),
              ],
            }),
            new Paragraph({
              text: `${settings.lastname} ${settings.firstname.slice(
                0,
                1
              )}. ${settings.middlename.slice(0, 1)}.`,
              alignment: AlignmentType.RIGHT,
            }),
            new Paragraph({
              text: `Дата: ${createDate()}`,
            }),
          ],
        },
      ],
    });
    //////////////////////////
    Packer.toBlob(doc).then((blob) => {
      // saveAs from FileSaver will download the file
      saveAs(blob, `${createDate()}.docx`);
    });

    ////////////////////////////////
    // const file = new Blob([doc], { type: "text/plain;charset=utf-8" });
    // saveAs(file, `${rybaObject.protocol} ${date}.docx`);
  };

  const exportTXT = () => {
    ////////// CREATE REPORT ///////

    let rybaString = "";
    rybaString += "Модальность: " + rybaObject.modality + "\n";
    rybaString += "Исследование: " + rybaObject.protocol + "\n";

    rybaString += "Дата: " + createDate() + "\n\n";
    rybaString += text;

    ////////////////////////////////
    // const fileData = JSON.stringify(rybaString);
    const file = new Blob([rybaString], { type: "text/plain;charset=utf-8" });
    saveAs(file, `${rybaObject.protocol} ${createDate()}.txt`);
  };

  const saveReport = () => {
    ////////////////////////////////
    const reports = JSON.parse(localStorage.getItem(`savedReports${user.id}`));

    let reportObject = {
      id: id,
      title: reportTitle,
      date: createDate(),
      time: createTime(),
      content: text,
      contentForExport: text,
      protocol: rybaObject.protocol,
      modality: rybaObject.modality,
      timeFull: Date.now(),
      dinamika: true,
    };
    if (!reports) {
      window.localStorage.setItem(
        `savedReports${user.id}`,
        JSON.stringify([reportObject])
      );
    }

    if (reports) {
      reports.unshift(reportObject);
      window.localStorage.setItem(
        `savedReports${user.id}`,
        JSON.stringify(reports)
      );
    }
    notification("Описание успешно сохранено");
    setReportTitle("");

    setSaved(true);
    setSavedText(JSON.stringify(text));
  };

  const autosaveReport = async () => {
    ////////// CREATE REPORT ///////

    let rybaString = text;

    ////////////////////////////////
    const reports = JSON.parse(
      localStorage.getItem(`autosavedReports${user.id}`)
    );

    let reportObject = {
      id: id,
      title: "Автосохранение",
      date: createDate(),
      time: createTime(),
      content: text,
      zakl: `ДИНАМИКА "...${rybaString.slice(-150, -1).trim()}..." `,
      contentForExport: text,
      // zakl: x[rybaObject.organs.length - 1].join(" ").slice(0, 150) + "...",
      protocol: rybaObject.protocol,
      modality: rybaObject.modality,
      timeFull: Date.now(),
      dinamika: true,
    };
    if (!reports) {
      return window.localStorage.setItem(
        `autosavedReports${user.id}`,
        JSON.stringify([reportObject])
      );
    }
    if (
      reports.filter((a) => {
        return a.id === id && a.content === text;
      }).length !== 0
    ) {
      return;
    }

    if (reports && !reports.includes(reportObject)) {
      reports.unshift(reportObject);
      window.localStorage.setItem(
        `autosavedReports${user.id}`,
        JSON.stringify(reports)
      );
    }

    notification("Автосохранение завершено");
    setReportTitle("");
    /// patch parent ryba
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/pond/numberTimesUsed/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
  };

  // universal patch request
  const patchUser = async function () {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/settings`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({ ...settings }),
      }
    );
    const json = await response.json();

    if (response.ok) {
      console.log("patched settings: ", json);
    }
  };

  const updateSettings = useDebouncedCallback((text) => {
    patchUser();
  }, 3000);

  useEffect(() => {
    updateSettings();
  }, [settings]);

  // BUTTON copy text from ALL textareas
  const goToNorm = function (e) {
    notification(`Описание сброшено до нормы`);
    let finalRyba = "";
    rybaObject.organs.map((organ, organIndex) => {
      let finalText = "";
      let segmentsNorms = "";
      organ.segments.map((s) => {
        segmentsNorms += s.norm;
      });
      finalText = organ.title + ": " + segmentsNorms;
      if (organ.title === "ШАПКА") {
        finalText = segmentsNorms.trim();
      }

      finalRyba += "\n" + finalText;
    });

    setText(finalRyba.trim());
    autosaveReport();
  };
  return (
    rybaLoaded &&
    settings && (
      <div className="RYBA4rybaContainer">
        {nameReportOpen && (
          <div className="popupContainer">
            <div className="popupContent">
              <p>Название сохраняемого документа</p>
              <TextareaAutosize
                autoFocus
                className="inputPromptTextarea"
                value={reportTitle}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    saveReport();
                    setNameReportOpen(false);
                  }
                  if (e.key === "Escape") {
                    setNameReportOpen(false);
                    setReportTitle("");
                  }
                }}
                onChange={(e) => {
                  setReportTitle(e.target.value);
                  setReportTitle2(e.target.value);
                }}
              ></TextareaAutosize>
              {/* <p>Дополнительная информация (что угодно; необязательно)</p>
            <TextareaAutosize
              autoFocus
              className="inputPromptTextarea"
              value={info}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  saveReport();
                  setNameReportOpen(false);
                }
                if (e.key === "Escape") {
                  setNameReportOpen(false);
                  setReportTitle("");
                }
              }}
              onChange={(e) => {
                setInfo(e.target.value);
              }}
            ></TextareaAutosize> */}
              <button
                className="saveChanges"
                onClick={() => {
                  saveReport();
                  setNameReportOpen(false);
                  setReportTitle("");
                }}
              >
                Сохранить (Enter)
              </button>
              <button
                className="closeTextEditWindow"
                onClick={() => {
                  setNameReportOpen(false);
                  setReportTitle("");
                }}
              >
                Отмена (Esc)
              </button>
            </div>
          </div>
        )}
        {constructorOpen && (
          <ConstructorRouter
            {...{
              constructorREF,
              setConstructorOpen,
              formState,
              setFormState,
              text,
              setText,
              zaklState,
              setZaklState,
              rybaObject,
              dinamika,
              dinamikaConstructorFunction,
            }}
          />
        )}
        <div
          className="RYBA4ryba"
          key={(keyCounter += 1)}
          style={{
            zoom: `${settings.zoom}`,
          }}
        >
          <div className="anchorForNotes">
            <div
              className="notes"
              style={{
                top: `${top}px`,
                left: `${left}px`,
                cursor: `${pressed ? "grab" : "pointer"}`,
              }}
            >
              <div
                className="notesTitle"
                onMouseDown={(e) => {
                  setPressed(true);
                }}
                onMouseUp={(e) => {
                  setPressed(false);
                }}
                onMouseMove={(e) => {
                  if (pressed) {
                    setLeft((l) => l + e.movementX);
                    setTop((t) => t + e.movementY);
                  }
                }}
                onMouseLeave={() => {
                  setPressed(false);
                }}
                onMouseOut={() => {
                  setPressed(false);
                }}
              >
                Заметки
              </div>
              <div className="notesText">
                <TextareaAutosize
                  value={notes}
                  onChange={(e) => {
                    setNotes(e.target.value);

                    window.localStorage.setItem(
                      `notes${id}`,
                      JSON.stringify(e.target.value)
                    );
                  }}
                ></TextareaAutosize>
              </div>
            </div>
          </div>
          {!rybaLoaded && <div class="loader"></div>}
          {rybaLoaded && settings && (
            <>
              {inputPromptOpen && (
                <InputPrompt
                  setInputPromptOpen={setInputPromptOpen}
                  promptText={promptText}
                  handleInputPromptTextareaChange={
                    handleInputPromptTextareaChange
                  }
                  setPromptInputValue={setPromptInputValue}
                />
              )}
              {notificationWindowOpen && (
                <Notification notificationText={notificationText.current} />
              )}
              {rybaObject.organs.length > 2 && (
                <div
                  className="RYBA4topButtonsDinamika"
                  key={(keyCounter += 1)}
                >
                  <div className="RYBA4topButtonsBorder">
                    <div
                      className="RYBA4topButton copyAll"
                      key={(keyCounter += 1)}
                      onClick={() => {
                        navigator.clipboard.writeText(text);
                        autosaveReport();
                      }}
                      data-tooltip-id="skopirovatVse"
                      data-tooltip-delay-show="300"
                    >
                      <CopyAll height="96" width="96" />
                      <Tooltip
                        id="skopirovatVse"
                        content="Скопировать весь текст"
                        place="bottom"
                        style={{ background: "black" }}
                      />
                    </div>

                    <div className="RYBA4topButtonsSmallAndTxtFormatDinamika">
                      <span
                        style={{
                          fontSize: "12px",
                          color: "grey",
                          marginLeft: "43px",
                        }}
                      >
                        Работа с шаблоном
                      </span>
                      <div className="RYBA4ropButtonsSmall">
                        <div
                          className="RYBA4topButton"
                          key={(keyCounter += 1)}
                          onClick={() => {
                            goToNorm();
                          }}
                          data-tooltip-id="goToNorm"
                          data-tooltip-delay-show="300"
                        >
                          <ResetToNorm height="48" width="48" />
                          <Tooltip
                            id="goToNorm"
                            content="Сбросить всё до нормы"
                            place="bottom"
                            style={{ background: "black" }}
                          />
                        </div>
                        {/* <div
                        className="RYBA4topButton"
                        key={(keyCounter += 1)}
                        onClick={clickHandlerCopyToClipboardProtocol}
                        data-tooltip-id="CopyProtocol"
                        data-tooltip-delay-show="300"
                      >
                        <CopyProtocol />
                        <Tooltip
                          id="CopyProtocol"
                          content="Скопировать только протокол"
                          place="bottom"
                          style={{ background: "black" }}
                        />
                      </div> */}
                        {/* <div
                        className="RYBA4topButton"
                        key={(keyCounter += 1)}
                        onClick={clickHandlerCopyToClipboardImpression}
                        data-tooltip-id="CopyImp"
                        data-tooltip-delay-show="300"
                      >
                        <CopyImpression />
                        <Tooltip
                          id="CopyImp"
                          content="Скопировать только заключение"
                          place="bottom"
                          style={{ background: "black" }}
                        />
                      </div> */}
                        {/* <div
                        className="RYBA4topButton"
                        key={(keyCounter += 1)}
                        onClick={goToDefaultAll}
                        data-tooltip-id="goToNorm"
                        data-tooltip-delay-show="300"
                      >
                        <ResetToNorm height="48" width="48" />
                        <Tooltip
                          id="goToNorm"
                          content="Сбросить всё до нормы"
                          place="bottom"
                          style={{ background: "black" }}
                        />
                      </div> */}

                        {/* <div
                        className="RYBA4topButton"
                        key={(keyCounter += 1)}
                        onClick={clickHandlerDeleteTextALL}
                        data-tooltip-id="clearAll"
                        data-tooltip-delay-show="300"
                      >
                        <DeleteAllText width="48" height="48" />
                        <Tooltip
                          id="clearAll"
                          content="Очистить все поля"
                          place="bottom"
                          style={{ background: "black" }}
                        />
                      </div> */}
                        <Link
                          to={`/editRyba2/${rybaObject._id}`}
                          style={{
                            border: "none",
                            padding: "0px",
                            margin: "0px",
                          }}
                          // target="_blank"
                        >
                          <div
                            className="RYBA4topButton"
                            data-tooltip-id="editRYba"
                            data-tooltip-delay-show="300"
                          >
                            <EditRyba />
                            <Tooltip
                              id="editRYba"
                              content="Редактировать шаблон"
                              place="bottom"
                              style={{ background: "black" }}
                            />
                          </div>
                        </Link>
                        <div
                          className="RYBA4topButton saveButtonDiv"
                          data-tooltip-id="Save"
                          data-tooltip-delay-show="300"
                          onClick={() => {
                            setNameReportOpen(true);
                          }}
                        >
                          <SaveButton />
                          <Tooltip
                            id="Save"
                            content="Сохранить заключение"
                            place="bottom"
                            style={{ background: "black" }}
                          />
                          {saved && (
                            <div className="saveButtonDivOK">
                              <OK />
                            </div>
                          )}
                        </div>
                        <div>
                          <div
                            style={{
                              margin: "0px",
                            }}
                            className="RYBA4topButton"
                            data-tooltip-id="TXT"
                            data-tooltip-delay-show="300"
                            onClick={() => {
                              exportTXT();
                            }}
                          >
                            <TXT />
                            <Tooltip
                              id="TXT"
                              content="Экспорт в TXT"
                              place="bottom"
                              style={{ background: "black" }}
                            />
                          </div>
                          <div
                            className="RYBA4topButton"
                            data-tooltip-id="DOC"
                            data-tooltip-delay-show="300"
                            onClick={() => {
                              exportWORD();
                            }}
                            style={{
                              margin: "0px",
                            }}
                          >
                            <DOC />
                            <Tooltip
                              id="DOC"
                              content="Экспорт в WORD"
                              place="bottom"
                              style={{ background: "black" }}
                            />
                          </div>
                        </div>

                        {/* <div
                        className="RYBA4topButton"
                        data-tooltip-id="PDF"
                        data-tooltip-delay-show="300"
                        onClick={() => {
                          print();
                          window.open("/exportPDF", "_blank");
                        }}
                      >
                        <PDF />
                        <Tooltip
                          id="PDF"
                          content="Экспорт в PDF"
                          place="bottom"
                          style={{ background: "black" }}
                        />
                      </div> */}
                      </div>
                      <div className="dinamikaAndPrimary">
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div
                            className="RYBA4topButton"
                            onClick={(e) => {
                              let s = { ...settings };
                              s.zoom += 0.05;
                              setSettings(s);
                            }}
                            data-tooltip-id="ZoomIn"
                            data-tooltip-delay-show="300"
                          >
                            <ZoomIn />
                            <Tooltip
                              id="ZoomIn"
                              content="Увеличить мастштаб (приблизить)"
                              place="bottom"
                              style={{ background: "black" }}
                            />
                          </div>
                          <div
                            className="RYBA4topButton"
                            onClick={(e) => {
                              let s = { ...settings };
                              s.zoom -= 0.05;
                              setSettings(s);
                            }}
                            data-tooltip-id="ZoomOut"
                            data-tooltip-delay-show="300"
                          >
                            <ZoomOut />
                            <Tooltip
                              id="ZoomOut"
                              content="Уменьшить масштаб (отдалить)"
                              place="bottom"
                              style={{ background: "black" }}
                            />
                          </div>
                        </div>
                        <div>
                          <Link
                            to={`/primary/${rybaObject._id}`}
                            style={{
                              border: "none",
                              padding: "0px",
                              margin: "0px",
                            }}
                            // target="_blank"
                          >
                            <button className="dinamika dinInactive">
                              Первичное
                            </button>
                          </Link>

                          <button className="dinamika dinActive">
                            Динамика
                          </button>
                        </div>
                      </div>
                      {/* <button
                      onClick={(e) => {
                        undo();
                      }}
                    >
                      Анду
                    </button>
                    <button>Риду</button> */}
                    </div>
                    <div className="RYBA4topButtonsBorder2">
                      {/* <p
                      style={{
                        textAlign: "center",
                        color: "grey",
                        fontSize: "0.8rem",
                        paddingTop: "10px",
                        margin: "0px",
                        marginTop: "5px",
                        marginBottom: "9px",
                      }}
                    >
                      Модальность: {rybaObject && rybaObject.modality}
                    </p> */}
                      <p
                        style={{
                          textAlign: "center",
                          color: "grey",
                          fontSize: "0.8rem",
                          paddingTop: "10px",
                          margin: "14px",
                          marginBottom: "13px",
                        }}
                      >
                        {/* Протокол:  */}
                        {rybaObject && rybaObject.protocol}
                      </p>
                      <h2 className="RYBA4title" key={(keyCounter += 1)}>
                        {rybaObject.title}
                      </h2>
                    </div>
                    {saved && (
                      <div className="copiedText">
                        <p
                          style={{
                            padding: "0px",
                            margin: "0px",
                            marginBottom: "5px",

                            marginTop: "-6px",
                            fontWeight: "100",
                          }}
                        >
                          Описание сохранено под названием "{reportTitle2}"
                        </p>
                      </div>
                    )}
                    <div className="copiedText">
                      <p
                        style={{
                          padding: "0px",
                          margin: "0px",
                          marginBottom: "5px",
                          fontWeight: "100",
                          marginTop: "30px",
                        }}
                      >
                        Сейчас скопировано:
                      </p>
                      {copied}
                    </div>
                  </div>
                </div>
              )}

              {/* {settings && (
              <div>
                Учреждение:
                <select
                  value={printData.hospital}
                  onChange={(e) => {
                    let p = { ...printData };
                    p.hospital = e.target.value;
                    console.log(p);
                    setPrintData(p);
                  }}
                  style={{
                    width: "300px",
                    margin: "10px",
                  }}
                >
                  {settings.rybaHeaders.map((h) => (
                    <option value={h}>{h}</option>
                  ))}
                </select>
              </div>
            )} */}
              {rybaObject.isChild && rated && (
                <div className="RYBA4rateRybaDiv">
                  <p>
                    Вы импортировали этот шаблон. Пожалуйста, оцените его от 1
                    до 10, это поможет другим коллегам в выборе!
                  </p>
                  {rateButtons.map((n, index) => (
                    <button
                      parentryba={rybaObject.parentRybaSharedID}
                      style={{
                        background: rateColor[index],
                      }}
                      value={n}
                      onClick={(e) => {
                        rateSharedRyba(
                          undefined,
                          undefined,
                          e.target.attributes[0].value,
                          e.target.value
                        );
                      }}
                      className="RYBA4rateRyba"
                    >
                      {n}
                    </button>
                  ))}
                </div>
              )}
              {rybaObject.organs.length === 3 && (
                <>
                  <p style={{ textAlign: "center", paddingTop: "20px" }}>
                    Это пустой шаблон. Нажмите кнопку ниже, чтобы начать
                    добавлять в неё содержимое.
                  </p>

                  <Link
                    to={`/editRyba2/${rybaObject._id}`}
                    style={{
                      border: "none",
                      padding: "0px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <div
                      className="RYBA4topButton"
                      data-tooltip-id="editRYba2"
                      data-tooltip-delay-show="300"
                      style={{
                        border: "none",
                        padding: "10px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <EditRyba />
                      <Tooltip
                        id="editRYba2"
                        content="Редактировать рыбку"
                        place="bottom"
                        style={{ background: "black", zIndex: "10" }}
                      />
                    </div>
                  </Link>
                </>
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {" "}
                <div className="RYBAdinamika">
                  <TextareaAutosize
                    // className={`segmentTextTextarea ${
                    //   organIndex === 0 &&
                    //   segmentIndex === 0 &&
                    //   "topRight"
                    // } ${organIndex === 1 && "bottomRight"} ${
                    //   organIndex === 2 &&
                    //   segmentIndex === 0 &&
                    //   "topRight"
                    // } ${
                    //   organIndex === rL(rybaObject) - 1 &&
                    //   segmentIndex ===
                    //     rybaObject.organs[rL(rybaObject) - 1].segments
                    //       .length -
                    //       1 &&
                    //   "bottomRight"
                    // } ${
                    //   organIndex === rL(rybaObject) &&
                    //   segmentIndex === 0 &&
                    //   "topRight bottomRight"
                    // }`}
                    value={text}
                    onChange={(e) => {
                      let x = [...text];
                      x = e.target.value;
                      setText(x);
                      window.localStorage.setItem(
                        `${"followup" + id}`,
                        JSON.stringify(x)
                      );
                    }}
                    // onFocus={(e) => {
                    //   setOrganFocus(organIndex);
                    // }}
                    // placeholder="-"
                    // onKeyDown={(e) => {
                    //   if (e.key === "Escape") {
                    //     setOrganFocus(null);
                    //   }
                    // }}
                    // style={{
                    //   backgroundColor:
                    //     text[organIndex][segmentIndex] !==
                    //       rybaObject.organs[organIndex].segments[
                    //         segmentIndex
                    //       ].norm &&
                    //     settings.changedTextColor,
                    // }}
                  ></TextareaAutosize>
                </div>
                <div>
                  <div className="RYBA4segmentsContainerDinamika">
                    {rybaObject.organs.map((organ, organIndex) => (
                      <div className={"organDinamika"}>
                        <div
                          className={`organTitleDinamika `}
                          onClick={() => {
                            setOrganFocus(organIndex);
                          }}
                          style={{
                            paddingTop: organIndex === 0 && "25px",
                            color: organFocus === organIndex && "white",
                            backgroundColor:
                              organFocus === organIndex && "rgb(16, 16, 16)",
                          }}
                        >
                          {organ.title}
                        </div>

                        <div className="organSegmentsDinamika">
                          {organ.segments &&
                            organ.segments.map((segment, segmentIndex) => (
                              <div
                                className="sgmntDinamika"
                                key={(keyCounter += 1)}
                                index={segmentIndex}
                              >
                                {/* <div className="segmentText">
                              <TextareaAutosize
                                className={`segmentTextTextarea ${
                                  organIndex === 0 &&
                                  segmentIndex === 0 &&
                                  "topRight"
                                } ${organIndex === 1 && "bottomRight"} ${
                                  organIndex === 2 &&
                                  segmentIndex === 0 &&
                                  "topRight"
                                } ${
                                  organIndex === rL(rybaObject) - 1 &&
                                  segmentIndex ===
                                    rybaObject.organs[rL(rybaObject) - 1]
                                      .segments.length -
                                      1 &&
                                  "bottomRight"
                                } ${
                                  organIndex === rL(rybaObject) &&
                                  segmentIndex === 0 &&
                                  "topRight bottomRight"
                                }`}
                                value={text[organIndex][segmentIndex]}
                                onChange={(e) => {
                                  let x = [...text];
                                  x[organIndex][segmentIndex] = e.target.value;
                                  setText(x);
                                  window.localStorage.setItem(
                                    `${id}`,
                                    JSON.stringify(x)
                                  );
                                }}
                                onFocus={(e) => {
                                  setOrganFocus(organIndex);
                                }}
                                placeholder="-"
                                onKeyDown={(e) => {
                                  if (e.key === "Escape") {
                                    setOrganFocus(null);
                                  }
                                }}
                                style={{
                                  backgroundColor:
                                    text[organIndex][segmentIndex] !==
                                      rybaObject.organs[organIndex].segments[
                                        segmentIndex
                                      ].norm &&
                                    settings.changedTextColor,
                                }}
                              ></TextareaAutosize>
                            </div> */}

                                <div
                                  className="segmentButtonsDinamika"
                                  style={{
                                    zIndex:
                                      organFocus === organIndex &&
                                      segmentIndex === 0 &&
                                      "5",
                                    opacity:
                                      organFocus === organIndex &&
                                      segmentIndex === 0 &&
                                      "1",
                                    padding:
                                      organ.segments.length === 1 && "0px",
                                  }}
                                >
                                  <p className="buttonsOrganTitleDinamica">
                                    {organ.title}
                                  </p>
                                  <button
                                    className="dinamikaNorma"
                                    onClick={() => {
                                      notification(
                                        `${organ.title} текст нормы скопирован`
                                      );
                                      let finalText = "";
                                      let segmentsNorms = "";
                                      organ.segments.map((s) => {
                                        segmentsNorms += s.norm;
                                      });
                                      finalText =
                                        organ.title + ": " + segmentsNorms;
                                      if (organ.title === "ШАПКА") {
                                        finalText = segmentsNorms;
                                      }
                                      navigator.clipboard.writeText(finalText);
                                      setCopied(finalText);
                                    }}
                                  >
                                    Скопировать норму
                                  </button>
                                  {organ.segments &&
                                    organ.segments.map(
                                      (segment, segmentIndex) => (
                                        <div className="segmentOne">
                                          <div className="fieldsetLegend">
                                            {segment.title}
                                          </div>

                                          {segment &&
                                            segment.buttons.map(
                                              (button, buttonIndex) => (
                                                <div>
                                                  <ButtonsFollowup
                                                    {...{
                                                      button,
                                                      buttonIndex,
                                                      buttonINDEX,
                                                      organINDEX,
                                                      segmentINDEX,
                                                      zaklRef,
                                                      setConstructorOpen,
                                                      constructorREF,
                                                      organIndex,
                                                      segmentIndex,
                                                      rybaObject,
                                                      setRybaObject,
                                                      id,
                                                      buttonClick,
                                                      reset,
                                                      setReset,
                                                      notification,
                                                      copied,
                                                      setCopied,
                                                      text,
                                                      setText,
                                                    }}
                                                  />
                                                </div>
                                              )
                                            )}
                                        </div>
                                      )
                                    )}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    )
  );
};

export default RybaDinamica;
