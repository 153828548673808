import { useState } from "react";
const RadioLymph = ({
  indexCount,
  activeGroup,
  setActiveGroup,
  string,
  setString,
  setZakl,
  zakluchatel,
  opisatel,
}) => {
  const [checked, setChecked] = useState([]);

  return (
    <>
      <label className={`radioLabel ${checked === "1" && "radioLabelChecked"}`}>
        <input
          type="radio"
          name="amount"
          value="единичный"
          onClick={(e) => {
            let a = [...activeGroup];
            let g = a[indexCount];
            g.amountCurrent = "единичный";
            a[indexCount] = g;
            let s = "";
            s = opisatel(a);
            let z = "";
            z = zakluchatel(a);
            setZakl(z);
            setActiveGroup(a);
            setString(s);
            setChecked("1");
          }}
        />
        Один
      </label>
      <label className={`radioLabel ${checked === "2" && "radioLabelChecked"}`}>
        <input
          type="radio"
          name="amount"
          value="единичные"
          onClick={(e) => {
            let a = [...activeGroup];
            let g = a[indexCount];
            g.amountCurrent = "единичные";
            a[indexCount] = g;
            let s = "";
            s = opisatel(a);
            let z = "";
            z = zakluchatel(a);
            setZakl(z);
            setActiveGroup(a);
            setString(s);
            setChecked("2");
          }}
        />
        Единичные
      </label>
      <label className={`radioLabel ${checked === "3" && "radioLabelChecked"}`}>
        <input
          type="radio"
          name="amount"
          value="множественные"
          onClick={(e) => {
            let a = [...activeGroup];
            let g = a[indexCount];
            g.amountCurrent = "множественные";
            a[indexCount] = g;
            let s = "";
            s = opisatel(a);
            let z = "";
            z = zakluchatel(a);
            setZakl(z);
            setActiveGroup(a);
            setString(s);
            setChecked("3");
          }}
        />
        Множественные
      </label>
      <label className={`radioLabel ${checked === "4" && "radioLabelChecked"}`}>
        <input
          type="radio"
          name="amount"
          value="конгломерат"
          onClick={(e) => {
            let a = [...activeGroup];
            let g = a[indexCount];
            g.amountCurrent = "конгломерат";
            a[indexCount] = g;
            let s = "";
            s = opisatel(a);
            let z = "";
            z = zakluchatel(a);
            setZakl(z);
            setActiveGroup(a);
            setString(s);
            setChecked("4");
          }}
        />
        Конгломерат
      </label>
    </>
  );
};
export default RadioLymph;
