const SaveButton = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={48}
      height={48}
      className="icon"
      viewBox="0 0 1024 1024"
    >
      <path d="M960 252.736 771.008 64H86.4A22.528 22.528 0 0 0 64 86.4v851.2c0 12.352 10.112 22.4 22.4 22.4h851.2c12.288 0 22.4-10.048 22.4-22.4V722.24a22.4 22.4 0 0 0-44.864 0V596.032h.256c0 .512-.256.896-.256 1.344a22.4 22.4 0 1 0 44.864 0c0-.512-.256-.896-.256-1.344H960v-96h-.32c.064-.576.32-1.088.32-1.6a22.4 22.4 0 0 0-44.864 0c0 .576.256 1.024.32 1.6h-.256v-85.056h.064l-.064.384a22.4 22.4 0 0 0 44.8.064l-.064-.384H960V252.736z" />
      <path
        fill="#4A5FA0"
        d="M108.8 915.2V108.8h643.648L915.2 271.296V915.2z"
      />
      <path d="M241.984 64v203.584c0 12.288 10.112 22.4 22.4 22.4h483.2c12.288 0 22.4-10.112 22.4-22.4V64h-528z" />
      <path fill="#B3B2B1" d="M286.784 108.8h438.4v136.384h-438.4z" />
      <path d="M769.984 960V548.352a22.592 22.592 0 0 0-22.464-22.4h-483.2a22.528 22.528 0 0 0-22.4 22.4V960h528.064z" />
      <path fill="#B3B2B1" d="M286.784 570.816h438.4V915.2h-438.4z" />
      <path fill="#949494" d="M548.032 136h145.984v81.984H548.032z" />
      <path d="M670.976 653.248c0 12.288-10.048 22.4-22.4 22.4h-275.2c-12.288 0-22.4-10.048-22.4-22.4s10.112-22.4 22.4-22.4h275.2c12.352 0 22.4 10.112 22.4 22.4zm0 91.392c0 12.288-10.048 22.4-22.4 22.4h-275.2c-12.288 0-22.4-10.048-22.4-22.4s10.112-22.4 22.4-22.4h275.2a22.4 22.4 0 0 1 22.4 22.4zm0 91.392c0 12.288-10.048 22.4-22.4 22.4h-275.2c-12.288 0-22.4-10.048-22.4-22.4s10.112-22.4 22.4-22.4h275.2a22.4 22.4 0 0 1 22.4 22.4z" />
      <path
        fill="#FFF"
        d="M168.576 858.368a16 16 0 0 1-32 0V169.024a16 16 0 0 1 32 0v689.344z"
      />
    </svg>
  );
};
export default SaveButton;
