import "./buttons.css";
import { useRef } from "react";
import CheckboxsVG from "../svg/CheckboxSVG";
import RadioSVG from "../svg/RadioSVG";

import ConstructorSVG from "../svg/ConstructorSVG";
import Dropdown from "../svg/Dropdown";

const Buttons = ({
  button,
  buttonIndex,
  zaklRef,
  setConstructorOpen,
  constructorREF,
  organIndex,
  segmentIndex,
  buttonClick,
  buttonINDEX,
  organINDEX,
  segmentINDEX,
  reset,
  setReset,
}) => {
  if (button.type === "radio")
    return (
      <>
        <div className="buttonBox">
          {/* {" "}
            <RadioSVG
              style={{ verticalAlign: "center" }}
              height={14}
              width={14}
              color={
                // reset[organIndex][segmentIndex][buttonIndex] ? "yellow" :
                "grey"
              }
            />{" "} */}
          <p
            style={{
              paddingTop: "0px",
              marginTop: "0px",
            }}
          >
            {button.title}
          </p>

          {button.radios.map((r, index) => (
            <button
              value={r.content}
              onClick={(e) => {
                e.preventDefault();
                organINDEX.current = organIndex;
                segmentINDEX.current = segmentIndex;
                buttonINDEX.current = buttonIndex;
                zaklRef.current = button.zakl;
                buttonClick(e, organIndex, segmentIndex, buttonIndex, r.zakl);
              }}
            >
              {r.title}
            </button>
          ))}
        </div>
      </>
    );
  if (button.type === "checkbox")
    return (
      <>
        <button
          className="simpleButton3"
          value={button.content}
          onClick={(e) => {
            organINDEX.current = organIndex;
            segmentINDEX.current = segmentIndex;
            buttonINDEX.current = buttonIndex;
            zaklRef.current = button.zakl;
            buttonClick(e, organIndex, segmentIndex, buttonIndex, button.zakl);
            let s = [...reset];
            s[organIndex][segmentIndex][buttonIndex] = true;
            setReset(s);
          }}
        >
          <span
            style={{
              marginTop: "9px",
            }}
          >
            <CheckboxsVG
              width={12}
              height={12}
              color={
                // reset[organIndex][segmentIndex][buttonIndex] ? "yellow" :
                "grey"
              }
            />{" "}
          </span>
          {button.title}
        </button>
      </>
    );
  if (button.type === "constructor")
    return (
      <>
        <ConstructorSVG
          width={16}
          height={16}
          color={
            // reset[organIndex][segmentIndex][buttonIndex] ? "yellow" :
            "grey"
          }
        />{" "}
        <button
          className="simpleButton2"
          value={button.title}
          onClick={(e) => {
            organINDEX.current = organIndex;
            segmentINDEX.current = segmentIndex;
            buttonINDEX.current = buttonIndex;
            constructorREF.current.constructor = button.title;
            constructorREF.current.organIndex = organIndex;
            constructorREF.current.segmentIndex = segmentIndex;
            constructorREF.current.buttonIndex = buttonIndex;
            setConstructorOpen(true);
          }}
        >
          {button.title} 🎛️
        </button>
      </>
    );
  if (button.type === "select")
    return (
      <>
        <label>
          <Dropdown
            width={16}
            height={16}
            color={
              // reset[organIndex][segmentIndex][buttonIndex] ? "yellow" :
              "grey"
            }
          />{" "}
          <select
            onChange={(e) => {
              zaklRef.current = button.options[e.target.selectedIndex].zakl;
              buttonINDEX.current = buttonIndex;
              organINDEX.current = organIndex;
              segmentINDEX.current = segmentIndex;
              buttonClick(
                e,
                organIndex,
                segmentIndex,
                buttonIndex,
                button.options[e.target.selectedIndex].zakl
              );
              e.target.value = "";
            }}
          >
            {button.options.map((s) => (
              <option value={s.content}>{s.title} </option>
            ))}
          </select>
          {button.title}
        </label>
      </>
    );
};

export default Buttons;
