import { useState } from "react";
const RadioLymphDinamika = ({
  indexCount,
  activeGroup,
  setActiveGroup,
  string,
  setString,
  opisatel,
  zakluchatel,
  setZakl,
}) => {
  const [checked, setChecked] = useState([]);

  return (
    <>
      <label
        className={`radioLabel ${checked === "1" && "radioLabelChecked"}`}
        style={{
          backgroundColor: checked === "1" && "lightgrey",
        }}
      >
        <input
          type="checkbox"
          onClick={(e) => {
            if (e.target.checked) {
              let a = [...activeGroup];
              a[indexCount].checkedPrevious = true;
              setActiveGroup(a);
              setChecked("1");

              let g = a[indexCount];

              a[indexCount] = g;
              let s = "";
              s = opisatel(a);
              let z = "";
              z = zakluchatel(a);
              setZakl(z);
              setActiveGroup(a);
              setString(s);
            }
            if (!e.target.checked) {
              let a = [...activeGroup];
              a[indexCount].checkedPrevious = false;
              setActiveGroup(a);
              setChecked("0");
              let g = a[indexCount];

              a[indexCount] = g;
              let s = "";
              s = opisatel(a);
              setActiveGroup(a);
              setString(s);
            }
          }}
        />
        динамика
      </label>
    </>
  );
};
export default RadioLymphDinamika;
