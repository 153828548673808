import "./calculators.css";
import { useRef, useState } from "react";
/// IMG import ///
import LiverVolum from "./images/liverVolume.png";
import ple from "./images/PleuralEff.png";
import prost from "./images/Prostate.png";
import SplCalc from "./images/Spleen.png";
import SubdHemCalc from "./images/SDH.png";
import ICAstenosisIMG from "./images/ICAstenosisIMG.png";
import AdrenalWashout from "./images/AdrenalWashout.png";
///
import Notification from "./editing/NotificationWindow";
import ProstateCalc from "./calculators/ProstateCalc";
import SpleenCalc from "./calculators/SpleenCalc";
import PleuralEffusion from "./calculators/PleuralEffusion";
import SubduralHematoma from "./calculators/SubduralHematoma";
import LiverVolume from "./calculators/LiverVolume";
import ICAcalc from "./calculators/ICAcalc";
import AdrenalWASHOUT from "./calculators/AdrenalWASHOUT";
import OvarianVolume from "./calculators/OvarianVolume";
import CerebralHematoma from "./calculators/CerebralHematoma";

const Calculators = () => {
  const [notificationWindowOpen, setNotificationWindowOpen] = useState(false);
  const [currentCalculator, setCurrentCalculator] = useState("Объём печени");
  let notificationMessageREF = useRef("");

  const notification = function (message, timing) {
    notificationMessageREF.current = message;

    setNotificationWindowOpen(true);
    setTimeout(() => {
      setNotificationWindowOpen(false);
    }, timing);
  };

  return (
    <div className="calculators">
      <h1
        style={{
          textAlign: "center",
          color: "darkgrey",
        }}
      >
        Калькуляторы
      </h1>
      <p
        style={{
          color: "darkgrey",
          textAlign: "center",
        }}
      >
        После расчёта готовая фраза для описания автоматически копируется в
        буфер обмена, можно сразу вставлять в текст без лишних хлопот
      </p>
      {notificationWindowOpen && (
        <Notification notificationText={notificationMessageREF.current} />
      )}

      <div className="calculatorsContainer">
        <div className="calcList">
          <div className="calcListSegment">
            <h6>Объёмы</h6>
            <h5>Органы</h5>
            <p
              onClick={() => {
                setCurrentCalculator("Объём печени");
              }}
            >
              Печень
            </p>
            <p
              onClick={() => {
                setCurrentCalculator("Объём селезёнки");
              }}
            >
              Селезёнка
            </p>
            <p
              onClick={() => {
                setCurrentCalculator("Объём простаты");
              }}
            >
              Простата
            </p>
            <p
              onClick={() => {
                setCurrentCalculator("Объём яичника");
              }}
            >
              Яичник
            </p>

            <h5>Жидкостные скопления</h5>
            <p
              onClick={() => {
                setCurrentCalculator("Объём гидроторакса");
              }}
            >
              Гидроторакс
            </p>
            <p
              onClick={() => {
                setCurrentCalculator("Объём субдуральной гематомы");
              }}
            >
              Субдуральная гематома
            </p>
            <p
              onClick={() => {
                setCurrentCalculator("ВМГ");
              }}
            >
              Внутримозговая гематома
            </p>
            <h6>Индексы</h6>
            <h5>Индексы/проценты/стенозы</h5>
            <p
              onClick={() => {
                setCurrentCalculator("Стеноз ВСА");
              }}
            >
              Стеноз С1 ВСА
            </p>
            <p
              onClick={() => {
                setCurrentCalculator("Вымывание надпочечников");
              }}
            >
              Индекс вымывания надпочечников
            </p>
          </div>
        </div>
        <div className="calculatorsRow">
          {currentCalculator === "Объём печени" && (
            <LiverVolume {...{ notification }} />
          )}
          {currentCalculator === "Объём простаты" && (
            <ProstateCalc {...{ notification }} />
          )}
          {currentCalculator === "Объём селезёнки" && (
            <SpleenCalc {...{ notification }} />
          )}
          {currentCalculator === "Объём гидроторакса" && (
            <PleuralEffusion {...{ notification }} />
          )}
          {currentCalculator === "Объём субдуральной гематомы" && (
            <SubduralHematoma {...{ notification }} />
          )}
          {currentCalculator === "Стеноз ВСА" && (
            <ICAcalc {...{ notification }} />
          )}
          {currentCalculator === "Вымывание надпочечников" && (
            <AdrenalWASHOUT {...{ notification }} />
          )}
          {currentCalculator === "Объём яичника" && (
            <OvarianVolume {...{ notification }} />
          )}
          {currentCalculator === "ВМГ" && (
            <CerebralHematoma {...{ notification }} />
          )}
        </div>
        <div className="calcLimgContainer">
          {currentCalculator === "Объём печени" && (
            <img src={LiverVolum} alt="liverVolume" className="calcIMAGE" />
          )}
          {currentCalculator === "Объём простаты" && (
            <img src={prost} alt="prost" className="calcIMAGE" />
          )}
          {currentCalculator === "Объём селезёнки" && (
            <img src={SplCalc} alt="prost" className="calcIMAGE" />
          )}
          {currentCalculator === "Объём гидроторакса" && (
            <img src={ple} alt="prost" className="calcIMAGE" />
          )}
          {currentCalculator === "Объём субдуральной гематомы" && (
            <img src={SubdHemCalc} alt="prost" className="calcIMAGE" />
          )}
          {currentCalculator === "Стеноз ВСА" && (
            <img src={ICAstenosisIMG} alt="prost" className="calcIMAGE" />
          )}
          {currentCalculator === "Вымывание надпочечников" && (
            <img src={AdrenalWashout} alt="prost" className="calcIMAGE" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Calculators;
