import TextareaAutosize from "react-textarea-autosize";

const InputPrompt = ({
  promptText,
  handleInputPromptTextareaChange,
  setPromptInputValue,

  setInputPromptOpen,
}) => {
  let p = 0;

  // enter handler
  const submitEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setPromptInputValue();
    }
    if (e.key === "Escape") {
      setInputPromptOpen(false);
    }
  };

  return (
    <div className="popupContainer" key={(p += 1)}>
      <div
        className="popupContent"
        style={{
          width: "450px",
        }}
        key={(p += 1)}
      >
        <div className="promptDiv" key={(p += 1)}>
          {promptText[0]}

          <span className="inputPromptSpan">{promptText[1]}</span>

          {promptText[2]}
        </div>
        {/* <p key={(p += 1)}>
          {promptText[0]}

          <span className="inputPromptSpan">{promptText[1]}</span>

          {promptText[2]}
        </p> */}
        {/* <TextareaAutosize
          value={promptText[0]}
          style={{
            background: "transparent",
            boxShadow: "none",
            border: "none",
          }}
        ></TextareaAutosize>
        <span
          className="inputPromptSpan"
          style={{
            height: "22px",
          }}
        >
          {promptText[1]}
        </span>
        <TextareaAutosize
          value={promptText[2]}
          style={{
            background: "transparent",
            boxShadow: "none",
            border: "none",
          }}
        ></TextareaAutosize> */}

        <textarea
          className="inputPromptTextarea"
          value={promptText[1]}
          onChange={handleInputPromptTextareaChange}
          autoFocus
          onKeyDown={submitEnter}
        ></textarea>
        <button className="saveChanges" onClick={setPromptInputValue}>
          Ввести (Enter)
        </button>
        <button
          className="closeTextEditWindow"
          onClick={() => {
            setInputPromptOpen(false);
          }}
        >
          Закрыть (Esc)
        </button>
      </div>
    </div>
  );
};
export default InputPrompt;
