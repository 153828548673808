import DeleteIcon from "../svg/DeleteIcon";
import ArrowUp from "../svg/ArrowUp";
import ArrowDown from "../svg/ArrowDown";
import Duplicate from "../svg/Duplicate";
import TextareaAutosize from "react-textarea-autosize";
import Dropdown from "../svg/Dropdown";
import CheckboxsVG from "../svg/CheckboxSVG";
import RadioSVG from "../svg/RadioSVG";
import ConstructorSVG from "../svg/ConstructorSVG";
import { Tooltip } from "react-tooltip";

const ButtonsRedactor = ({
  segment,
  button,
  rybaObject,
  organIndex,
  segmentIndex,
  buttonIndex,
  setRybaObject,
  deleteButtonRequest,
  locateButton,
  duplicateButton,
  moveButtonUpward,
  moveButtonDownward,
  notification,
}) => {
  const newRadio = {
    title: "Название кнопки",
    content: "Текст кнопки. ",
  };

  const newOption = {
    title: "Название опции",
    content: "Текст опции. ",
  };

  const constructorsArray = [
    "Кисты почек",
    "Объём печени",
    "Спленомегалия",
    "Гидроторакс",
    "Объём простаты",
    "Объём яичника",
    "Объём яичка",
    "Индекс вымывания (надпочечники)",
    "Стеноз ВСА по ECST и NASCET",
    "Лимфаденопатия ОГК",
    "Очаговые изменения лёгких",
    "Инфильтративные изменения лёгких",
    // "Фиброзные изменения лёгких",
    // "Очаговые изменения печени",
  ];

  if (button.type !== undefined && button.type === "radio")
    return (
      <div className="buttonAndContent">
        <div className="editDiv2">
          <div className="buttonItself" index={buttonIndex}>
            <RadioSVG width={48} height={48} color={"white"} />
          </div>
          <div>
            <button
              className="svgButton"
              onClick={(e) => {
                deleteButtonRequest(e, buttonIndex, segmentIndex, organIndex);
              }}
            >
              <DeleteIcon />
            </button>
            <button
              className="svgButton"
              onClick={() => {
                locateButton(organIndex, segmentIndex, buttonIndex);
                duplicateButton();
              }}
              onContextMenu={(e) => {
                e.preventDefault();
                notification("Кнопка скопирована");
                let r = { ...rybaObject };
                window.localStorage.setItem(
                  `copiedBUTTON`,
                  JSON.stringify(
                    r.organs[organIndex].segments[segmentIndex].buttons[
                      buttonIndex
                    ]
                  )
                );
              }}
            >
              <Duplicate
                width="20px"
                height="20px"
                style={{ pointerEvents: "none" }}
              />
            </button>
          </div>
          <div>
            {buttonIndex > 0 && (
              <button
                className="svgButton"
                onClick={() => {
                  moveButtonUpward(organIndex, segmentIndex, buttonIndex);
                }}
              >
                <ArrowUp />
              </button>
            )}
            {buttonIndex < segment.buttons.length - 1 && (
              <button
                className="svgButton"
                onClick={() => {
                  moveButtonDownward(organIndex, segmentIndex, buttonIndex);
                }}
              >
                <ArrowDown />
              </button>
            )}
          </div>
        </div>
        <div className="buttonContentDiv">
          <div className="buttonTITLEradioORselect">
            <TextareaAutosize
              value={
                rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                  buttonIndex
                ].title
              }
              onChange={(e) => {
                let r = { ...rybaObject };
                r.organs[organIndex].segments[segmentIndex].buttons[
                  buttonIndex
                ].title = e.target.value;
                setRybaObject(r);
              }}
            ></TextareaAutosize>
            <label>
              Добавлять текст с новой строки
              <input
                type="checkbox"
                checked={
                  rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                    buttonIndex
                  ].newline
                }
                onChange={(e) => {
                  let r = { ...rybaObject };
                  r.organs[organIndex].segments[segmentIndex].buttons[
                    buttonIndex
                  ].newline = e.target.checked;
                  setRybaObject(r);
                }}
              />
            </label>
          </div>
          <div className="buttonEditingSegmentRADIOorSELECT">
            <div className="buttonTITLE2">Название опции</div>
            <div className="buttonCONTENT2">Текст опции</div>
            <div className="buttonZAKL2">В заключение</div>
          </div>
          {button.radios.map((radio, index) => (
            <div className="buttonEditingSegmentRADIOorSELECT">
              <div className="buttonTITLE2">
                <TextareaAutosize
                  value={
                    rybaObject.organs[organIndex].segments[segmentIndex]
                      .buttons[buttonIndex].radios[index].title
                  }
                  onChange={(e) => {
                    console.log(e.target.value);
                    let r = { ...rybaObject };
                    r.organs[organIndex].segments[segmentIndex].buttons[
                      buttonIndex
                    ].radios[index].title = e.target.value;
                    setRybaObject(r);
                  }}
                ></TextareaAutosize>
              </div>
              <div className="buttonCONTENT2">
                {" "}
                <TextareaAutosize
                  placeholder="-"
                  value={
                    rybaObject.organs[organIndex].segments[segmentIndex]
                      .buttons[buttonIndex].radios[index].content
                  }
                  onChange={(e) => {
                    let r = { ...rybaObject };
                    r.organs[organIndex].segments[segmentIndex].buttons[
                      buttonIndex
                    ].radios[index].content = e.target.value;
                    setRybaObject(r);
                  }}
                ></TextareaAutosize>
              </div>

              <div className="buttonZAKL2">
                <TextareaAutosize
                  placeholder="-"
                  value={
                    rybaObject.organs[organIndex].segments[segmentIndex]
                      .buttons[buttonIndex].radios[index].zakl
                  }
                  onChange={(e) => {
                    let r = { ...rybaObject };
                    r.organs[organIndex].segments[segmentIndex].buttons[
                      buttonIndex
                    ].radios[index].zakl = e.target.value;
                    setRybaObject(r);
                  }}
                ></TextareaAutosize>
              </div>
              <div className="optionsandradioseditdiv">
                <button
                  className="svgButton"
                  onClick={() => {
                    let r = { ...rybaObject };
                    const newRadio = JSON.parse(
                      JSON.stringify(
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].radios[index]
                      )
                    );

                    r.organs[organIndex].segments[segmentIndex].buttons[
                      buttonIndex
                    ].radios.splice(index, 0, newRadio);

                    setRybaObject(r);
                  }}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    notification("Опция скопирована");
                    let r = { ...rybaObject };
                    window.localStorage.setItem(
                      `copiedRADIO`,
                      JSON.stringify(
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].radios[index]
                      )
                    );
                  }}
                >
                  <Duplicate />
                </button>

                {index > 0 && (
                  <button
                    className="svgButton"
                    onClick={() => {
                      let r = { ...rybaObject };
                      let a =
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].radios[index];
                      let b =
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].radios[index - 1];
                      r.organs[organIndex].segments[segmentIndex].buttons[
                        buttonIndex
                      ].radios[index] = b;
                      r.organs[organIndex].segments[segmentIndex].buttons[
                        buttonIndex
                      ].radios[index - 1] = a;
                      setRybaObject(r);
                    }}
                  >
                    <ArrowUp />
                  </button>
                )}
                {index < button.radios.length - 1 && (
                  <button
                    className="svgButton"
                    onClick={() => {
                      let r = { ...rybaObject };
                      let a =
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].radios[index];
                      let b =
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].radios[index + 1];
                      r.organs[organIndex].segments[segmentIndex].buttons[
                        buttonIndex
                      ].radios[index] = b;
                      r.organs[organIndex].segments[segmentIndex].buttons[
                        buttonIndex
                      ].radios[index + 1] = a;
                      setRybaObject(r);
                    }}
                  >
                    <ArrowDown />
                  </button>
                )}

                <button
                  className="svgButton"
                  onClick={() => {
                    let r = { ...rybaObject };
                    r.organs[organIndex].segments[segmentIndex].buttons[
                      buttonIndex
                    ].radios.splice(index, 1);
                    setRybaObject(r);
                  }}
                >
                  <DeleteIcon />
                </button>
              </div>
            </div>
          ))}
          <div>
            <button
              onClick={() => {
                let r = { ...rybaObject };
                r.organs[organIndex].segments[segmentIndex].buttons[
                  buttonIndex
                ].radios.push(newRadio);
                setRybaObject(r);
              }}
              onContextMenu={(e) => {
                e.preventDefault();
                if (!window.localStorage.getItem("copiedRADIO")) {
                  alert("В буфере обмена нет скопированных кнопок");
                  return;
                }
                notification("Опция из буфера обмена добавлена");
                let r = { ...rybaObject };
                const newRadio = JSON.parse(
                  window.localStorage.getItem("copiedRADIO")
                );
                const length =
                  r.organs[organIndex].segments[segmentIndex].buttons[
                    buttonIndex
                  ].radios.length;
                r.organs[organIndex].segments[segmentIndex].buttons[
                  buttonIndex
                ].radios.splice(length, 0, newRadio);

                setRybaObject(r);
              }}
            >
              ➕
            </button>
          </div>
        </div>
      </div>
    );

  if (button.type !== undefined && button.type === "checkbox")
    return (
      //   <label>
      //     <input type="checkbox" value={button.content} />
      //     {button.title}
      //   </label>
      <div className="buttonAndContent">
        <div className="editDiv2">
          <div className="buttonItself" index={buttonIndex}>
            <CheckboxsVG width={48} height={48} color={"white"} />
          </div>
          <div>
            <button
              className="svgButton"
              onClick={(e) => {
                deleteButtonRequest(e, buttonIndex, segmentIndex, organIndex);
              }}
            >
              <DeleteIcon />
            </button>
            <button
              className="svgButton"
              onClick={() => {
                locateButton(organIndex, segmentIndex, buttonIndex);
                duplicateButton();
              }}
              onContextMenu={(e) => {
                e.preventDefault();
                notification("Кнопка скопирована");
                let r = { ...rybaObject };
                window.localStorage.setItem(
                  `copiedBUTTON`,
                  JSON.stringify(
                    r.organs[organIndex].segments[segmentIndex].buttons[
                      buttonIndex
                    ]
                  )
                );
              }}
            >
              <Duplicate
                width="20px"
                height="20px"
                style={{ pointerEvents: "none" }}
              />
            </button>
          </div>
          <div>
            {buttonIndex > 0 && (
              <button
                className="svgButton"
                onClick={() => {
                  moveButtonUpward(organIndex, segmentIndex, buttonIndex);
                }}
              >
                <ArrowUp />
              </button>
            )}
            {buttonIndex < segment.buttons.length - 1 && (
              <button
                className="svgButton"
                onClick={() => {
                  moveButtonDownward(organIndex, segmentIndex, buttonIndex);
                }}
              >
                <ArrowDown />
              </button>
            )}
          </div>
        </div>
        <div className="buttonContentDiv">
          <div className="buttonTITLEradioORselect">
            <TextareaAutosize
              value={
                rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                  buttonIndex
                ].title
              }
              onChange={(e) => {
                console.log(e.target.value);
                let r = { ...rybaObject };
                r.organs[organIndex].segments[segmentIndex].buttons[
                  buttonIndex
                ].title = e.target.value;
                setRybaObject(r);
              }}
            ></TextareaAutosize>
            <label>
              Добавлять текст с новой строки
              <input
                type="checkbox"
                checked={
                  rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                    buttonIndex
                  ].newline
                }
                onChange={(e) => {
                  let r = { ...rybaObject };
                  r.organs[organIndex].segments[segmentIndex].buttons[
                    buttonIndex
                  ].newline = e.target.checked;
                  setRybaObject(r);
                }}
              />
            </label>
          </div>
          <div className="buttonEditingSegment">
            {/* <div className="buttonTITLE">Название кнопки</div> */}
            <div className="buttonCONTENT">Текст кнопки</div>
            <div className="buttonZAKL">В заключение</div>
          </div>
          <div className="buttonEditingSegment">
            {/* <div className="buttonTITLE">
              <TextareaAutosize
                value={
                  rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                    buttonIndex
                  ].title
                }
                onChange={(e) => {
                  console.log(e.target.value);
                  let r = { ...rybaObject };
                  r.organs[organIndex].segments[segmentIndex].buttons[
                    buttonIndex
                  ].title = e.target.value;
                  setRybaObject(r);
                }}
              ></TextareaAutosize>
            </div> */}
            <div className="buttonCONTENT">
              <TextareaAutosize
                value={
                  rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                    buttonIndex
                  ].content
                }
                onChange={(e) => {
                  console.log(e.target.value);
                  let r = { ...rybaObject };
                  r.organs[organIndex].segments[segmentIndex].buttons[
                    buttonIndex
                  ].content = e.target.value;
                  setRybaObject(r);
                }}
              ></TextareaAutosize>
            </div>
            <div className="buttonZAKL">
              <TextareaAutosize
                className="buttonZAKL"
                placeholder="-"
                value={
                  rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                    buttonIndex
                  ].zakl
                }
                onChange={(e) => {
                  console.log(e.target.value);
                  let r = { ...rybaObject };
                  r.organs[organIndex].segments[segmentIndex].buttons[
                    buttonIndex
                  ].zakl = e.target.value;
                  setRybaObject(r);
                }}
              ></TextareaAutosize>
            </div>
          </div>
        </div>
      </div>
    );
  if (button.type !== undefined && button.type === "select")
    return (
      <div className="buttonAndContent">
        <div className="editDiv2">
          <div className="buttonItself" index={buttonIndex}>
            <Dropdown width={48} height={48} color={"white"} />
          </div>
          <div>
            <button
              className="svgButton"
              onClick={(e) => {
                deleteButtonRequest(e, buttonIndex, segmentIndex, organIndex);
              }}
            >
              <DeleteIcon />
            </button>
            <button
              className="svgButton"
              onClick={() => {
                locateButton(organIndex, segmentIndex, buttonIndex);
                duplicateButton();
              }}
            >
              <Duplicate
                width="20px"
                height="20px"
                style={{ pointerEvents: "none" }}
              />
            </button>
          </div>
          <div>
            {buttonIndex > 0 && (
              <button
                className="svgButton"
                onClick={() => {
                  moveButtonUpward(organIndex, segmentIndex, buttonIndex);
                }}
              >
                <ArrowUp />
              </button>
            )}
            {buttonIndex < segment.buttons.length - 1 && (
              <button
                className="svgButton"
                onClick={() => {
                  moveButtonDownward(organIndex, segmentIndex, buttonIndex);
                }}
              >
                <ArrowDown />
              </button>
            )}
          </div>
        </div>
        <div className="buttonContentDiv">
          <div className="buttonTITLEradioORselect">
            <TextareaAutosize
              value={
                rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                  buttonIndex
                ].title
              }
              onChange={(e) => {
                console.log(e.target.value);
                let r = { ...rybaObject };
                r.organs[organIndex].segments[segmentIndex].buttons[
                  buttonIndex
                ].title = e.target.value;
                setRybaObject(r);
              }}
            ></TextareaAutosize>
            <label>
              Добавлять текст с новой строки
              <input
                type="checkbox"
                checked={
                  rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                    buttonIndex
                  ].newline
                }
                onChange={(e) => {
                  let r = { ...rybaObject };
                  r.organs[organIndex].segments[segmentIndex].buttons[
                    buttonIndex
                  ].newline = e.target.checked;
                  setRybaObject(r);
                }}
              />
            </label>
          </div>
          <div className="buttonEditingSegmentRADIOorSELECT">
            <div className="buttonTITLE2">Название кнопки</div>
            <div className="buttonCONTENT2">Текст кнопки</div>
            <div className="buttonZAKL2">В заключение</div>
          </div>
          {button.options.map(
            (option, index) =>
              index > 0 && (
                <div className="buttonEditingSegmentRADIOorSELECT">
                  <div className="buttonTITLE2">
                    <TextareaAutosize
                      value={
                        rybaObject.organs[organIndex].segments[segmentIndex]
                          .buttons[buttonIndex].options[index].title
                      }
                      onChange={(e) => {
                        console.log(e.target.value);
                        let r = { ...rybaObject };
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].options[index].title = e.target.value;
                        setRybaObject(r);
                      }}
                    ></TextareaAutosize>
                  </div>
                  <div className="buttonCONTENT2">
                    <TextareaAutosize
                      placeholder="-"
                      value={
                        rybaObject.organs[organIndex].segments[segmentIndex]
                          .buttons[buttonIndex].options[index].content
                      }
                      onChange={(e) => {
                        console.log(e.target.value);
                        let r = { ...rybaObject };
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].options[index].content = e.target.value;
                        setRybaObject(r);
                      }}
                    ></TextareaAutosize>
                  </div>

                  <div className="buttonZAKL2">
                    <TextareaAutosize
                      placeholder="-"
                      value={
                        rybaObject.organs[organIndex].segments[segmentIndex]
                          .buttons[buttonIndex].options[index].zakl
                      }
                      onChange={(e) => {
                        console.log(e.target.value);
                        let r = { ...rybaObject };
                        r.organs[organIndex].segments[segmentIndex].buttons[
                          buttonIndex
                        ].options[index].zakl = e.target.value;
                        setRybaObject(r);
                      }}
                    ></TextareaAutosize>
                  </div>
                  <div className="optionsandradioseditdiv">
                    {index > 0 && (
                      <button
                        className="svgButton"
                        onClick={() => {
                          let r = { ...rybaObject };
                          const newOption = JSON.parse(
                            JSON.stringify(
                              r.organs[organIndex].segments[segmentIndex]
                                .buttons[buttonIndex].options[index]
                            )
                          );

                          r.organs[organIndex].segments[segmentIndex].buttons[
                            buttonIndex
                          ].options.splice(index, 0, newOption);

                          setRybaObject(r);
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                          notification("Кнопка скопирована");
                          let r = { ...rybaObject };
                          window.localStorage.setItem(
                            `copiedOPTION`,
                            JSON.stringify(
                              r.organs[organIndex].segments[segmentIndex]
                                .buttons[buttonIndex].options[index]
                            )
                          );
                        }}
                      >
                        <Duplicate />
                      </button>
                    )}
                    {index > 1 && (
                      <button
                        className="svgButton"
                        onClick={() => {
                          let r = { ...rybaObject };
                          let a =
                            r.organs[organIndex].segments[segmentIndex].buttons[
                              buttonIndex
                            ].options[index];
                          let b =
                            r.organs[organIndex].segments[segmentIndex].buttons[
                              buttonIndex
                            ].options[index - 1];
                          r.organs[organIndex].segments[segmentIndex].buttons[
                            buttonIndex
                          ].options[index] = b;
                          r.organs[organIndex].segments[segmentIndex].buttons[
                            buttonIndex
                          ].options[index - 1] = a;
                          setRybaObject(r);
                        }}
                      >
                        <ArrowUp />
                      </button>
                    )}
                    {index > 0 && index < button.options.length - 1 && (
                      <button
                        className="svgButton"
                        onClick={() => {
                          let r = { ...rybaObject };
                          let a =
                            r.organs[organIndex].segments[segmentIndex].buttons[
                              buttonIndex
                            ].options[index];
                          let b =
                            r.organs[organIndex].segments[segmentIndex].buttons[
                              buttonIndex
                            ].options[index + 1];
                          r.organs[organIndex].segments[segmentIndex].buttons[
                            buttonIndex
                          ].options[index] = b;
                          r.organs[organIndex].segments[segmentIndex].buttons[
                            buttonIndex
                          ].options[index + 1] = a;
                          setRybaObject(r);
                        }}
                      >
                        <ArrowDown />
                      </button>
                    )}
                    {index > 0 && (
                      <button
                        className="svgButton"
                        onClick={() => {
                          let r = { ...rybaObject };
                          r.organs[organIndex].segments[segmentIndex].buttons[
                            buttonIndex
                          ].options.splice(index, 1);

                          setRybaObject(r);
                        }}
                      >
                        <DeleteIcon />
                      </button>
                    )}
                  </div>
                </div>
              )
          )}
          <div>
            <button
              onClick={() => {
                let r = { ...rybaObject };
                r.organs[organIndex].segments[segmentIndex].buttons[
                  buttonIndex
                ].options.push(newOption);
                setRybaObject(r);
              }}
              onContextMenu={(e) => {
                e.preventDefault();
                if (!window.localStorage.getItem("copiedOPTION")) {
                  alert("В буфере обмена нет скопированных кнопок");
                  return;
                }
                notification("Кнопка из буфера обмена добавлена");
                let r = { ...rybaObject };
                const newRadio = JSON.parse(
                  window.localStorage.getItem("copiedOPTION")
                );
                const length =
                  r.organs[organIndex].segments[segmentIndex].buttons[
                    buttonIndex
                  ].options.length;
                r.organs[organIndex].segments[segmentIndex].buttons[
                  buttonIndex
                ].options.splice(length, 0, newRadio);

                setRybaObject(r);
              }}
            >
              ➕
            </button>
          </div>
        </div>
      </div>
    );

  if (button.type !== undefined && button.type === "constructor")
    return (
      //   <label>
      //     <select>
      //       {button.options.map((s) => (
      //         <option value={s.content}>{s.title}</option>
      //       ))}
      //     </select>
      //     {button.title}
      //   </label>
      <div className="buttonAndContent">
        <div className="editDiv2">
          <div className="buttonItself" index={buttonIndex}>
            <ConstructorSVG width={32} height={32} color={"white"} />
          </div>
          <div>
            <button
              className="svgButton"
              onClick={(e) => {
                deleteButtonRequest(e, buttonIndex, segmentIndex, organIndex);
              }}
            >
              <DeleteIcon />
            </button>
            <button
              className="svgButton"
              onClick={() => {
                locateButton(organIndex, segmentIndex, buttonIndex);
                duplicateButton();
              }}
              onContextMenu={(e) => {
                e.preventDefault();
                notification("Кнопка скопирована");
                let r = { ...rybaObject };
                window.localStorage.setItem(
                  `copiedBUTTON`,
                  JSON.stringify(
                    r.organs[organIndex].segments[segmentIndex].buttons[
                      buttonIndex
                    ]
                  )
                );
              }}
            >
              <Duplicate
                width="20px"
                height="20px"
                style={{ pointerEvents: "none" }}
              />
            </button>
          </div>
          <div>
            {buttonIndex > 0 && (
              <button
                className="svgButton"
                onClick={() => {
                  moveButtonUpward(organIndex, segmentIndex, buttonIndex);
                }}
              >
                <ArrowUp />
              </button>
            )}
            {buttonIndex < segment.buttons.length - 1 && (
              <button
                className="svgButton"
                onClick={() => {
                  moveButtonDownward(organIndex, segmentIndex, buttonIndex);
                }}
              >
                <ArrowDown />
              </button>
            )}
          </div>
        </div>
        <div className="buttonContentDiv">
          Выберите конструктор/калькулятор: {"  "}
          <select
            className="constructorSelect"
            value={
              rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                buttonIndex
              ].constructor
            }
            onChange={(e) => {
              let r = { ...rybaObject };
              r.organs[organIndex].segments[segmentIndex].buttons[
                buttonIndex
              ].constructor = e.target.value;
              r.organs[organIndex].segments[segmentIndex].buttons[
                buttonIndex
              ].title = e.target.value;

              setRybaObject(r);
            }}
          >
            {constructorsArray.map((o, index) => (
              <option value={o}>{o}</option>
            ))}
          </select>
          <div>
            <label>
              Добавлять текст с новой строки
              <input
                type="checkbox"
                checked={
                  rybaObject.organs[organIndex].segments[segmentIndex].buttons[
                    buttonIndex
                  ].newline
                }
                onChange={(e) => {
                  let r = { ...rybaObject };
                  r.organs[organIndex].segments[segmentIndex].buttons[
                    buttonIndex
                  ].newline = e.target.checked;
                  setRybaObject(r);
                }}
              />
            </label>
          </div>
        </div>
      </div>
    );
};

export default ButtonsRedactor;
