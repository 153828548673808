import { useState } from "react";
const Checkbox = ({ title, value, checkClick, indexCount }) => {
  const [checked, setChecked] = useState(false);
  return (
    <label className={`checkboxLabel ${checked && "checkboxLabelChecked"}`}>
      <input
        type="checkbox"
        value={value}
        indexCount={indexCount}
        onClick={(e) => {
          checkClick(e, indexCount);
          e.target.checked ? setChecked(true) : setChecked(false);
        }}
      />
      {title}
    </label>
  );
};
export default Checkbox;
