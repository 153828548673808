const complexArray = [
  "КТ органов грудной клетки",
  "КТ органов брюшной полости",
  "КТ органов малого таза",
  "КТ органов брюшной полости и малого таза",
  "КТ мочевыводящей системы",
  "КТ органов грудной клетки, брюшной полости и малого таза",
  "КТ головы, шеи, органов грудной клетки, брюшной полости и малого таза",
  "КТ всего тела (пан-КТ)",
];
const neuroArray = [
  "КТ головного мозга",
  "КТ глазниц",
  "КЛКТ зубочелюстной системы",
  "КТ придаточных пазух носа",
  "КТ головы и шеи",
];
const spinalArray = [
  "КТ шейного отдела позвоночника",
  "КТ грудного отдела позвоночника",
  "КТ пояснично-крестцового отдела позвоночника",
  "КТ крестцово-копчикового отдела позвоночника",
];
const vascularArray = [
  "КТ-ангиография брахиоцефальных артерий",
  "КТ-ангиография интракраниальных артерий",
  "КТ-ангиография брахиоцефальных и интракраниальных артерий",
  "КТ-панаортография",
  "КТ-ангиография брюшной аорты",
  "КТ-ангиография брюшной аорты, артерий нижних конечностей",
  "КТ сердца для оценки коронарного кальция",
  "КТ-коронарография",
  "КТ-планирование TAVI",
  "КТ-ангиография лёгочных артерий",
  "КТ-ангиография лёгочных артерий для исключения ТЭЛА",
  "КТ-ангиография лёгочных вен",
];
const orthoArray = [
  "КТ кисти",
  "КТ локтевого сустава",
  "КТ плечевого сустава",
  "КТ тазобедренного сустава",
  "КТ костей таза",
  "КТ коленного сустава",
  "КТ голеностопного сустава",
  "КТ стопы",
  "КТ гортани",
  "КТ-энтерография",
  "КТ мягких тканей",
];

const protocolsCT = [
  ...complexArray,
  ...neuroArray,
  ...vascularArray,
  ...orthoArray,
  ...spinalArray,
  "Другое",
];
module.exports = {
  protocolsCT,
  complexArray,
  vascularArray,
  neuroArray,
  orthoArray,
  spinalArray,
};
